@import "vars";

html,body {
	&.body-overflow {
		overflow: hidden; } }

.second-btn {
	color: $colorMain;
	&.small-btn {
		min-width: auto;
		padding: 3px 11px;
		border-radius: 16px;
		font-size: 10px;
		line-height: 1.6;
		font-weight: 500;
		color: $colorMain;
		letter-spacing: 0.05em;
		text-transform: uppercase; } }
.step_table_order {
	display: flex;
	align-items: center;
	justify-content: left; }
.sort_order {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	&.not-active {
		color: $colorGray;
		svg {
			fill: $colorGray; }
		&:hover {
			background: none!important;
			background-color: transparent!important;
			svg {
				fill: $colorGray; } }
		&:after {
			display: none; } }
	svg {
		fill: $colorMain;
		margin-right: 16px; }
	&:hover {
		svg {
			fill: $colorAccent; } } }
.sort_order_column {
	display: none!important; }
.step_ordered_item {
	display: flex;
	flex-direction: column;
	.article-status {
		font-size: 12px;
		display: block;
		line-height: normal!important;
		margin-bottom: 10px; } }
.step-order-block {
	display: none;
	@media only screen and (max-width : 768px) {
		.step-filling__num {
			display: none; }
		.step-col {
			width: auto; }
		.step-filling__info {
			padding: 10px 0 10px 10px; }
		.step-col-date {
			max-width: 150px; }
		.step-col-time {
			max-width: 100px;
			padding: 0; }
		.input-block-big {
			align-items: center; } }

	.step_ordered_item {
		p {
			margin-bottom: 10px;
			color: $colorMain; } }
	.step-order-row {
		cursor: move!important;
		cursor: grab!important;
		cursor: -moz-grab!important;
		cursor: -webkit-grabe!important;
		&:nth-child(even) {
			background: #f8faff; } }
	&-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		.main-btn, .second-btn {
			margin: 0 8px; } } }
.marathon-btn {
	color: $colorGray;
	&:hover {
		color: $colorMain; } }

.table-btn {
	&:hover {
		border-color: #e6e9f1; } }

.checkbox p {
	padding: 4px 0 0; }

.users-table__btn,.users-table__close,.step-btn {
	transition: $transition;
	border-radius: 50%;
	&:hover {
		background: #E6E9F1;
		border-color: #E6E9F1; } }
.step-block {
	.tooltip {
		&:hover {
			background: $colorGray;
			color: #fff; }
		b,p {
			width: 200px;
			padding: 12px;
			font-size: 12px;
			line-height: 16px;
			color: $colorMain;
			text-align: left;
			background: #fff;
			box-shadow: 0px 5px 20px rgba(150, 162, 183, 0.25);
			&::after {
				border-top-color: #fff; } } } }

.download-img {
	transition: $transition;
	.user_avatar {
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%; }
	&.avatar {
		border-radius: 50%;
		overflow: hidden; } }

.download-photo {
	&:hover .download-img {
		background: #EBEEF5;
		border-color: #EBEEF5; } }

.forum-sidebar {
	&__title {
		padding: 0 40px 18px; }
	&__wrap {
		padding: 40px 0 18px;
		ul {
			li {
				padding: 0;
				a {
					padding: 12px 60px 12px 40px;
					b {
						top: 18px;
						right: 40px; }
					&:hover {
						background: #EBEEF5; } } } } } }

.inputAdd {
	&-input {
		position: relative;
		&.active {
			z-index: 20; } }
	&-list li {
		cursor: pointer;
		&:hover {
			background: $colorGray;
			color: #fff;
			svg path {
				fill: #fff; } } }
	&-btn {
		svg path {
			transition: $transition; }
		&:hover {
			svg path {
				fill: $colorMain; } } } }

.step-services li a span {
	display: inline-block;
	margin-left: 7px; }

.nano-pane {
	display: block!important; }

.involvement-list li {
	position: relative;
	overflow: visible;
	img {
		border-radius: 50%; }
	&:hover {
		.ico-close {
			opacity: 1;
			visibility: visible; } } }

.accounts {
	&-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 48px;
		border-radius: 50%;
		font-size: 28px;
		line-height: 48px;
		font-weight: normal;
		color: #fff;
		text-align: center;
		background: rgba(63,77,100,.75);
		opacity: 0;
		visibility: hidden;
		cursor: pointer;
		transition: $transition;
		z-index: 3; }
	&-list {
		li {
			position: relative;
			svg {
				display: block; }
			&:hover {
				.accounts-btn {
					opacity: 1;
					visibility: visible; } }
			a {
				&::after {
					display: none; } } }
		&__add {
			.accounts {
				&-btn {
					transform: rotate(45deg); } } } } }


.marathon-attachments {
	.involvement-list {
		margin-top: 0; } }

.ico-close {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(30%,-30%);
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: $colorMain;
	cursor: pointer;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	transition: $transition;
	svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%); } }

.participants-row {
	span {
		i {
			font-style: normal; } } }

@media only screen and (max-width: 1020px) {
	.forum {
		&-sidebar {
			&__title {
				padding: 0 15px 18px; }
			&__wrap {
				padding: 25px 0 18px;
				ul {
					li {
						a {
							padding: 12px 35px 12px 15px;
							b {
								right: 15px; } } } } } } }
	.table-btns {
		&__drop .table-btn {
			svg path {
				transition: .2s linear; }
			&:hover {
				background: #F7FAFF;
				svg path {
					fill: $colorAccent;
					stroke: $colorAccent; }
				.table-btn__text {
					color: $colorAccent; } } }
		&.active {
			.table-btn__dots {
				background: #EBEEF5;
				rect {
					stroke: #EBEEF5; } } } }
	.participants-wrap.participants-wrap__2 .participants-col__1 .participants-row {
		padding-left: 24px; } }

@media only screen and (max-width : 768px) {
	.forum-sidebar {
		&__title {
			padding: 14px 50px 14px 24px; }
		&__wrap {
			padding: 0;
			ul {
				li {
					a {
						padding: 12px 44px 12px 24px;
						b {
							right: 24px; } } } } } }
	.cabinet {
		.free_answer .reviews {
			padding: 32px 0 32px 180px; }
		.article {
			&-img {
 } }				//height: auto
		.participation-info__all {
			padding-left: 20px; } }
	.post-info__bottom ul {
		padding-right: 0; }
	.marathon-second .marathon-main {
		.marathon-text {
			padding-bottom: 32px; } }
	.post-wrap:hover .post-tags {
		height: auto; }
	.member-accordeon {
		&__top {
			padding-bottom: 24px; }
		&__hasBtn .member-accordeon__top {
			padding-bottom: 92px; } }
	.ico-close {
		opacity: 1;
		visibility: visible; } }

@media only screen and (max-width : 700px) {
	.marathon {
		&-subttile {
			padding-bottom: 0; }
		&-info__col p {
			font-size: 12px; } }
	.participants-wrap.participants-wrap__3 .participants-col__1 {
		width: 96px;
		min-width: 96px; }
	.cabinet {
		&-select {
			margin: 0 -20px;
			position: relative;
			&::before,&::after {
				content: '';
				position: absolute;
				top: 0;
				width: 20px;
				height: 100%;
				background: #f2f5fb;
				z-index: 2; }
			&::before {
				left: 0; }
			&::after {
				right: 0; }
			.select {
				&-text {
					padding-left: 20px;
					padding-right: 20px;
					span {
						display: inline-block;
						min-width: 32px;
						svg {
							display: block;
							margin: 0; } }
					&::after {
						right: 24px; } }
				&-info {
					height: 224px;
					background: $colorAccent;
					border-radius: 0;
					margin-top: 0;
					transition: 0s;
					li {
						padding: 18px 20px;
						color: #fff;
						a {
							color: #fff;
							b {
								color: #fff; } }
						&:hover {
							background: transparent; }
						svg {
							path {
								fill: #fff; } }
						span {
							display: inline-block;
							min-width: 24px; } } } }
			&.active {
				background: $colorAccent;
				&::before,&::after {
					display: none; }
				@media only screen and (max-width : 700px) {
					.link {
						color: #fff; } }
				.select {
					&-text {
						color: #fff;
						border-color: #72BFFF;
						svg {
							path {
								fill: #fff; } }
						b {
							color: #fff; }
						&::after {
							border-top-color: #fff; } } } } } }
	.marathon-task {
		&.marathon-task__noBtn {
			padding-bottom: 24px; } }
	.cabinet .involvement-form .involvement-form__row {
		padding-bottom: 0; } }

@media only screen and (max-width : 600px) {
	.participants-row p {
		font-size: 12px;
		line-height: 20px; }
	.soon {
		.post {
			padding-bottom: 24px; }
		&.soon-second {
			padding-bottom: 48px; } }
	.participants-row {
		span {
			i {
				display: none; } } }
	.participants-wrap {
		&.participants-wrap__2 {
			.participants-block__col-1 {
				min-width: 80px;
				width: 80px;
				&.step-headding {
					width: 100%; } } }
		&.participants-wrap__3 {
			.participants-col__1 {
				min-width: 64px;
				width: 64px;
				.participants-row {
					padding-left: 20px; } }
			.participants-slide__1 .participants-block__col-1 {
				min-width: 56px;
				width: 56px; }
			.participants-slide__3 .participants-block__col-1 {
				padding-right: 10px; }
			.participants-block__2 .participants-block__col-1 {
				width: 120px;
				min-width: 120px; } } }
	.cabinet {
		.participation-info__all {
			padding: 0; }
		.step-wrap .btn-block .second-btn {
			margin-top: 8px; } }
	.btn-block {
		.main-btn,.second-btn {
			margin-top: 8px; } } }

@media only screen and (max-width : 450px) {
	.involvement-form__row {
		.input-upload {
			width: 100%; }
		p {
			font-size: 11px;
			line-height: 16px; } }
	.radio {
		p {
			font-size: 12px;
			line-height: 20px;
			padding: 2px 0; } }
	.input-upload {
		width: 100%;
		.second-btn {
			justify-content: center; } }
	.cabinet .free_answer .reviews {
		//padding: 34px 0 68px
		padding: 0; }
	.text-anim {
		font-size: 14px;
		span {
			font-size: 14px; } }
	.testing-text {
		font-size: 16px;
		line-height: 24px; }
	.btn-block {
		max-width: 320px;
		margin: 40px auto 0; }
	.raffle {
		&-title {
			font-size: 14px;
			line-height: 20px; }
		.main-btn, .second-btn {
			font-size: 14px; } }
	.marathon-subtitle {
		font-size: 16px;
		line-height: 24px; }
	.reviews-second {
		&__name {
			font-size: 14px; }
		&__info p {
			font-size: 12px;
			line-height: 20px; } }
	.member {
		&-top {
			padding-bottom: 24px;
			.section-title {
				padding-bottom: 16px; } }
		&-wrap {
			padding-top: 32px; } }
	.warnings p {
		font-size: 12px;
		line-height: 20px; }
	.post-status {
		font-size: 12px; }
	.marathon-second .marathon-main {
		.page-title {
			padding-bottom: 16px; }
		.marathon-text {
			font-size: 14px;
			line-height: 20px; } }
	.profile {
		border: none;
		border-top: 1px solid #e3e6ef;
		border-bottom: 1px solid #e3e6ef;
		border-radius: 0;
		&-col {
			p {
				padding-left: 10px; }
			&:last-child {
				border-bottom: 1px solid #e3e6ef; } }
		&-info {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background: #f2f5fb; } } }
	.step-services li {
		padding-right: 19px;
		a span {
			display: none; } }
	.statistics-top {
		.main-btn {
			margin-top: 24px; } }
	.faq {
		&-list {
			&__top {
				display: none; }
			&__row {
				padding: 24px 0 24px 32px;
				a,span,p {
					font-size: 14px;
					line-height: 20px; } } } }
	.member-accordeon {
		&__top {
			padding-bottom: 60px; }
		.member-voting {
			bottom: auto;
			top: 80px; }
		&__hasBtn {
			.member-accordeon__top {
				padding-bottom: 120px; } }
		&.member-main {
			.member-voting {
				top: auto;
				bottom: 24px; } } } }

@media only screen and (max-width : 400px) {
	#modal-testing {
		.modal {
			&-title {
				max-width: 240px;
				margin: 0 auto; } } } }
.marathon-content {
	h3 {
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		padding-bottom: 20px;
		@media only screen and (max-width : 768px) {
			padding-bottom: 20px; }
		@media only screen and (max-width : 450px) {
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 16px; } } }
.row_inline_display {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center; }
.order_block {
	text-align: center;
	.step-row {
		justify-content: center; } }
.old_price {
	text-decoration: line-through;
	color: gray; }
.crop_image {
	.crop_buttons {
		margin-top: 20px;
		.btn {
			display: inline-block;
			width: auto;
			margin-right: 5px; } } }
