@import "vars";

.sitemap {
	padding: 80px 0;
	&-title {
		font-size: 30px;
		line-height: 1;
		font-weight: 500;
		padding-bottom: 20px; }
	ul {
		padding-bottom: 50px;
		li {
			font-size: 20px;
			line-height: 30px;
			color: $colorAccent;
			a {
				color: $colorGray;
				&:hover {
					color: $colorAccent; } } } } }
