@import "vars";

.member {
	padding: 45px 0 45px;
	background: #EBEFF7;
	&-wrap {
		border-top: 1px solid #DDE1E9;
		padding-top: 20px; }
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 20px;
		.section-title {
			padding: 0; }
		.select {
			min-width: 184px; } }
	&-voting {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		span {
			display: block;
			min-width: 66px;
			text-align: center;
			font-size: 20px;
			line-height: 1.2;
			color: #65C58C;
			padding: 0 5px;
			&.text-red {
				color: #FF655B; } }
		.voting {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 24px;
			height: 24px;
			border: 1px solid #CCD3DC;
			border-radius: 50%;
			line-height: 22px;
			transition: $transition;
			cursor: pointer;
			&:hover {
				background: #DDE1E9;
				border-color: #DDE1E9; } } }
	&-accordeon {
		border-bottom: 1px solid #DDE1EA;
		border-radius: $borderRadius;
		overflow: hidden;
		transition: $transition;
		.accordeon-arrow {
			right: 15px; }
		&__top {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			padding: 40px 75px 40px 40px;
			position: relative;
			border-radius: $borderRadius;
			&-row {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				img {
					display: block;
					max-width: 64px;
					border-radius: 50%;
					margin-right: 40px; } } }
		&__name {
			font-size: 16px;
			line-height: 28px;
			font-weight: normal; }
		&__block {
			display: none; }
		&__wrap {
			padding: 40px 0 80px;
			max-width: 600px;
			margin: 0 auto;
			.video {
				margin-bottom: 48px; }
			p {
				font-family: $HelveticaNeueCyr;
				font-size: 16px;
				line-height: 30px;
				a {
					color: $colorAccent; } } }
		&.accordeon-active {
			background: #F2F5FB;
			border-bottom-color: transparent;
			.accordeon-arrow {
				transform: translate(0,-50%) rotate(180deg); } }
		&.member-main {
			background: #FFF8E2;
			border-bottom: none; } }
	.center-btn {
		margin-top: 80px; }
	&-winner {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		color: #9F9A8A;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		span {
			display: inline-block;
			padding-left: 16px; } } }

@media only screen and (max-width : 1020px) {
	.member {
		padding: 40px 0 64px;
		&-accordeon {
			&__top {
				padding: 32px 75px 32px 32px;
				&-row {
					img {
						margin-right: 24px; } } }
			&__name {
				max-width: 120px;
				font-size: 16px;
				line-height: 24px; } }
		&-voting {
			left: 45%; }
		.center-btn {
			margin-top: 48px; } } }

@media only screen and (max-width : 768px) {
	.member {
		&-wrap {
			padding-top: 40px; }
		&-top {
			padding-bottom: 40px; }
		&-btn {
			padding: 0 20px;
			position: absolute;
			bottom: 24px;
			left: 0;
			width: 100%;
			.main-btn {
				display: block; } }
		&-voting {
			position: static;
			transform: translate(0,0); }
		&-accordeon {
			border-radius: 0;
			margin: 0 -36px;
			.accordeon-arrow {
				top: 36px;
				transform: translate(0,0); }
			&__top {
				border-radius: 0;
				padding: 24px 70px 92px 20px; }
			&__name {
				max-width: 100%; }
			.accordeon-arrow {
				right: 20px; }
			&__wrap {
				padding: 25px 20px 40px;
				max-width: 100%;
				.video {
					margin: 0 0 40px; } }
			&.accordeon-active {
				.accordeon-arrow {
					transform: rotate(180deg); } }
			&.member-main {
				.member {
					&-accordeon {
						&__top {
							padding: 24px 70px 24px 20px; } } } } } } }

@media only screen and (max-width : 700px) {
	.member {
		&-accordeon {
			margin: 0 -20px; } } }

@media only screen and (max-width : 600px) {
	.member-accordeon {
		&.member-main {
			.member {
				&-accordeon {
					&__name {
						padding-bottom: 24px; } } }
			.member-winner {
				position: absolute;
				left: 108px;
				bottom: 24px;
				svg {
					display: none; }
				span {
					padding: 0; } } } } }

@media only screen and (max-width : 450px) {
	.member {
		padding: 32px 0 40px;
		&-top {
			display: block;
			padding-bottom: 40px;
			.section-title {
				padding-bottom: 24px; } }
		&-accordeon {
			.accordeon-arrow {
				top: 28px; }
			&__top {
				padding-bottom: 120px;
				&-row {
					img {
						max-width: 48px;
						margin-right: 20px; } } }
			&__wrap {
				.video {
					margin-bottom: 20px; }
				p {
					font-size: 14px;
					line-height: 22px; } }
			&.member-main {
				.member-winner {
					left: 88px; }
				.member-voting {
					bottom: 24px;
					left: 210px;
					span {
						font-size: 18px; } } } }
		&-voting {
			position: absolute;
			top: auto;
			left: 50%;
			bottom: 80px;
			transform: translate(-50%,0); }
		.center-btn {
			margin-top: 40px; } } }
