@import "vars";

.rating {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 36px;
	p {
		font-size: 14px;
		line-height: 24px; }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		flex-direction: row-reverse;
		margin-right: -6px;
		label {
			padding: 0 6px;
			cursor: pointer; }
		input[type="radio"] {
			display: none; }
		input[type="radio"]:hover ~ label svg path {
			fill: $colorAccent; }
		input[type="radio"]:checked ~ label svg path {
			fill: $colorGray; } }
	.input-block {
		width: 100%;
		textarea {
			border: 1px solid #d8dee8;
			max-height: 100px!important; } } }

@media only screen and (max-width : 450px) {
	.rating {
		display: block;
		padding-bottom: 20px;
		&-row {
			justify-content: flex-end;
			margin: 10px 0 0 -6px; } } }
