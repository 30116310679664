@import "vars";

.close {
	position: absolute;
	top: 24px;
	right: 24px;
	cursor: pointer; }

.modal {
	width: 100%;
	max-width: 600px;
	&-user-note {
		max-width: 1000px; }
	max-height: 100%;
	position: fixed;
	top: 50%;
	left: 50%;
	background: #fff;
	z-index: 99999;
	border-radius: $borderRadius;
	transform: translate(-50%,-80%);
	box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s;
	overflow-y: auto;
	padding: 64px 36px;
	&-content {
		max-width: 400px;
		margin: 0 auto; }
	&-title {
		text-align: center;
		padding-bottom: 48px;
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase; }
	&-conclusion {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 32px;
		p {
			padding-right: 10px;
			font-size: 16px;
			line-height: 24px; }
		&__wrap {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			input {
				display: block;
				max-width: 120px;
				min-width: 120px;
				width: 100%;
				height: 48px;
				background: #F2F5FB;
				border-radius: $borderRadius;
				border: none;
				padding: 12px 24px;
				font-size: 16px; }
			span {
				display: inline-block;
				padding-left: 24px;
				font-size: 16px;
				line-height: 24px;
				color: $colorGray; } } }
	&-text {
		padding-bottom: 28px;
		p {
			padding-bottom: 20px;
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray; } }
	&-normal {
		p {
			color: $colorMain;
			font-size: 14px;
			line-height: 24px; } }
	&-btns {
		display: flex;
		flex-flow: row nowrap;
		margin: 0 -12px;
		.main-btn,.second-btn {
			width: 50%;
			min-width: auto;
			margin: 0 12px; } }
	&-center {
		text-align: center; }
	&-wrap {
		&__small {
			max-width: 290px;
			margin: 0 auto;
			padding-bottom: 32px;
			.toggle {
				margin-bottom: 16px; } } }
	&-rating {
		padding-bottom: 14px; }
	&-calendar {
		ul {
			padding-bottom: 16px;
			li {
				font-size: 14px;
				line-height: 20px;
				color: #3F4D64;
				padding-bottom: 24px;
				.svg {
					display: inline-block;
					height: 10px;
					width: 12px;
					position: relative;
					margin-left: 4px;
					svg {
						position: absolute;
						top: 50%;
						left: 0;
						transform: translate(0,-50%); } } } }
		.second-btn {
			display: block;
			width: 100%; } }
	&.open {
		visibility: visible;
		opacity: 1;
		transform: translate(-50%,-50%); } }

.testStatistics {
	&-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 16px;
		span {
			display: inline-block;
			font-size: 10px;
			line-height: 2;
			font-weight: 500;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $colorGray; } }
	&-bar {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px; }
	&-num {
		display: inline-block;
		min-width: 40px;
		font-size: 14px;
		line-height: 24px;
		color: #3F4D64;
		&:last-child {
			text-align: right; } }
	&-tab {
		display: none; }
	&-active {
		display: block; } }

.bar {
	width: 100%;
	height: 4px;
	border-radius: 4px;
	position: relative;
	&-block {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background: #65C58C; } }

.testStatistics {
	&-list {
		display: flex;
		flex-flow: row nowrap;
		padding: 40px 0 8px;
		li {
			padding-right: 24px;
			a {
				display: inline-block;
				font-size: 10px;
				line-height: 15px;
				font-weight: 500;
				text-transform: uppercase;
				color: $colorGray;
				padding: 5px 0;
				&.active {
					color: $colorAccent; } }
			&:last-child {
				padding-right: 0; } } }
	&-title {
		font-size: 16px;
		line-height: 2;
		font-weight: normal;
		padding-bottom: 16px; }
	&-wrap {
		padding-bottom: 32px;
		.radio {
			display: block;
			margin-bottom: 16px;
			p {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				i {
					font-style: normal; } }
			input {
				&:checked + span {
					border-color: #65C58C;
					&::after {
						background: #65C58C; } } } } } }

.input-block {
	&.modal-input {
		margin-bottom: 48px;
		input {
			background: #F2F5FB; } } }
.modal-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	top: 0;
	left: 0;
	z-index: 1000;
	background: rgba(#EBEEF5,0.85);
	transition: all 0.3s;
	&.open-overlay {
		visibility: visible;
		opacity: 1; } }

@media only screen and (max-width : 768px) {
	.modal {
		&-content {
			max-width: 456px; } } }

@media only screen and (max-width : 600px) {
	.modal {
		padding: 48px 20px;
		border-radius: 0; }
	.input-block {
		&.modal-input {
			margin-bottom: 30px; } } }

@media only screen and (max-width : 450px) {
	.modal {
		padding: 40px 20px;
		&-title {
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 40px; }
		&-conclusion {
			display: block;
			padding-bottom: 24px;
			p {
				padding: 0 0 20px;
				font-size: 14px;
				line-height: 20px; }
			&__wrap {
				input {
					height: 40px;
					padding: 10px 24px;
					font-size: 14px; }
				span {
					padding-left: 16px; } } }
		&-text {
			padding-bottom: 20px;
			p {
				font-size: 11px; } }
		&-btns {
			margin: 0 -8px;
			.main-btn,.second-btn {
				margin: 0 8px; } }
		&-calendar {
			.close {
				top: 20px;
				right: 20px; }
			.modal-title {
				max-width: 240px;
				margin: 0 auto; } } } }
