.free-bonus {
    position: relative;
    z-index: 1;
    margin: 120px 0 80px;
    padding: 80px 0;
    color: #fff;
    background: #3E4E65;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin: 80px 0;
        padding: 60px 0;
    }
    @media screen and (max-width: 767px) {
        margin: 60px 0;
        padding: 50px 0;
    }

    &__img-decor {
        position: absolute;
        top: 0;
        right: -10px;
        z-index: -1;
        display: block;
        min-width: 264px;
        width: 264px;
        height: 264px;
        img {
            display: block;
            width: 100%;
            height: 100%!important;
            object-fit: contain;
        }
        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            right: -20px;
            min-width: 214px;
            width: 214px;
            height: 214px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            right: -20px;
            min-width: 144px;
            width: 144px;
            height: 144px;
        }
        @media screen and (max-width: 767px) {
            right: -10px;
            min-width: 74px;
            width: 74px;
            height: 74px;
        }
    }
    &__wrapper {
        margin: 0 auto;
        max-width: 790px;
        width: 100%;
        text-align: center;

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            max-width: 700px;
        }
    }
    &__heading {
        font-size: 32px;
        font-weight: 600;

        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
    &__description {
        margin-top: 60px;
        font-size: 20px;
        font-weight: normal;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 767px) {
            margin-top: 20px;
            font-size: 16px;
        }
    }
    &__btn {
        margin-top: 80px;
        .main-btn,
        .second-btn {
            min-width: 237px;
        }

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
}