@import "vars";

.faq {
	&-subtitle {
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		padding-bottom: 40px; }
	&-info {
		max-width: 1000px;
		margin: 0 auto 50px;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 30px;
			padding-bottom: 20px;
			word-break: break-word; } }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		max-width: 808px;
		margin: 0 auto 80px; }
	&-img {
		min-width: 288px; }
	&-text {
		max-width: 480px;
		width: 100%;
		padding-left: 40px;
		&__title {
			display: block;
			padding-bottom: 16px;
			border-bottom: 1px solid #E3E6EF;
			font-size: 12px;
			line-height: 2;
			font-weight: 500;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $colorAccent;
			margin-bottom: 32px; }
		p {
			font-size: 20px;
			line-height: 1.75; } }
	&-picture {
		text-align: center;
		margin-bottom: 80px; } }

@media only screen and (max-width : 768px) {
	.faq {
		&-info {
			max-width: 100%;
			margin-bottom: 16px; }
		&-subtitle {
			padding-bottom: 24px; }
		&-row {
			margin-bottom: 48px; }
		&-img {
			min-width: 216px; }
		&-text {
			p {
				font-size: 16px;
				line-height: 28px; } }
		&-picture {
			margin: 0 -36px 48px; } } }

@media only screen and (max-width : 700px) {
	.faq {
		&-picture {
			margin: 0 -20px 32px; } } }

@media only screen and (max-width : 600px) {
	.faq {
		&-row {
			display: block; }
		&-img {
			min-width: auto;
			padding-bottom: 24px;
			img {
				display: block; } }
		&-text {
			max-width: 100%;
			padding: 0; } } }

@media only screen and (max-width : 450px) {
	.faq {
		&-content {
			padding-bottom: 8px; }
		&-info {
			margin-bottom: 8px;
			p {
				font-size: 14px;
				line-height: 24px;
				padding-bottom: 24px; } }
		&-subtitle {
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 16px; }
		&-row {
			margin-bottom: 32px; }
		&-text {
			&__title {
				font-size: 11px;
				line-height: 20px;
				padding-bottom: 16px;
				margin-bottom: 24px; }
			p {
				line-height: 24px; } } } }
