@import "vars";

.contacts {
	padding: 80px 0;
	position: relative;
	z-index: 10;
	background: #f2f5fb;
	&-wrap {
		max-width: 600px;
		margin: 0 auto; }
	.section-title {
		padding-bottom: 80px; }
	&-block {
		margin-bottom: 48px; }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		margin: 0 -12px 48px;
		.input-block {
			width: 50%;
			padding: 0 12px; } }
	.main-btn {
		display: block;
		width: 100%; } }

@media only screen and (max-width : 768px) {
	.contacts {
		padding: 64px 0;
		.section-title {
			padding-bottom: 40px; }
		&-block {
			margin-bottom: 40px; } } }

@media only screen and (max-width : 450px) {
	.contacts {
		padding: 48px 0 40px;
		&-row {
			display: block;
			padding: 0;
			margin: 0;
			.input-block {
				width: 100%;
				padding: 0;
				margin-bottom: 32px; } } } }

