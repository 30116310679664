@import "vars";

.contactUs {
	background: $colorMain;
	position: relative;
	text-align: center;
	padding-bottom: 56px;
	&-bg {
		position: absolute;
		top: -386px;
		right: -212px;
		z-index: 9; }
	&::after {
		content: '';
		position: absolute;
		top: -400px;
		left: 0;
		width: 100%;
		height: 400px;
		background: $colorMain; }
	.wrapper {
		position: relative;
		z-index: 10; }
	.page-title {
		color: #fff;
		max-width: 520px;
		margin: 0 auto; }
	.support {
		&-col {
			a {
				color: #fff; } }
		&-subtitle {
			color: $colorGray; } } }

@media only screen and (max-width : 1020px) {
	.contactUs {
		&-bg {
			display: none; } } }

@media only screen and (max-width : 768px) {
	.contactUs {
		padding: 24px 0 32px; } }
