@import "vars";

.header {
	padding-bottom: 40px;
	position: relative;
	transition: $transition;
	z-index: 99;
	&-nav {
		width: 100%; }
	&-top {
		//padding: 39px 0
		padding: 10px 0;
		background: #F2F5FB;
		border-bottom: 1px solid #E3E6EF;
		transition: $transition;
		.wrapper {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			padding-right: 102px;
			position: relative; } }
	&-logo {
		position: relative;
		z-index: 11;
		margin-right: 40px;
		svg {
			display: block; } }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between; }
	&-list {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		width: calc(100% - 20px);
		margin-right: 20px;
		//padding-right: 46px
		li {
			padding: 0 10px;
			a {
				display: inline-block;
				font-size: 14px;
				line-height: 20px;
				color: $colorMain;
				&:hover {
					color: $colorAccent; } } } }
	&-btns {
		display: flex;
		flex-flow: row nowrap;
		align-items: center; }
	&-link {
		display: inline-block;
		font-size: 16px;
		//line-height: 48px
		color: $colorMain;
		padding: 14px 24px;
		margin-right: 10px;
		min-width: auto; }
	&-info {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
 }		//min-width: 290px
	&-bell,&-mes {
		margin-left: 8px; }
	&-ico {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		border-radius: $borderRadius;
		position: relative;
		cursor: pointer;
		transition: $transition;
		span {
			position: absolute;
			top: 12px;
			right: 8px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			&.active {
				background: $colorAccent; }
			&.with_count {
				width: auto;
				min-width: 16px;
				padding: 0 3px;
				border-radius: 6px;
				height: 16px;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 9px;
				line-height: 16px;
				top: 10px;
				right: 5px; } }
		&:hover {
			background: #E6E9F1; } }
	&-user {
		min-width: 60px;
		width: 40px;
		height: 48px;
		padding: 4px 20px 4px 0;
		overflow: hidden;
		position: relative;
		margin: 0 20px 0 39px;
		cursor: pointer;
		.avatar {
			background-size: cover;
			background-position: center;
			width: 100%;
			height: 100%; }
		img, .avatar {
			display: block;
			width: 100%;
			border-radius: 50%;
			background-color: #e6e9f0; }
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 3px;
			transform: translate(0,-50%);
			border-top: 4px solid $colorGray;
			border-left: 2px solid transparent;
			border-right: 2px solid transparent;
			transition: $transition; } }
	&-search {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: #fff;
		padding-left: 296px;
		opacity: 0;
		visibility: hidden;
		z-index: 10;
		.select-info {
			z-index: 24;
			ul {
				li.group-label {
					background: #f9f9f9;
					color: #9e9ece;
					pointer-events: none;
					& + li {
						padding-left: 40px; } } }
			li.tags {
				justify-content: flex-start;
				a.tag-link {
					margin-right: 8px;
					display: inline-block;
					padding: 0 12px;
					font-size: 12px;
					line-height: 24px;
					color: #96a2b7;
					background: #e6e9f1;
					border-radius: 12px;
					text-align: center; } } }
		input {
			display: block;
			width: 100%;
			height: 48px;
			border: none;
			font-size: 16px;
			padding: 0 110px 0 40px; }
		&__row {
			position: relative; }
		&__btn {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0,-50%);
			cursor: pointer;
			height: 17px; } }
	&-popular {
		position: absolute;
		top: 129px;
		left: 0;
		width: 100%;
		padding: 40px 0 15px;
		transition: $transition;
		.wrapper {
			position: relative; }
		ul {
			display: flex;
			flex-flow: row wrap;
			padding-right: 20px;
			height: 48px;
			overflow: hidden;
			li {
				padding: 0 36.4px 28px 0;
				a {
					display: inline-block;
					font-size: 14px;
					line-height: 20px;
					color: $colorGray;
					&:hover {
						color: $colorMain; } } } } }
	&-bar span,&-bar span:before,&-bar span:after {
		display: block;
		height: 2px;
		width: 24px;
		position: absolute;
		top: 50%;
		margin-top: -1.5px;
		left: 50%;
		margin-left: -12px;
		background: $colorMain; }
	&-bar {
		position: absolute;
		top: 50%;
		left: 36px;
		transform: translate(0,-50%);
		width: 24px;
		height: 24px;
		z-index: 1000;
		cursor: pointer;
		display: none;
		span {
			&:after {
				content: '';
				transition: all .2s ease;
				margin-top: 6px; }
			&:before {
				content: '';
				transition: all .2s ease;
				margin-top: -9px; } } }
	&.header-open {
		background: #fff;
		box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
		.header {
			&-popular {
				ul {
					height: auto; } } }
		.popular {
			&-btn {
				background: #F2F5FB; }
			&-dots {
				display: none; }
			&-close {
				display: block; } } }
	&.header-find {
		.header {
			&-top {
				background: #fff;
				box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
				border-color: #EBEEF5; }
			&-search {
				opacity: 1;
				visibility: visible; } }
		.search-ico {
			border-color: #EBEEF5;
			svg {
				display: none;
				&.search-close {
					display: block; } } } }
	&.header-dark {
		background: $colorMain;
		.header {
			&-top {
				background: $colorMain; } }
		.search-ico {
			border-color: transparent; } }
	&.header-second {
		padding-bottom: 40px;
 }		//margin-bottom: 81px
	&.header-dark {
		.header {
			&-top {
				border-color: #48566E;
				.header-search {
					background: $colorMain;
					input {
						background: $colorMain;
						color: #fff; }
					&__btn {
						svg {
							path {
								fill: #fff; } } } } }
			&-logo {
				.logo-path {
					fill: #fff; } }
			&-bar span,&-bar span:before,&-bar span:after {
				background: #fff; }
			&-list {
				li {
					a {
						color: #fff;
						&:hover {
							color: $colorAccent; } } } }
			&-user::after {
				border-top-color: #fff; }
			&-ico {
				svg {
					path {
						fill: #fff; } }
				&:hover {
					background: #48566E; } } }
		.search-ico {
			background: $colorMain;
			svg {
				path {
					fill: #fff; } }
			&:hover {
				background: #48566E; } }
		.drop.drop-active .header-ico {
			background: #48566E; } } }

.drop {
	position: relative;
	&-info {
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: 22px;
		width: 248px;
		background: #fff;
		box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
		border-radius: $borderRadius;
		overflow: hidden;
		z-index: 20;
		transition: $transition;
		opacity: 0;
		visibility: hidden;
		&.user-info {
			left: auto;
			right: -20px; }
		&.bell-info {
			width: 288px; } }
	&-block {
		padding: 8px 0; }
	&-over {
		height: 382px;
		overflow-x: hidden;
		overflow-y: auto; }
	&-bottom {
		text-align: center;
		a {
			display: block;
			font-size: 16px;
			line-height: 48px;
			color: $colorGray;
			background: #EBEEF5; } }
	&-mob {
		display: none; }
	&.drop-active {
		.header-ico {
			background: #e6e9f1; }
		.drop-info {
			opacity: 1;
			visibility: visible;
			margin-top: 12px; }
		.header-user::after {
			transform: translate(0,-50%) rotate(180deg); } } }

.bell {
	&-block {
		padding: 24px;
		border-bottom: 1px solid #EBEEF5;
		&:last-child {
			border-bottom: none; }
		span {
			color: $colorGray;
			font-size: 12px;
			font-style: italic; } }
	&-title {
		display: inline-block;
		font-size: 14px;
		line-height: 20px;
		color: $colorMain;
		margin-bottom: 12px;
		a {
			color: $colorMain; } }
	&-date {
		font-family: $HelveticaNeueCyr;
		display: block;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		a {
			color: $colorGray; } } }

.user {
	&-top {
		padding: 24px;
		border-bottom: 1px solid #EBEEF5;
		span {
			display: block;
			font-size: 10px;
			line-height: 1.6;
			font-weight: 500;
			color: $colorGray;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			padding-bottom: 4px; }
		p {
			font-size: 20px;
			line-height: 1.2;
			a {
				color: $colorMain;
				@media only screen and (max-width : 700px) {
					color: #ffffff; } }
			b {
				font-weight: normal;
				color: $colorGray; } } }
	&-accordeon {
		border-bottom: 1px solid #EBEEF5;
		@media only screen and (max-width : 700px) {
			border-bottom-color: #4c5b73; }

		ul {
			display: none;
			padding: 6px 0;
			li {
				a {
					display: block;
					padding: 6px 24px;
					font-size: 12px;
					line-height: 20px;
					color: $colorGray;
					&:hover {
						color: $colorAccent; }
					&.active {
						color: $colorMain; } } } } }
	&-name {
		display: block;
		padding: 13px 24px;
		font-size: 14px;
		line-height: 20px;
		color: $colorMain;
		cursor: pointer;
		transition: $transition;
		&:hover {
			background: #F7FAFF;
			color: $colorAccent;
			@media only screen and (max-width : 700px) {
				background: none; } } }

	&-bottom {
		a {
			display: block;
			padding: 13px 24px;
			font-size: 14px;
			line-height: 20px;
			color: $colorGray;
			&:hover {
				color: $colorMain; } } } }

.nano {
	position: relative; }

.search-ico {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	cursor: pointer;
	border-radius: $borderRadius;
	margin-left: 18px;
	border: 1px solid transparent;
	transition: $transition;
	z-index: 11;
	position: absolute;
	top: 0;
	right: 36px;
	svg {
		&.search-close {
			display: none; } }
	&:hover {
		background: #E6E9F1; } }

.popular {
	&-btn {
		position: absolute;
		top: -13px;
		right: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 48px;
		width: 48px;
		height: 48px;
		border-radius: $borderRadius;
		cursor: pointer;
		transition: $transition;
		&:hover {
			background: #E6E9F1; } }
	&-close {
		display: none; } }

@media only screen and (max-width : 1250px) {
	.header {
		&-list {
			padding: 0;
			li {
				padding: 0 10px; } } } }

@media only screen and (max-width : 1150px) {
	.header {
		&-list {
			li {
				padding: 0 10px;
				a {
					font-size: 14px; } } }
		.main-btn {
			min-width: auto;
			padding: 8px 15px;
			font-size: 14px; }
		&-link {
			font-size: 14px;
			line-height: 36px;
			margin-right: 0; }
		&-info {
			min-width: 200px; }
		&-user {
			margin: 0 10px 0 20px; }
		&.header-dark {
			background: $colorMain;
			.header {
				&-top {
					background: $colorMain; } } } } }

@media only screen and (max-width : 1020px) {
	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		padding-bottom: 0;
		background: #f2f5fb;
		z-index: 100;
		&-bar {
			display: block; }
		&-top {
			padding: 28px 0;
			position: relative;
			.wrapper {
				padding: 0 36px;
				position: static; } }
		&-logo {
			margin: 0 auto;
			svg {
				width: auto;
				max-height: 40px; } }
		&-nav {
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			background: #fff;
			border-top: 1px solid transparent;
			z-index: 98;
			overflow-x: hidden;
			overflow-y: auto;
			max-height: calc(100vh - 96px); }
		&-row {
			display: block;
			padding: 72px 36px;
			height: 100%; }
		&-list {
			display: block;
			text-align: center;
			padding-bottom: 44px;
			li {
				padding: 0 0 38px;
				a {
					font-size: 24px;
					line-height: 1;
					color: #fff; } } }
		&-btns {
			display: block;
			max-width: 400px;
			margin: 0 auto;
			.main-btn {
				display: block;
				width: 100%;
				padding: 14px 24px;
				font-size: 16px;
				margin: 8px 0 0; } }
		&-link {
			display: block;
			width: 100%;
			border-radius: $borderRadius;
			padding: 13px 24px;
			font-size: 16px;
			line-height: 20px;
			border: 1px solid #53627B;
			color: #fff; }
		&-popular {
			display: none; }
		&-search {
			top: 100%;
			margin-top: 1px;
			display: flex;
			align-items: center;
			padding: 0 36px;
			box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
			form {
				width: 100%; }
			&__row {
				width: 100%; }
			input {
				padding: 0 60px 0 0; }
			&__btn {
				left: auto;
				right: 0; } }
		&-info {
			flex-flow: row wrap;
			justify-content: end;
			margin: 0 -36px; }
		&-ico {
			svg {
				path {
					fill: #fff; } } }
		&-mes,&-bell,&-profile {
			width: 100%;
			margin: 0;
			.drop-info {
				position: static;
				margin: 0;
				width: 100%;
				text-align: left;
				background: #48566E;
				border-radius: 0;
				box-shadow: none;
				&.user-info {
					margin: 0; } } }
		&-profile {
			order: -1;
			.drop-top {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				width: 100%;
				height: 70px;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 4px;
					transform: translate(0,-50%);
					width: 18px;
					height: 10px;
					background: url(../img/arrow-down.svg) no-repeat center;
					background-size: contain;
					transition: $transition; } } }
		&-ico {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 28px;
			height: 28px;
			span {
				top: 0;
				right: 0; }
			&:hover {
				background: transparent; } }
		&-bell {
			.drop-top {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 70px; } }
		&-bl {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 70px; }
		&-user {
			min-width: 40px;
			height: 40px;
			padding: 0;
			margin: 0 24px 0 0;
			&::after {
				display: none; } }
		&-mes {
			padding: 0 36px; }
		&.header-log {
			.header-row {
				padding-bottom: 0; } }
		&.header-active {
			.header {
				&-top {
					background: $colorMain;
					border-color: #48566E;
					.search-ico {
						svg {
							path {
								fill: #fff; } } } }
				&-logo {
					.logo-path {
						fill: #fff; } }
				&-nav {
					background: $colorMain;
					border-color: #48566E; }
				&-bar {
					span {
						background: transparent;
						&:after {
							transform: rotate(-135deg);
							margin-top: 0;
							background: #fff; }
						&:before {
							transform: rotate(135deg);
							margin-top: 0;
							background: #fff; } } } } }
		&.header-second {
			padding: 0; } }
	.search-ico {
		top: 50%;
		transform: translate(0,-50%);
		right: 21px;
		margin: 0;
		svg {
			width: 18px;
			height: 18px;
			path {
				transition: $transition; } } }
	.drop {
		border-top: 1px solid #48566E;
		padding: 0 36px;
		transition: $transition;
		&-mob {
			display: block;
			font-size: 16px;
			line-height: 24px;
			color: #fff; }
		&-info {
			display: none;
			transition: 0s;
			&.bell-info {
				padding-bottom: 20px; } }
		&-over {
			height: 240px; }
		&-bottom {
			a {
				background: $colorAccent;
				color: #fff; } }
		&.drop-active {
			background: #48566e;
			.header {
				&-ico {
					background: transparent; } }
			.drop-top {
				&::after {
					transform: translate(0,-50%) rotate(180deg); } } } }
	.bell {
		&-block {
			padding: 14px 0;
			border-bottom-color: #4C5B73; }
		&-title {
			color: #fff;
			a {
				color: #fff; } } }
	.user {
		&-top {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			border-bottom-color: #4C5B73;
			padding: 16px 0;
			span,p {
				padding: 0;
				font-size: 14px;
				line-height: 20px;
				color: #fff; } }
		&-name {
			border-bottom-color: #4C5B73;
			color: #fff;
			padding: 13px 0;
			&:hover {
				color: #fff; } }
		&-accordeon {
			ul {
				border-bottom-color: #4C5B73;
				li {
					a {
						color: #fff;
						padding: 6px 0;
						&:hover {
							color: #fff; } } } } }
		&-bottom {
			a {
				padding: 13px 0;
				color: $colorGray;
				&:hover {
					color: $colorGray; } } } } }

@media only screen and (max-width : 700px) {
	.header {
		&-bar {
			left: 20px; }
		&-top {
			padding: 16px 0;
			.wrapper {
				padding: 0 20px; } }
		&-nav {
			max-height: calc(100vh - 73px); }
		&-row {
			padding: 48px 20px 75px;
			transition: $transition; }
		&-list {
			padding-bottom: 16px;
			li {
				padding-bottom: 32px;
				a {
					font-size: 20px; } } }
		&-btns {
			.main-btn {
				font-size: 14px;
				padding: 10px 20px;
				max-width: 100%; } }
		&-link {
			font-size: 14px;
			padding: 9px 20px;
			&.second-btn {
				color: #ffffff;
				background-color: #53627b;
				margin-right: 0;
				max-width: none!important;
				border-color: $colorAccent; } }
		&-search {
			padding: 0 20px; }
		&-info {
			margin: 0 -20px; }
		&-bell {
			.drop-top {
				height: 54px; } }
		&-bl {
			height: 54px; }
		&-profile {
			.drop-top {
				height: 54px; } }
		&-user {
			min-width: 24px;
			width: 24px;
			height: 24px;
			margin-right: 16px;
			img {
				max-width: 24px; } }
		&-mes {
			padding: 0 20px; } }
	.search-ico {
		right: 9px; }
	.drop {
		padding: 0 20px;
		&-mob {
			font-size: 14px;
			line-height: 20px; } } }

