@import "vars";
.mobile_header_panel {
	display: none; }
.mobile {
	.top .top-overlay {
		background: rgb(255, 255, 255) transparent;
		background: rgba(255, 255, 255, 0.6);
		padding: 32px; }
	.menu {
		position: fixed;
		left: 0;
		top: 44px;
		width: 0;
		height: 0;
		z-index: 150; }

	.app-menu-container {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		height: 100%;
		-webkit-transform: translateY(100%);
		transform: translateY(100vh);
		z-index: 170; }

	.app-menu {
		background-color: #ffffff;
		color: #263238;
		position: fixed;
		border-top-right-radius: 40px;
		border-top-left-radius: 40px;
		overflow: hidden;
		width: 100%;
		height: 85%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		will-change: transform;
		z-index: 160;
		pointer-events: auto;
		bottom: 0;

		&-dragger {
			position: absolute;
			width: 60px;
			height: 5px;
			left: 50%;
			margin-left: -30px;
			top: 8px;
			background: #CCD3DC;
			border-radius: 5px; } }

	.menu--visible .app-menu-container {
		-webkit-transform: none;
		transform: none;
		pointer-events: none; }

	.menu--animatable .app-menu-container {
		transition: all 130ms ease-in; }

	.menu--visible.menu--animatable .app-menu-container {
		transition: all 330ms ease-out; }

	.menu-background {
		content: '';
		display: block;
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		will-change: opacity;
		display: none;
		transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1); }

	.menu--visible .menu-background {
		opacity: 0.5;
		pointer-events: auto; }

	.menu--background-visible .menu-background {
		display: block; }

	.app-menu-open .app-menu {
		-webkit-transform: none;
		transform: none;
		transition: all 300ms ease-out; }

	.menu--background-visible .app-menu-background {
		display: block; }

	.no-transition {
		transition: none !important; }

	.app-menu-background {
		background-color: rgba(0, 0, 0, 0.4);
		display: none;
		height: 100%;
		opacity: 0;
		position: fixed;
		top: 0;
		width: 100%; }
	.footer {
		display: none!important; }
	.mobile_header_panel {
		position: absolute;
		display: flex;
		width: 100%;
		z-index: 9;
		align-items: center;
		justify-content: center;
		padding: 40px 24px;
		font-size: 14px;
		font-weight: 500;
		svg {
			position: absolute;
			left: 10px;
			top: 50%;
			margin-top: -8px; }
		text-align: center;
		color: #ffffff; }

	padding-top: 0px;
	.header {
		display: none!important; }
	.marathon-main {
		display: -webkit-box;
		display: -moz-box;
		display: box;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		box-orient: vertical;
		min-height: auto!important;
		.main-btn {
			max-width: 100%; }
		.marathon-img {
			max-height: 245px;
			overflow: hidden;
			display: flex;
			align-items: center;
			background: #3f4d64;
			img {
				opacity: .65; } }
		.first_block {
			-webkit-box-ordinal-group: 1;
			-moz-box-ordinal-group: 1;
			box-ordinal-group: 1; }
		.second_block {
			-webkit-box-ordinal-group: 2;
			-moz-box-ordinal-group: 2;
			box-ordinal-group: 2;
			display: block; }
		.marathon-list,
		.marathon_block_category {
			display: none; } }
	.marathon-subttile {
		display: none; }
	.org_block {
		.marathon-block__row {
			margin-bottom: 24px;
			display: flex;
			position: relative;
			.marathon-block__info {
				margin-left: 16px;
				padding: 0; }
			.marathon-block__img {
				img {
					width: 64px;
					max-width: 64px; } }
			.marathon-block__ball {
				position: relative;
				top: auto;
				bottom: auto;
				right: 0;
				z-index: 1; } }
		.marathon-block__b {
			align-items: flex-end;
			span {
				font-size: 24px;
				line-height: 24px;
				color: $colorAccent;
				padding-right: 0; }
			b {
				border-color: transparent;
				svg {
					path {
						fill: $colorAccent; } } } } } }
.user-bottom-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #ffffff;
	padding: 8px;
	color: #96A2B7;
	.drop-mob {
		color: #96A2B7;
		font-size: 10px; }
	.ico {
		width: 28px;
		height: 28px;
		display: block;
		margin: 0 auto;
		text-align: center; }
	svg {
		fill: #96A2B7;
		path {
			fill: #96A2B7; } }
	.active {
		.drop-mob {
			color: $colorAccent; }
		svg {
			fill: $colorAccent;
			path {
				fill: $colorAccent; } } } }
