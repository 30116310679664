@import "vars";
/* This is the core CSS of Tooltipster */

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
    /* this ensures that a constrained height set by functionPosition,
     *if greater that the natural height of the tooltip, will be enforced
     *in browsers that support display:flex */
    display: flex;
    pointer-events: none;

    /* this may be overriden in JS for fixed position origins */
    position: absolute; }

.tooltipster-box {
    /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
     *and flex-basis auto for IE11- (at least) */
    flex: 1 1 auto; }

.tooltipster-content {
    /* prevents an overflow if the user adds padding to the div */
    box-sizing: border-box;

    /* these make sure we'll be able to detect any overflow */
    max-height: 100%;
    max-width: 100%;
    overflow: auto; }

.tooltipster-ruler {
    /* these let us test the size of the tooltip without overflowing the window */
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden; }

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;

    &.tooltipster-show {
        opacity: 1; } }


/* fade */

@keyframes tooltipster-fading {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

.tooltipster-update-fade {
    animation: tooltipster-fading 400ms; }


/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 *
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins. */

/* .tooltipster-box */

.tooltipster-sidetip {
    .tooltipster-box {
        background: $colorGray;
        border-radius: $borderRadius;

        text-align: left;
        background: #fff;
        box-shadow: 0px 5px 20px rgba(150, 162, 183, 0.25); }

    &.tooltipster-bottom .tooltipster-box {
        margin-top: 8px; }

    &.tooltipster-left .tooltipster-box {
        margin-right: 8px; }

    &.tooltipster-right .tooltipster-box {
        margin-left: 8px; }

    &.tooltipster-top .tooltipster-box {
        margin-bottom: 8px; }

    .tooltipster-content {
        font-size: 12px;
        line-height: 16px;
        color: $colorMain;
        padding: 12px; }

    .tooltipster-arrow {
        overflow: hidden;
        position: absolute; }

    &.tooltipster-bottom .tooltipster-arrow {
        height: 10px;

        /* half the width, for centering */
        margin-left: -10px;
        top: 0;
        width: 20px; }

    &.tooltipster-left .tooltipster-arrow {
        height: 20px;
        margin-top: -10px;
        right: 0;

        /* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
         *been positioned yet */
        top: 0;
        width: 10px; }

    &.tooltipster-right .tooltipster-arrow {
        height: 20px;
        margin-top: -10px;
        left: 0;

        /* same as .tooltipster-left .tooltipster-arrow */
        top: 0;
        width: 10px; }

    &.tooltipster-top .tooltipster-arrow {
        bottom: 0;
        height: 10px;
        margin-left: -10px;
        width: 20px; }

    .tooltipster-arrow-background, .tooltipster-arrow-border {
        height: 0;
        position: absolute;
        width: 0; }

    .tooltipster-arrow-background {
        border: 10px solid transparent; }

    &.tooltipster-bottom .tooltipster-arrow-background {
        border-bottom-color: #ffffff;
        left: 0;
        top: 3px; }

    &.tooltipster-left .tooltipster-arrow-background {
        border-left-color: #ffffff;
        left: -3px;
        top: 0; }

    &.tooltipster-right .tooltipster-arrow-background {
        border-right-color: #ffffff;
        left: 3px;
        top: 0; }

    &.tooltipster-top .tooltipster-arrow-background {
        border-top-color: #ffffff656;
        left: 0;
        top: -3px; }

    .tooltipster-arrow-border {
        border: 10px solid transparent;
        left: 0;
        top: 0; }

    &.tooltipster-bottom .tooltipster-arrow-border {
        border-bottom-color: white; }

    &.tooltipster-left .tooltipster-arrow-border {
        border-left-color: white; }

    &.tooltipster-right .tooltipster-arrow-border {
        border-right-color: white; }

    &.tooltipster-top .tooltipster-arrow-border {
        border-top-color: white; }

    .tooltipster-arrow-uncropped {
        position: relative; }

    &.tooltipster-bottom .tooltipster-arrow-uncropped {
        top: -10px; }

    &.tooltipster-right .tooltipster-arrow-uncropped {
        left: -10px; } }

/* .tooltipster-content */

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
 *corresponds to the arrow we want to display */

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

/* .tooltipster-arrow-background */

/* .tooltipster-arrow-border */

/* tooltipster-arrow-uncropped */
