@import "vars";

.articles {
	&-row {
		padding-bottom: 56px; } }

.article {
	margin-bottom: 24px;
	&-wrap {
		display: flex;
		flex-flow: row nowrap;
		align-items: end;
		padding: 40px 230px 40px 40px;
		background: #fff;
		border-radius: $borderRadius;
		position: relative;
		transition: $transition;
		position: relative;
		&:hover {
			box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
			.article {
				&-img {
					&__wrap {
						transform: scale(1); } } } } }
	&-img {
		min-width: 232px;
		height: 118px;
		position: relative;
		overflow: hidden;
		margin-right: 40px;
		.post-al {
			top: 0;
			&-participant {
				top: auto; } }
		&__wrap {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			transition: $transition;
			transform: scale(1.05); } }
	&-extra {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0,-50%);
		padding: 40px 40px 40px 0;
		text-align: right;
		.text-anim {
			justify-content: flex-end;
			font-size: 20px;
			line-height: 1.6;
			margin-bottom: 26px;
			color: $colorMain;
			a {
				&:not(.main-btn) {
					color: $colorMain; } }
			svg, img {
				margin: 0 5px 0 0;
				&:last-child {
					margin: 0 15px 0 0; } } } }
	&-status {
		font-size: 12px;
		line-height: 20px;
		color: $colorGray; }
	.post {
		&-title {
			min-height: auto;
			margin-bottom: 26px;
			@media only screen and (min-width : 1000px) {
				min-width: 300px; } }
		&-info {
			&__bottom {
				padding: 0;
				max-width: 310px; } } } }

@media only screen and (max-width : 900px) {
	.article {
		&-wrap {
			padding: 40px 40px 100px; }
		&-extra {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			top: auto;
			right: auto;
			transform: translate(0,0);
			bottom: 40px;
			left: 40px;
			padding: 0;
			.post-category,.text-anim {
				margin: 0; }
			.text-anim {
				margin: 0 30px; } } } }

@media only screen and (max-width : 768px) {
	.articles {
		&-row {
			display: flex;
			flex-flow: row wrap;
			margin: 0 -12px; } }
	.article {
		width: 50%;
		padding: 0 12px 24px;
		&-wrap {
			flex-flow: row wrap;
			padding: 0 0 56px;
			height: 100%; }
		&-info {
			order: -1;
			padding: 32px;
			width: 100%;
			.post {
				&-title {
					min-height: 72px;
					margin: 0; }
				&-info__bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: 17px 32px;
					border-top: 1px solid #EBEEF5;
					max-width: 100%;
					ul {
						max-width: 310px;
						padding: 0; } } } }
		&-img {
			width: 100%;
			height: 172px;
			margin: 0;
			.post-al {
				top: 24px; } }
		&-extra {
			display: block;
			position: static;
			width: 100%;
			padding: 32px;
			text-align: left;
			.text-anim {
				font-size: 16px;
				line-height: 24px;
				margin: 12px 0 4px;
				justify-content: end;
				svg {
					order: 10;
					margin: 0 0 0 16px; } } } } }

@media only screen and (max-width : 700px) {
	.article {
		&-info,&-extra {
			padding: 24px; }
		&-info {
			.post-info__bottom {
				padding: 17px 24px; } } } }

@media only screen and (max-width : 600px) {
	.articles {
		&-row {
			padding-bottom: 32px;
			margin: 0; } }
	.article {
		max-width: 500px;
		width: 100%;
		padding: 0;
		margin: 0 auto 8px;
		&-info {
			.post {
				&-title {
					min-height: auto; } } } } }
