@import "vars";

.step {
	padding-bottom: 80px;
	.page-title {
		text-align: center; }
	&-list {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding-bottom: 80px;
		li {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			width: 25%;
			font-size: 48px;
			line-height: 1;
			font-weight: 300;
			color: $colorGray;
			padding: 0 20px 42px 20px;
			border-bottom: 2px solid transparent;
			transition: $transition;
			span {
				display: inline-block;
				max-width: 130px;
				padding-left: 24px;
				font-size: 10px;
				line-height: 1.5;
				font-weight: 500;
				letter-spacing: 0.1em;
				text-transform: uppercase; }
			&.active,&.active-second {
				color: $colorAccent;
				border-color: $colorAccent;
				span {
					color: $colorMain; } }
			&.active-second {
				color: $colorMain; }
			&:last-child {
				margin-right: 0; } }
		&__33 {
			li {
				width: 33.333%; } } }
	&-wrap {
		max-width: 650px;
		margin: 0 auto; }
	&-block {
		padding-bottom: 48px;
		.block-with_icon {
			display: flex;
			align-items: center;
			svg {
				display: inline-block;
				max-width: 30px;
				height: auto;
				margin-right: 10px;
				path {
					fill: $colorGray; } }
			&:hover {
				path {
					fill: $colorAccent; } } }
		&-s {
			padding-bottom: 40px; }
		&__raffle {
			padding: 3px 0 20px;
			.checkbox {
				margin-bottom: 19px;
				p {
					font-size: 16px; } }
			textarea {
 } }				//margin-bottom: 64px
		&-top {
			padding-top: 48px;
			.users-table {
				padding: 48px 0 0; } }
		&-curators {
			padding-top: 0;
			&__empty {
				margin: 0 auto; } }
		&-coauthors {
			padding-top: 0;
			&__empty {
				margin: 0 auto; } } }
	&-title {
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		padding-bottom: 48px; }
	&-subtitle {
		display: block;
		font-size: 16px;
		line-height: 30px;
		font-weight: normal;
		margin-bottom: 16px;
		.tooltip {
			margin-left: 11px; } }
	&-row {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -12px; }
	&-col {
		width: 50%;
		padding: 0 12px 8px;
		&.wide {
			width: 100%;
			display: flex;
			.main-btn, .second-btn {
				min-width: auto; } }
		&-3-4 {
			width: 75%; }
		&-1-4 {
			width: 25%; }
		&-3,&-1-3 {
			width: 33.333%;
			.checkbox {
				&-big {
					padding-bottom: 140px; } }
			.radio-subtitle {
				margin-bottom: 9px; }
			&.full-access {
				width: 100%;
				p {
					max-width: 100%; } } } }
	&-check {
		&-input {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0 24px 24px;
			z-index: 10;
			div {
				position: relative; }
			input {
				display: block;
				width: 100%;
				height: 48px;
				border: none;
				position: static;
				opacity: 1;
				visibility: visible;
				background: #F2F5FB;
				border-radius: $borderRadius;
				padding: 0 40px 0 24px;
				cursor: auto;
				font-size: 14px; }
			b {
				position: absolute;
				top: 50%;
				right: 24px;
				transform: translate(0,-50%);
				font-size: 14px;
				line-height: 24px;
				font-weight: normal;
				color: $colorMain; } } }
	&-text {
		font-family: $HelveticaNeueCyr;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		padding-bottom: 24px; }
	.checkbox-m {
		margin-top: 32px;
		p {
			font-family: $Rubik;
			font-size: 14px; } }
	.second-btn {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		@media only screen and (max-width : 700px) {
			max-width: 100%; }
		span {
			margin-left: 16px;
			color: $colorMain; } }
	.main-btn {
		display: block;
		width: 100%;
		margin-bottom: 32px; }
	&-bottom {
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray; } }
	&-img {
		padding-bottom: 40px;
		img {
			display: block;
			width: 100%; } }
	&-category {
		display: inline-block;
		font-family: $HelveticaNeueCyr;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		margin-bottom: 16px;
		a {
			color: $colorGray; } }
	.section-title {
		padding-bottom: 40px; }
	&-main {
		.step-col {
			padding-bottom: 40px;
			.step-category {
				margin-bottom: 8px; }
			p {
				font-size: 14px;
				line-height: 20px; } } }
	&-info {
		padding-bottom: 80px;
		word-break: break-word;
		.step-title {
			padding-bottom: 16px; }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 30px;
			padding-bottom: 40px; } }
	&-timeline {
		padding-bottom: 80px; }
	&-btn {
		display: inline-block;
		width: 32px;
		min-width: 32px;
		height: 32px;
		border-radius: 50%;
		position: relative;
		margin-left: 8px;
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%); }
		&__edit {
			border: 1px solid #CCD3DC; } }
	.raffle {
		margin: 0 0 40px; }
	.marathon {
		&-bottom {
			max-width: 100%;
			padding-bottom: 40px; }
		&-ul {
			padding: 0 0 40px; } }
	&-blue {
		.checkbox {
			background: #E3F0FC;
			border-radius: $borderRadius;
			&-subtitle {
				padding: 0;
				margin: 2px 0 18px 40px; }
			&.checkbox-big {
				span {
					right: auto;
					left: 24px; }
				p {
					max-width: 540px; } } } }
	.step-block__raffle-second {
		padding: 0; }
	&-frame {
		padding: 40px;
		border: 1px solid #E3E6EF;
		border-radius: $borderRadius;
		&__small {
			margin-bottom: 40px; } }
	&-services {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding-bottom: 32px;
		li {
			padding-right: 32px;
			font-size: 14px;
			line-height: 20px;
			color: $colorMain;
			svg path {
				transition: $transition; }
			a {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				color: $colorMain;
				svg {
					margin-right: 12px; }
				&.active {
					color: $colorAccent;
					svg path {
						fill: $colorAccent; } } }
			&:last-child {
				padding-right: 0; } } }
	.involvement-form__row {
		padding: 0; }
	&-checkbox {
		.checkbox {
			margin-bottom: 24px;
			p {
				max-width: 100%; } } }
	&-testing {
		padding: 32px 0 0; }
	&-radio {
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			.step-input {
				width: 100%;
				margin-right: 24px; }
			.radio {
				min-width: 144px;
				width: 144px; } } }
	&-charge {
		padding: 24px 0 40px;
		input {
			margin: 0 16px; } }
	.tooltip {
		margin-left: 12px; }
	.input-block {
		label {
			line-height: 24px; } }
	&-filling {
		.step-title {
			padding-bottom: 33px; }
		&__row {
			border: 2px solid transparent;
			margin-bottom: 8px;
			position: relative;
			&.step-filled {
				span, p, b, a {
					color: $colorGreen; } }
			&.step-active {
				border-color: $colorAccent;
				box-shadow: 0 15px 25px rgba(150, 162, 183, 0.1);
				span,p,b,a {
					color: $colorAccent; } } }
		&__num {
			position: absolute;
			top: 50%;
			left: 22px;
			transform: translate(0,-50%);
			font-size: 14px; }
		&__info {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			padding: 7px 22px 7px 102px;
			p,span {
				font-size: 14px;
				line-height: 30px;
				color: $colorGray; } } }
	&-changes {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding: 65px 0 65px;
		border-bottom: 1px solid #E3E6EF;
		margin-bottom: 80px;
		position: relative;
		.section-title {
			padding: 0;
			b {
				font-weight: normal;
				color: $colorGray; }
			i {
				font-style: normal; }
			span {
				display: none; } }
		.main-btn {
			display: inline-block;
			min-width: auto;
			max-width: 220px;
			margin: 0; }
		&__second {
			padding: 76px 0;
			border-top: 1px solid #E3E6EF;
			margin: 80px 0; } }
	&-arrows {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		a {
			display: inline-block;
			padding: 0 12px;
			&.not-active {
				opacity: .3;
				pointer-events: none; } } }
	.input-block.input-block-m {
		margin: 0; }
	&-ac {
		svg {
			display: none; } } }
.timeline {
	&-col {
		padding: 37px 10px 40px;
		border-top: 1px solid #E3E6EF;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 14px;
			line-height: 25px;
			color: $colorGray;
			word-break: break-all; } }
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-between;
		padding-bottom: 16px; }
	&-name {
		display: block;
		font-size: 16px;
		line-height: 25px;
		font-weight: normal; }
	&-wrap {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		.step-category {
			font-size: 10px;
			line-height: 2;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin: 0 16px 0 0; } }
	&-btn {
		display: flex; } }

@media only screen and (max-width : 1020px) {
	.step {
		.section-title {
			i {
				display: none; }
			span {
				display: inline-block;
				font-size: 24px;
				line-height: 1.5;
				color: $colorMain;
				margin: 0; } } } }

@media only screen and (max-width : 768px) {
	.step {
		padding-bottom: 64px;
		&-list {
			padding-bottom: 64px;
			li {
				font-size: 40px;
				padding: 0 10px 25px 0;
				span {
					font-size: 9px;
					line-height: 16px;
					max-width: 115px;
					padding-left: 20px; } } }
		&-wrap {
			max-width: 100%; }
		&-block {
			padding-bottom: 40px;
			&-s {
				padding-bottom: 32px; } }
		&-changes {
			padding: 40px 0;
			margin-bottom: 40px;
			&__second {
				margin: 40px 0; } } } }

@media only screen and (max-width : 600px) {
	.step {
		padding-bottom: 40px;
		&-list {
			padding-bottom: 40px;
			li {
				width: auto;
				min-width: 45px;
				font-size: 30px;
				padding: 0 10px 18px;
				span {
					padding-left: 24px;
					display: none; }
				&.active {
					width: 90%;
					padding-left: 0;
					span {
						display: block; } } } }
		&-block {
			padding-bottom: 32px;
			&-s {
				padding-bottom: 24px; }
			.select,.input-block {
				margin-bottom: 24px; }
			&__raffle {
				textarea {
					margin-bottom: 40px; } }
			&__second {
				&.step-block .input-block, &.step-block .select {
					margin-bottom: 0; } }
			.checkbox-subtitle {
				font-size: 12px; } }
		&-title {
			font-size: 16px;
			line-height: 30px;
			padding-bottom: 24px; }
		&-subtitle {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 20px; }
		&-col {
			width: 100%;
			&-3 {
				width: 100%;
				.checkbox-big {
					padding: 24px;
					.step-check-input {
						position: static;
						padding: 0;
						margin-top: 20px;
						width: 120px;
						input {
							height: 40px;
							padding: 0 35px 0 20px;
							font-size: 14px; } } } } }
		&-bottom {
			margin-top: 16px; }
		.main-btn {
			margin-bottom: 24px;
			@media only screen and (max-width : 700px) {
				max-width: 100%; } }
		.users {
			&-table {
				padding-bottom: 24px; } }
		&-block-top {
			.users-table {
				padding: 0; } }
		&-img {
			margin: 0 -20px;
			padding-bottom: 32px; }
		&-category {
			margin-bottom: 8px; }
		.section-title {
			padding-bottom: 8px; }
		&-main {
			margin: 0 0 16px;
			.step-col {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				padding: 16px 0;
				border-bottom: 1px solid #E3E6EF;
				.step-category {
					margin: 0; }
				p {
					font-size: 12px; }
				&:last-child {
					border-bottom: none; } } }
		&-info {
			padding-bottom: 32px;
			.step-title {
				padding-bottom: 12px; }
			p {
				font-size: 14px;
				line-height: 24px;
				padding-bottom: 24px; } }
		.marathon {
			&-bottom {
				padding-bottom: 16px; }
			&-ul {
				padding-bottom: 32px; } }
		&-timeline {
			padding-bottom: 48px; }
		&-btns {
			.main-btn {
				margin-bottom: 8px; } }
		&-btn {
			position: absolute;
			top: 0;
			right: 0;
			&__edit {
				right: 40px; } }
		&-changes {
			display: block;
			padding: 24px 0;
			.section-title {
				padding: 0 60px 24px 0;
				i {
					display: inline-block; }
				span {
					display: none; } } }
		&-arrows {
			top: 30px;
			left: auto;
			right: -12px;
			transform: translate(0,0);
			svg {
				width: 12px;
				height: 22px; } }
		&-frame {
			padding: 24px;
			&__small {
				margin: 0 0 22px; } }
		&-testing {
			.input-block {
				margin: 0; } }
		&-charge {
			padding: 16px 0 24px; }
		&-accordeon {
			display: none; }
		&-ac {
			padding: 10px 0;
			margin: 0 0 10px;
			cursor: pointer;
			position: relative;
			svg {
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0,-50%);
				transition: $transition; }
			&.active-ac {
				svg {
					transform: translate(0,-50%) rotate(180deg); } } } }
	.timeline {
		&-btn {
			padding-bottom: 30px; }
		&-wrap {
			.step-category {
				font-size: 9px;
				line-height: 16px;
				margin: 0; } }
		&-col {
			padding: 30px 0 24px;
			p {
				font-size: 12px;
				line-height: 20px; } }
		&-top {
			display: block;
			padding-bottom: 16px;
			position: relative; }
		&-name {
			font-size: 14px;
			line-height: 20px;
			padding-bottom: 8px; }
		&-btn .second-btn {
			width: 100%; } }
	.step-block .textarea-descr__top .input-block, .step-block .textarea-descr__top .select {
		margin: 0; }
	.textarea {
		&-descr {
			&__list {
				min-width: 100px; }
			&__link {
				min-width: 50px; } } } }

@media only screen and (max-width : 450px) {
	.step {
		&-services li {
			padding-right: 22px; }
		&-filling {
			padding-bottom: 15px;
			&__row {
				margin-bottom: 2px; }
			&__info {
				padding: 10px 18px 10px 50px;
				p,span,a {
					font-size: 12px;
					line-height: 20px; } }
			&__num {
				left: 20px; } }
		&-radio {
			&__row {
				display: block;
				.step-input {
					padding-bottom: 15px; } } } }
	.input-block {
		.textarea-descr {
			&__top {
				height: 80px;
				padding-top: 40px;
				justify-content: space-between;
				.select {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 40px;
					&-text {
						border-bottom: 1px solid #ebeff8; } } }
			&__list {
				min-width: 115px; }
			&__link {
				min-width: 50px; }
			textarea {
				height: 200px!important;
				padding: 100px 20px 20px;
				&.short-description {
					padding: 20px; } } } } }
