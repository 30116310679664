@import "vars";

.timeline-name {
	.timeline-step {
		padding-bottom: 0; } }

.timeline-info {
	&.add_step {
		margin-top: 20px; } }

@media only screen and (max-width : 450px) {
	.main-btn,.second-btn {
		max-width: 320px; }
	.main-list {
		.main-btn,.second-btn {
			max-width: 100%; } }
	.about-btns {
		.main-btn,.second-btn {
			margin-left: 0; } } }


@media only screen and (max-width : 768px) {
	.main-btn+.main-btn {
		margin-top: 8px; } }


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; }

input[type=number] {
	-moz-appearance: textfield; }

.external__links {
	display: flex;
	a {
		margin-right: 20px;
		&:last-child {
			margin-right: 0; } } }
.post-img {
	.post-al-external {
		svg {
			top: 0; } } }

.external__social {
	.inputAdd-btn {
		width: 15px;
		text-align: center;
		cursor: auto; } }

.external-btn {
	background: #72757b;
	&:hover {
		background: $colorAccent; } }

.live-translation {
	background: transparent;
	margin: 80px 0;
	color: #ffffff;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	overflow: hidden;
	.accordeon-arrow {
		border: none;
		margin-right: 10px; }
	&.accordeon-active {
		.accordeon-arrow {
			-webkit-transform: translate(0,-50%) rotate(180deg);
			-ms-transform: translate(0,-50%) rotate(180deg);
			transform: translate(0,-50%) rotate(180deg);
			svg path {
				fill: #96a2b7; } } }
	.tab {
		height: 500px;
		@media only screen and (max-width : 450px) {
			margin: 0 0 24px;
			height: auto; } }
	&-header {
		cursor: pointer;
		padding: 20px;
		background: #3f4d64;
		font-weight: 400;
		text-align: center;
		position: relative;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		svg {
			margin-right: 15px; } }
	&:hover {
		.translation-indicator {
			background: #ff3333;
			-webkit-animation: r20 1s 0.4s ease-out infinite;
			-moz-animation: r20 1s 0.4s ease-out infinite;
			animation: r20 1s 0.4s ease-out infinite; } }
	iframe {
		width: 100%!important;
		height: 100% !important;
		@media only screen and (max-width : 450px) {
 } } }			//max-height: 250px

.coupon_author {
	@media only screen and (max-width : 768px) {
		text-align: center;
		display: flex;
		align-items: center;
		.marathon-block__img {
			margin-bottom: 0!important; } } }
.premium {
	.social_share {
		padding: 20px 0;
		margin: 0; } }
.translation-indicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	cursor: pointer;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	margin-right: 15px;

	background: $colorAccent;
	-webkit-animation: r0 2s 0s ease-out infinite;
	-moz-animation: r0 2s 0s ease-out infinite;
	animation: r0 2s 0s ease-out infinite; }

.required_field {
	color: #ff0000;
	font-style: normal;
	margin-left: 3px; }
.bottom_required_fields {
	margin: 10px;
	text-align: center;
	color: #ff0000; }
.ui-datepicker-year {
	height: auto;
	padding: 5px;
	font-size: 14px;
	margin-left: 10px;
	line-height: 20px;
	color: #3f4d64;
	position: relative;
	cursor: pointer;
	background: transparent;
	border: 0;
	border-bottom: 2px solid #ebeef4;
	display: inline-block;
	width: auto!important; }
#rates-block {
	margin: 0 -4px; }
#price_full {
	margin-top: 20px; }
.rates_list {
	.select {
		input {
			display: none; } }
	.description {
		min-height: 65px; }
	.rate-price-input {
		&-block {
			width: 100%; }
		display: flex;
		align-items: center;
		.comission {
			font-size: 14px;
			line-height: 20px;
			display: inline-block;
			margin-left: 8px;
			white-space: nowrap; } }
	.step-subtitle {
		font-size: 14px;
		line-height: 20px;
		margin-top: 16px;
		margin-bottom: 5px; }
	&.step-col {
		&:not(.full-access) {
			padding: 0 4px 8px; } }
	.checkbox-big {
		padding-bottom: 24px;
		.step-check-input {
			position: relative;
			padding: 0;
			margin-top: 20px; } }
	.summary {
		margin: 16px 0;
		.checkbox {
			padding: 0 0 0 30px;
			margin-top: 10px;
			display: flex;
			align-items: center;
			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;
				z-index: 3; }
			span {
				top: 0;
				left: 0;
				right: auto; } } } }


@-webkit-keyframes r0 {
	0% {
		box-shadow: 0 0 4px 2px rgba(26, 255, 179, 0), 0 0 0px 0px #3f4d64, 0 0 0px 0px rgba(26, 255, 179, 0); }

	10% {
		box-shadow: 0 0 2px 2px $colorAccent, 0 0 2px 4px #3f4d64, 0 0 2px 4px $colorAccent; }

	100% {
		box-shadow: 0 0 2px 0px rgba(26, 255, 179, 0), 0 0 0px 10px #3f4d64, 0 0 0px 10px rgba(26, 255, 179, 0); } }

@-moz-keyframes r0 {
	0% {
		box-shadow: 0 0 4px 2px rgba(26, 255, 179, 0), 0 0 0px 0px #3f4d64, 0 0 0px 0px rgba(26, 255, 179, 0); }

	10% {
		box-shadow: 0 0 2px 2px $colorAccent, 0 0 2px 4px #3f4d64, 0 0 2px 4px $colorAccent; }

	100% {
		box-shadow: 0 0 2px 0px rgba(26, 255, 179, 0), 0 0 0px 10px #3f4d64, 0 0 0px 10px rgba(26, 255, 179, 0); } }

@keyframes r0 {
	0% {
		box-shadow: 0 0 4px 2px rgba(26, 255, 179, 0), 0 0 0px 0px #3f4d64, 0 0 0px 0px rgba(26, 255, 179, 0); }

	10% {
		box-shadow: 0 0 2px 2px $colorAccent, 0 0 2px 4px #3f4d64, 0 0 2px 4px $colorAccent; }

	100% {
		box-shadow: 0 0 2px 0px rgba(26, 255, 179, 0), 0 0 0px 10px #3f4d64, 0 0 0px 10px rgba(26, 255, 179, 0); } }

@-webkit-keyframes r20 {
	from {
		box-shadow: 0 0 2px 0px #ff1a1a, 0 0 6px 2px #3f4d64, 0 0 4px 4px #ff1a1a; }

	to {
		box-shadow: 0 0 8px 6px rgba(255, 26, 26, 0), 0 0 4px 10px #3f4d64, 0 0 4px 10px rgba(255, 26, 26, 0); } }

@-moz-keyframes r20 {
	from {
		box-shadow: 0 0 2px 0px #ff1a1a, 0 0 6px 2px #3f4d64, 0 0 4px 4px #ff1a1a; }

	to {
		box-shadow: 0 0 8px 6px rgba(255, 26, 26, 0), 0 0 4px 10px #3f4d64, 0 0 4px 10px rgba(255, 26, 26, 0); } }

@keyframes r20 {
	from {
		box-shadow: 0 0 2px 0px #ff1a1a, 0 0 6px 2px #3f4d64, 0 0 4px 4px #ff1a1a; }

	to {
		box-shadow: 0 0 8px 6px rgba(255, 26, 26, 0), 0 0 4px 10px #3f4d64, 0 0 4px 10px rgba(255, 26, 26, 0); } }

