@import "vars";

.soon {
	//padding-bottom: 184px
	padding-bottom: 40px;
	position: relative;
	&-slider {
		margin: 0 -12px;
		z-index: 20;
		&.slick-dotted.slick-slider {
			margin: 0 -12px; }
		.slick-arrow {
			top: 44%; }
		.slick-dots {
			position: static; } }
	.post {
		display: flex;
		padding-bottom: 52.5px;
		&-wrap {
			//margin-bottom: 50px
			width: 100%;
			&:hover {
 } } }				//margin-bottom: 4px
	.center-btn {
		margin-top: 50px;

		@media screen and (max-width: 767px) {
			margin-top: 24px; } }
	&-bg {
		position: absolute;
		bottom: 0;
		right: -310px;
		transform: translate(0%,50%);
		z-index: -1; }
	&.soon-second {
		padding-bottom: 80px; }
	&--indent {
		margin-top: 120px;

		@media screen and (min-width: 768px) and (max-width: 1023px) {
			margin-top: 80px; }
		@media screen and (max-width: 767px) {
			margin-top: 60px; } } }

.popular-tags {
	position: relative;
	z-index: 50;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 80px;
	&__list {
		display: flex;
		margin-right: 20px;
		max-width: calc(100% - 48px - 20px);
		width: 100%;
		overflow: hidden;
		.popular-tags {
			&__item {
				&:not(.is--visible) {
					opacity: 0;
					pointer-events: none; } } } }
	&__item {
		padding: 0 36px 0 0; }
	&__link {
		display: inline-block;
		font-size: 14px;
		line-height: 20px;
		white-space: nowrap;
		color: $colorGray;
		&:hover {
			color: $colorMain; } }
	&__trigger {
		position: relative;
		z-index: 50;
		&.is--active {
			.popular-tags {
				&__btn {
					background: #E6E9F1; }
				&__dropdown {
					visibility: visible;
					opacity: 1;
					transform: scale(1) translateY(0);
					pointer-events: all; } } } }
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		min-width: 48px;
		width: 48px;
		height: 48px;
		border-radius: $borderRadius;
		cursor: pointer;
		transition: $transition;
		&:hover {
			background: #E6E9F1; } }
	&__dropdown {
		position: absolute;
		top: 100%;
		right: 0;
		z-index: 100;
		transform: scale(0.99) translateY(-0.7em);
		display: block;
		padding: 8px 0;
		overflow: hidden;
		visibility: hidden;
		opacity: 0;
		transform-origin: top;
		min-width: 200px;
		width: fit-content;
		background: #fff;
		border-radius: $borderRadius;
		box-shadow: 0 15px 25px rgba(150, 162, 183, 0.1);
		transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
		transition-duration: 500ms;
		transition-property: opacity, transform;
		pointer-events: none;
		will-change: opacity, transform; }
	&__dropdown-list {
		.popular-tags {
			&__item {
				padding: 0;
				&.is--hidden {
					display: none; } }
			&__link {
				padding: 8px 16px;
				width: 100%; } } } }

@media only screen and (max-width : 900px) {
	.soon {
		&-bg {
			display: none; } } }

@media only screen and (max-width : 768px) {
	.soon {
		padding-bottom: 64px;
		.post-wrap {
			margin: 0;
			&:hover {
				margin-bottom: 0; } }
		&-slider {
			&.slick-dotted.slick-slider {
				padding-bottom: 44px; }
			.slick-dots {
				bottom: 40px; } }
		.center-btn {
			margin-top: 0; } } }

@media only screen and (max-width : 600px) {
	.soon {
		padding-bottom: 48px;
		&-slider {
			&.slick-dotted.slick-slider {
				padding-bottom: 20px; }
			.slick-dots {
				bottom: 20px; } }
		.post {
			max-width: 100%;
			padding: 0 12px 50px; } } }
