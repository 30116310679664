@import "../vars";

.table-btn {
	&:hover {
		background: #E6E9F1; } }

.slider-all {
	.post-title {
		min-height: 90px; } }

.cabinet {
	.cabinet {
		&-control {
			.page-title {
				padding-bottom: 30px; }
			&__row {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 67px; }
			.marathon-select {
				padding: 0; } } } }

.participants {
	&-wrap {
		&.participants-wrap__2 {
			&.participants-wrap__control {
				padding: 0 140px 0 88px;
				.participants {
					&-col {
						&__1 {
							width: 88px;
							min-width: 88px; }
						&__2 {
							width: 190px; } }
					&-slide {
						&__1 {
							width: 36%!important;
							.participants {
								&-block__col {
									&-1 {
										img {
											max-width: 80px;
											border-radius: 0; } } } } }
						&__2 {
							width: 22%!important; }
						&__3 {
							width: 37%!important;
							.participants {
								&-row {
									padding-right: 5px; }
								&-block__col {
									&-3 {
										p span {
											font-size: 14px; } } } } } } } } } } }

.participants-row {
	.part-row,span.part-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		svg {
			margin-right: 8px; }
		b {
			font-weight: normal; } } }

@media only screen and (max-width : 1100px) {
	.participants {
		&-wrap {
			&.participants-wrap__2 {
				&.participants-wrap__control {
					padding-bottom: 48px;
					border: none;
					.slick-dots {
						bottom: -48px; }
					.participants {
						&-slide {
							&__1 {
								width: 40%!important;
								p {
									max-width: 240px; }
								.participants {
									&-block__col {
										&-1 {
											img {
												max-width: 80px;
												border-radius: 0; } } } } }
							&__2 {
								width: 26.666%!important; }
							&__3 {
								width: 33.333%!important;
								.participants {
									&-block__col {
										&-3 {
											p span {
												font-size: 14px; } } } } } } } } } } } }

@media only screen and (max-width : 1020px) {
	.participants-wrap {
		&.participants-wrap__2 {
			&.participants-wrap__control {
				padding-right: 80px;
				.participants {
					&-col {
						&__2 {
							width: 80px; } } } } } } }

@media only screen and (max-width : 768px) {
	.slider-all .post-title {
		min-height: 72px; }
	.participants-wrap {
		&.participants-wrap__2 {
			&.participants-wrap__control {
				.participants {
					&-slide {
						&__1 {
							.marathon-preview {
                            	width: 40px;
                            	display: none; }
							.participants-block__col-1 {
								min-width: auto;
								img {
									display: none; } } } } } } } } }

@media only screen and (max-width : 700px) {
	.participants-wrap {
		&.participants-wrap__2 {
			&.participants-wrap__control {
				.participants {
					&-slide {
						width: 33.333%!important;
						&__2 {
							.participants-block__col {
								&-1 {
									width: 60%; }
								&-2 {
									width: 40%; } } } } } } } } }

@media only screen and (max-width : 600px) {
	.slider-all .post-title {
		min-height: auto; }
	.cabinet .cabinet-control__row {
		padding-bottom: 30px; }
	.participants-wrap {
		&.participants-wrap__2 {
			&.participants-wrap__control {
				padding: 0 60px 40px;
				.participants {
					&-col {
						&__1 {
							width: 60px;
							min-width: 60px;
							.participants-row {
								padding-left: 18px;
								p {
									font-size: 12px; } } }
						&__2 {
							width: 60px;
							min-width: 60px; } } } } } } }

@media only screen and (max-width : 450px) {
	.cabinet .cabinet-control__row {
		display: block;
		padding-bottom: 24px;
		.main-btn {
			display: block;
			max-width: 100%;
			margin-top: 12px; } }
	.participants-wrap {
		&.participants-wrap__2 {
			&.participants-wrap__control {
				.participants {
					&-slide {
						&__3 {
							p,span {
								font-size: 12px; }
							.participants-block__col-3 p span {
								font-size: 12px; }
							.participants-row__top {
								span {
									font-size: 9px; } } } } } } } } }
