@import "vars";

.doesNotExist {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 40px 0 80px;
	text-align: center;
	&.auto-height {
		min-height: auto;
		@media only screen and (max-width : 700px) {
			margin-top: 0px; } }
	.wrapper {
		height: 100%; }
	&-top {
		position: absolute;
		top: 40px;
		left: 0;
		width: 100%;
		text-align: left; }
	&-img {
		padding-top: 0px;
		padding-bottom: 26px;
		svg {
			display: block;
			margin: 0 auto;
			max-width: 580px;
			width: 100%;
			height: auto; } }
	&-mob {
		display: none; }
	.page-title {
		padding-bottom: 25px; }
	p {
		font-family: $HelveticaNeueCyr;
		font-size: 16px;
		line-height: 30px;
		padding-bottom: 40px;
		max-width: 500px;
		margin: 0 auto; }
	&.congratulations {
		padding-top: 0;
		.doesNotExist-img {
			max-width: 475px;
			margin: 0 auto; } } }

@media only screen and (max-width : 1020px) {
	.doesNotExist {
		margin-top: -136px;
		&.congratulations {
			margin-top: 0; } } }

@media only screen and (max-width : 768px) {
	.doesNotExist {
		display: block;
		padding: 28px 0 220px;
		&-top {
			position: static;
			text-align: center;
			padding-bottom: 150px; }
		&-img {
			margin: 0;
			padding: 0 0 20px; }
		p {
			padding-bottom: 64px; } } }

@media only screen and (max-width : 700px) {
	.doesNotExist {
		padding: 16px 0 40px;
		//margin-top: -97px
		&-top {
			padding: 0 0 57px; }
		.main-btn {
			max-width: 280px;
			width: 100%; } } }

@media only screen and (max-width : 600px) {
	.doesNotExist {
		&-img {
			padding-bottom: 5px; }
		.page-title {
			padding-bottom: 16px; }
		p {
			font-size: 14px;
			line-height: 20px;
			padding-bottom: 40px; } } }
