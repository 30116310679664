@import "vars";

.main {
	padding-bottom: 40px;
	// margin-top: -75px
	&-row {
		min-height: 550px;
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-end;
		position: relative;
		.interesting {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%; } }
	&-info {
		max-width: 376px;
		width: 100%;
		padding: 0 15px 115px 0; }
	&-subtitle {
		font-size: 10px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $colorMain;
		margin-bottom: 25px; }
	&-title {
		font-size: 36px;
		line-height: 1.388;
		font-weight: 500;
		padding-bottom: 34px; }
	&-list {
		display: flex;
		flex-flow: row nowrap;
		padding-bottom: 22px;
		.main-btn,.second-btn {
			min-width: auto; }
		.main-btn {}
		.second-btn {
			margin-left: 16px; } }
	&-text {
		font-family: $HelveticaNeueCyr;
		font-size: 14px;
		line-height: 20px;
		color: $colorGray;
		padding-top: 44px; }
	&-img {
		max-width: 894px;
		margin-right: -36px;
		img {
			display: block; } } }

@media only screen and (max-width : 1020px) {
	.main {
		margin: 0;
		&-row {
			min-height: auto; } } }

@media only screen and (max-width : 900px) {
	.main {
		&-img {
			display: none; } } }

@media only screen and (max-width : 768px) {
	.main {
		padding-bottom: 64px;
		&-subtitle {
			font-size: 9px;
			line-height: 16px;
			margin-bottom: 16px; }
		&-title {
			font-size: 30px;
			line-height: 1.3;
			padding-bottom: 24px; }
		&-list {
			padding-bottom: 24px; }
		&-text {
			padding-top: 40px; } } }

@media only screen and (max-width : 450px) {
	.main {
		padding-bottom: 48px;
		&-info {
			max-width: 100%;
			padding: 0 0 72px; }
		&-title {
			font-size: 20px;
			line-height: 1.4; }
		&-list {
			display: block;
			.main-btn,.second-btn {
				width: 100%; }
			.main-btn {
				margin: 0 0 8px; }
			.second-btn {
				margin-left: 0; } }
		&-text {
			padding-top: 32px; } } }
.new-advantages__item {
	.new-main__img {
		@media screen and (max-width: 767px) {
			display: none; } }
	.new-main__mobile {
		min-width: 100%;
		max-width: 350px; }
	.new-advantages__img {
		&-buttons {
			border: none;
			overflow: visible;
			text-align: center;
			a {
				margin: 5px; }
			@media screen and (max-width: 767px) {
				width: 100%;
				max-width: 100%;
				min-width: auto;
				height: auto;
				min-height: auto;
				a {
					margin: 5px;
					width: auto;
					min-width: 150px; } } } } }
