@import "vars";

.testing {
	padding: 80px 0;
	.member-top {
		border-bottom: 1px solid #E3E6EF; }
	&-wrap {
		padding: 80px 0 0;
		max-width: 600px;
		margin: 0 auto; }
	&-step {
		padding-bottom: 56px;
		&__subtitle {
			display: block;
			font-size: 10px;
			line-height: 2.5;
			font-weight: 500;
			color: $colorGray;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			padding-bottom: 12px; }
		&__title {
			font-size: 20px;
			line-height: 1.5;
			font-weight: normal;
			padding-bottom: 40px; }
		.radio {
			display: block;
			margin-bottom: 24px; } }
	.main-btn {
		display: block; }
	&-text {
		text-align: center;
		font-size: 20px;
		line-height: 30px;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $colorMain;
		span {
			color: #65C58C; } } }

@media only screen and (max-width : 768px) {
	.testing {
		padding: 64px 0;
		&-wrap {
			padding-top: 40px;
			max-width: 100%; }
		&-step {
			padding-bottom: 24px; } } }

@media only screen and (max-width : 450px) {
	.testing {
		padding: 48px 0 40px;
		.member-top {
			padding-bottom: 24px; }
		&-wrap {
			padding-top: 32px; }
		&-step {
			padding-bottom: 34px;
			&__subtitle {
				font-size: 9px;
				line-height: 16px;
				padding-bottom: 16px; }
			&__title {
				font-size: 16px;
				line-height: 24px;
				padding-bottom: 24px; }
			.radio {
				margin-bottom: 16px; } } } }
