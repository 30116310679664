.courses {
    position: relative;
    margin-top: 154px;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 80px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 60px;
    }

    &__decor {
        position: absolute;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        &--md {
            right: -167px;
            bottom: -188px;
            width: 370px;
            height: 284px;
            background-image: url(../img/icon-decor-md.svg);
        }

        @media screen and (min-width: 1024px) and (max-width: 1099px) {
            &--md {
                right: -187px;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            &--md {
                bottom: -148px;
                right: -227px;
            }
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &__slider {
        .slick-slide {
            margin: 0 12px;
        }
        .slick-next,
        .slick-prev {
            @media screen and (min-width: 1361px) {
                top: 100px;
                transform: none;
            }
            @media screen and (max-width: 1360px) {
                top: -83px;
                transform: translate(0, -100%);
            }
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                top: -40px;
            }
            @media screen and (max-width: 767px) {
                top: -20.5px;
            }
        }
        .slick-dots {
            position: static;
            margin-top: 49.5px;

            @media screen and (max-width: 767px) {
                margin-top: 23.5px;
            }
        }
    }
    &__img {
        display: block;
        overflow: hidden;
        height: 200px;
        border-radius: 6px;
        img {
            display: block;
            width: 100%;
            height: 100%!important;
            object-fit: cover;
        }

        @media screen and (min-width: 768px) and (max-width: 1199px) {
            height: 172px;
        }
        @media screen and (max-width: 767px) {
            height: 172px;
        }
    }
    &__body {
        padding: 32px 40px 0;

        @media screen and (max-width: 767px) {
            padding: 20px 20px 0;
        }
    }
    &__position {
        display: block;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        color: #96A2B7;
    }
    &__title {
        display: block;
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        color: #3F4D64;
    }
    &__more {
        margin-top: 50px;
        .main-btn,
        .second-btn {
            min-width: 237px;
        }

        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }
}