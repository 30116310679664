@import "vars";

.tooltip {
	display: inline-block;
	text-align: center;
	min-width: 24px;
	width: 24px;
	height: 24px;
	border: 1px solid #E6E9F1;
	background: #E6E9F1;
	border-radius: 50%;
	font-size: 12px;
	line-height: 22px;
	font-weight: normal;
	color: $colorMain;
	position: relative;
	transition: $transition;
	cursor: pointer;
	z-index: 1;
	&:hover {
		border-color: #DDE1E9;
		background: #DDE1E9; } }
