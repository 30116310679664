@import "vars";

.registration {
	.page-title {
		padding-bottom: 40px; } }

.filter {
	background: #fff;
	border-radius: $borderRadius;
	padding: 24px 0;
	margin-bottom: 24px;
	position: relative;
	z-index: 20;
	&-mob {
		display: none;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-bottom: 40px;
		span {
			margin-left: 15px; }
		&__close {
			display: none; } }
	&-top {
		display: none;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding: 32px 26px 32px 36px;
		border-bottom: 1px solid #EBEEF5; }
	&-title {
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase; }
	&-close {
		cursor: pointer; }
	&-plus {
		display: block;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 1px solid #CCD3DC;
		position: relative;
		transition: $transition;
		margin-left: 12px;
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			path {
				transition: $transition; } } }
	&-content {
		position: relative; }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		padding: 0 174px 0 14px; }
	&-col {
		border-right: 1px solid #EBEEF5;
		padding: 0 40px;
		position: relative;
		&__text {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			font-size: 14px;
			line-height: 20px;
			color: $colorMain;
			cursor: pointer;
			white-space: nowrap;
			.select {
				&-text {
					display: block;
					padding: 0 15px 0 0;
					font-size: 14px;
					line-height: 24px;
					&::after {
						right: 0; } }
				&-info {
					left: -24px;
					width: 136px;
					margin-top: 28px; } }
			&.active {
				.filter-plus {
					border-color: #E6E9F1;
					background: #E6E9F1;
					transform: rotate(45deg);
					svg {
						path {
							fill: $colorGray; } } }
				.filter-arrow {
					&::after {
						border-top-color: $colorGray;
						transform: translate(0,-50%) rotate(180deg); } } } }
		&__1 {
			padding-left: 26px;
			.filter-drop {
				left: -14px; } }
		&__6 {
			position: static;
			border-right: none; } }
	&-arrow {
		padding-right: 12px;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0,-50%);
			border-top: 6px solid $colorMain;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			transition: $transition; } }
	&-subtitle {
		display: block;
		font-family: $HelveticaNeueCyr;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		margin-bottom: 6px;
		white-space: nowrap; }
	&-range {
		padding: 14px 24px 28px;
		&__content {
			.filter-subtitle {
				display: none; } }
		&__row {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			padding-bottom: 26px;
			input {
				opacity: 0;
				visibility: hidden;
				width: 0;
				height: 0;
				border: none; }
			span {
				font-size: 14px;
				line-height: 20px;
				color: $colorGray;
				i {
					font-style: normal;
					color: $colorMain; }
				b {
					font-weight: normal; } } } }
	&-btn {
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translate(0,-50%);
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background: #F2F5FB;
		border-radius: $borderRadius;
		padding: 15px 24px;
		white-space: nowrap;
		font-size: 16px;
		line-height: 20px;
		color: $colorMain;
		svg {
			margin-right: 12px; } }
	&-drop {
		position: absolute;
		top: 100%;
		left: 0;
		width: 240px;
		height: 432px;
		background: #fff;
		border-radius: $borderRadius;
		box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
		margin-top: 28px;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		transition: $transition;
		&__content {
			height: 384px; }
		&__list {
			li {
				padding: 14px 20px 14px 56px;
				font-size: 14px;
				line-height: 20px;
				color: $colorMain;
				cursor: pointer;
				transition: $transition;
				position: relative;
				a {
					color: $colorMain; }
				&::after {
					content: '';
					position: absolute;
					top: 20px;
					left: 24px;
					width: 12px;
					height: 9px;
					background: url(../img/check.svg) no-repeat center;
					background-size: contain;
					opacity: 0; }
				&:hover {
					background: #F7FAFF; }
				&.active {
					&::after {
						opacity: 1; } } } }
		.main-btn {
			display: block;
			border-radius: 0; }
		&__time {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			padding: 24px 0 10px;
			li {
				padding: 0 17px 10px;
				font-size: 14px;
				line-height: 20px;
				a {
					font-size: 14px;
					line-height: 20px;
					color: $colorGray;
					&:hover {
						color: $colorAccent; } } } }
		&__datepicker {
			max-width: 850px;
			margin: 0 auto 20px; }
		&__big {
			width: 100%;
			height: auto; }
		&__range {
			width: 280px;
			height: auto; }
		&.active {
			opacity: 1;
			visibility: visible;
			z-index: 100; } }
	&-second {
		background: transparent;
		border: 1px solid #E3E6EF;
		.select-text {
			background: transparent; } }
	.ui-datepicker.ui-widget.ui-widget-content {
		width: 100%!important;
		box-shadow: none; }
	.ui-datepicker .ui-datepicker-title {
		font-weight: 500; }
	&-drop__range {
		.ui-widget.ui-widget-content {
			height: 2px;
			background: #EBEEF5;
			border: none;
			border-radius: 2px; }
		.ui-state-default, .ui-widget-content .ui-state-default {
			top: 50%;
			margin-top: -10px;
			width: 20px;
			height: 20px;
			border: 2px solid $colorAccent;
			border-radius: 50%;
			background: #fff;
			cursor: pointer; }
		.ui-widget-header {
			background: $colorAccent; } } }

.filter-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	top: 0;
	left: 0;
	z-index: 1000;
	background: rgba(#EBEEF5,0.85);
	transition: all 0.3s;
	&.open-overlay {
		visibility: visible;
		opacity: 1; } }

.filter-age {
	.filter-col__text {
		span {
			min-width: 40px; } } }

@media only screen and (max-width : 1300px) {
	.filter {
		&-row {
			justify-content: space-between; }
		&-col {
			padding: 0 15px; } } }

@media only screen and (max-width : 1020px) {
	.filter {
		position: fixed;
		top: 0;
		left: 0;
		width: 372px;
		height: 100%;
		background: #fff;
		padding: 0;
		z-index: 1001;
		transition: $transition;
		opacity: 0;
		visibility: hidden;
		transform: translate(-102%,0);
		&-mob {
			display: flex; }
		&-top {
			display: flex; }
		&-content {
			position: static;
			height: calc(100% - 95px);
			padding: 8px 40px 100px;
			overflow-x: hidden;
			overflow-y: auto; }
		&-row {
			display: block;
			padding: 0; }
		&-col {
			border: none;
			border-bottom: 1px solid #EBEEF5;
			padding: 32px 0;
			&__6 {
				border-bottom: none; } }
		&-last {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 20px 36px 40px;
			background: #fff;
			z-index: 10; }
		&-btn {
			position: static;
			transform: translate(0,0);
			justify-content: center; }
		&-plus {
			position: absolute;
			top: 50%;
			right: 0;
			margin: 0;
			margin-top: -12px; }
		&-col {
			&__text {
				position: relative;
				.select-info {
					left: 0;
					width: 292px; } }
			&__4 {
				.filter-col__wrap {
					display: none; } } }
		&-range {
			padding: 0;
			&__content {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 30px;
				.filter-subtitle {
					display: block;
					margin: 0; } }
			&__row {
				padding: 0;
				span {
					font-size: 12px; } } }
		&-drop {
			display: flex;
			flex-flow: column;
			position: fixed;
			top: 0;
			left: 0!important;
			width: 372px;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			&__btn {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 0 20px 20px;
				.main-btn {
					border-radius: $borderRadius; } }
			&__content {
				height: 100%;
				margin: 40px 0 80px; }
			&__range {
				position: static;
				opacity: 1;
				visibility: visible;
				width: 100%;
				overflow: visible;
				.main-btn {
					display: none; } }
			&__time {
				display: none; } }
		&.active {
			opacity: 1;
			visibility: visible;
			transform: translate(0,0); } } }

@media only screen and (max-width : 600px) {
	.filter {
		&-top {
			padding: 24px 20px; }
		&-title {
			font-size: 16px;
			line-height: 24px; }
		&-content {
			height: calc(100% - 73px);
			padding: 8px 24px 80px; }
		&-col {
			padding: 20px 0;
			&__text {
				.select-info {
					width: 324px;
					margin-top: 20px; } } }
		&-last {
			padding: 24px; }
		&-btn {
			padding: 10px 24px; }
		&-drop {
			&__list {
				li {
					padding: 14px 20px 14px 52px; } } } } }

@media only screen and (max-width : 450px) {
	.filter {
		width: 100%;
		&-col {
			&__text {
				.select-info {
					width: 272px; } } }
		&-drop {
			width: 100%;
			&__datepicker {
				width: 100%;
				padding: 0 20px; } }
		.ui-datepicker {
			.ui-widget.ui-widget-content {
				width: 100%!important; }
			.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
				width: 40px;
				height: 40px;
				font-size: 14px;
				line-height: 36px; } } } }
