@import "vars";
.download-photo {
	input {
		position: absolute;
		width: 1px;
		height: 1px;
		left: 0;
		top: 0;
		opacity: 0; } }
.checkbox, .radio, .input-upload, .toggle {
	display: inline-block;
	position: relative;
	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		z-index: 3; } }

.checkbox, .radio {
	padding: 0 0 0 40px;
	text-align: left;
	&.rate-radio {
		padding: 0;
		text-align: center;
		width: 100%;
		height: 100%;
		span {
			top: auto;
			bottom: 20px;
			left: 50%;
			margin-left: -12px; } }
	span {
		position: absolute;
		top: 0;
		left: 0;
		border: 1px solid #CCD3DC;
		transition: $transition;
		width: 24px;
		height: 24px; } }

.checkbox {
	&-subtitle {
		display: block;
		font-size: 14px;
		line-height: 24px;
		font-weight: normal;
		padding-right: 30px;
		margin-bottom: 12px; }
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 3px;
		svg {
			opacity: 0;
			visibility: hidden;
			transition: $transition; } }
	p {
		font-family: $HelveticaNeueCyr;
		font-size: 12px;
		line-height: 20px;
		padding: 2px 0;
		a {
			color: $colorAccent;
			position: relative;
			z-index: 5;
			&:hover {
				text-decoration: underline; } } }
	input {
		&:checked {
			& + span {
				border: 2px solid $colorAccent;
				svg {
					opacity: 1;
					visibility: visible; } } } }
	&.checkbox-big {
		width: 100%;
		height: 100%;
		background: #fff;
		border-radius: $borderRadius;
		padding: 24px;
		&.checked {
			background: $colorGreen;
			color: #ffffff;
			@media only screen and (max-width : 768px) {
				width: auto;
				margin-bottom: 10px;
				padding: 9px 10px; }
			svg {
				path {
					fill: #ffffff; } }
			input:checked+span {
				border-color: #ffffff; } }
		span {
			top: 24px;
			left: auto;
			right: 24px; }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray;
			max-width: 238px; } }
	&.checkbox-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		.checkbox-subtitle {
			padding-right: 0;
			padding-left: 10px;
			.tooltip {
				margin-left: 0;
				z-index: 4; } }
		span {
			position: relative;
			top: auto;
			right: auto;
			left: auto; } } }

.radio {
	&-subtitle {
		display: block;
		font-size: 14px;
		line-height: 24px;
		font-weight: normal;
		padding-right: 30px;
		margin-bottom: 12px; }
	span {
		border-radius: 50%;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $colorAccent;
			opacity: 0;
			visibility: hidden;
			transition: $transition; } }
	p {
		font-size: 14px;
		line-height: 24px;
		color: $colorMain;
		b {
			display: none;
			font-weight: normal; } }
	input {
		&:checked {
			& + span {
				border: 2px solid $colorAccent;
				&::after {
					opacity: 1;
					visibility: visible; } } } }
	&.radio-correctly {
		p {
			b {
				display: inline;
				color: #65C58C; } }
		input {
			&:checked {
				& + span {
					border: 2px solid #65C58C;
					&::after {
						opacity: 1;
						visibility: visible;
						background: #65C58C; } } } } }
	&.radio-error {
		p {
			b {
				display: inline;
				color: #FF655B; } }
		input {
			&:checked {
				& + span {
					border: 2px solid #FF655B;
					&::after {
						opacity: 1;
						visibility: visible;
						background: #FF655B; } } } } }
	&.radio-big {
		width: 100%;
		height: 100%;
		background: #fff;
		border-radius: $borderRadius;
		padding: 24px;
		span {
			top: 24px;
			left: auto;
			right: 24px; }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray;
			max-width: 238px; } } }

.input-upload {
	.second-btn {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		color: $colorMain;
		svg {
			margin-right: 16px; } } }

.download {
	&-img {
		position: relative;
		min-width: 96px;
		width: 96px;
		height: 96px;
		border-radius: 3px;
		border: 1px solid #CCD3DC;
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%); } }
	&-info {
		padding-left: 24px;
		max-height: 500px;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray; } }
	&-photo {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		&-big {
			align-items: start;
			.download {
				&-img {
					transition: $transition;
					min-width: 184px;
					height: 100px;
					overflow: hidden;
					cursor: pointer;
					background-size: cover!important;
					background-position: center; }
				&-info {
					p {
						margin-bottom: 10px;
						line-height: 18px;
						&:last-child {
							margin: 0; } } } } } } }

.toggle {
	display: block;
	span {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0,-50%);
		background: #C1CADA;
		border-radius: 20px;
		width: 40px;
		height: 20px;
		transition: $transition;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 2px;
			transform: translate(0,-50%);
			width: 16px;
			height: 16px;
			background: #fff;
			border-radius: 50%;
			box-shadow: 0px 2px 5px rgba(150, 162, 183, 0.5);
			transition: $transition; } }
	p {
		font-size: 14px;
		line-height: 24px;
		padding-right: 45px; }
	input {
		&:checked {
			& + span {
				background: #65C58C;
				&::after {
					left: 22px; } } } } }

@media only screen and (max-width : 450px) {
	.download {
		&-photo {
			display: block; }
		&-img {
			min-width: 72px;
			width: 72px;
			height: 72px; }
		&-info {
			padding: 16px 0 0;
			p {
				font-size: 11px;
				line-height: 16px; } } } }

