.section-top {
    margin-bottom: 80px;
    text-align: center;

    @media screen and (max-width: 1360px) {
        padding-right: 72px;
        text-align: left;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 24px;
    }

    &__title {
        font-size: 30px;
        font-weight: 600;
        color: #3F4D64;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: 24px;
        }
        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
    &__description {
        margin: 32px auto 0;
        max-width: 793px;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: #96A2B7;
        &--light {
            margin-top: 80px;
            font-weight: normal;
            color: #3F4D64;
        }

        @media screen and (max-width: 1360px) {
            margin-left: 0;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            &--light {
                margin-top: 40px;
            }
        }
        @media screen and (max-width: 767px) {
            margin-top: 16px;
            font-size: 16px;
        }
    }
}