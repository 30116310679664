@import "vars";

.preview {
	padding: 0 0 40px;
	&-slider {
		padding-bottom: 40px;
		margin: 0 -12px;
		&.slick-dotted.slick-slider {
			margin: 0 -12px; }
		.slick-arrow {
			top: 35%; } }
	&-slide {
		padding: 0 12px;
		&__wrap {
			height: 200px;
			border-radius: $borderRadius;
			position: relative;
			overflow: hidden;
			&:hover {
				.preview-slide__block {
					transform: scale(1); } } }
		&__block {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			transform: scale(1.05);
			transition: $transition; } } }

@media only screen and (max-width : 768px) {
	.preview {
		padding-bottom: 64px;
		&-slider {
			padding-bottom: 64px; } } }

@media only screen and (max-width : 600px) {
	.preview {
		padding-bottom: 48px;
		&-slider {
			padding-bottom: 48px; } } }

@media only screen and (max-width : 450px) {
	.preview-slide__wrap {
		height: 142px; } }
