@import "vars";

.balance {
	margin-bottom: 76px;
	&-wrap {
		background: #fff;
		border-radius: $borderRadius;
		padding: 32px 250px 32px 32px;
		position: relative; }
	&-btn {
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translate(0,-50%); }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		max-width: 760px; }
	&-col {
		max-width: 330px;
		width: 100%;
		text-align: center;
		&__2 {
			.balance-price {
				color: $colorMain; } } }
	&-subtitle,&-price {
		display: block;
		color: $colorGray; }
	&-subtitle {
		font-size: 10px;
		line-height: 1.6;
		font-weight: 500;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-bottom: 12px; }
	&-price {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		font-size: 48px;
		line-height: 1;
		font-weight: 300;
		.tooltip {
			margin-left: 16px; } } }

.inquiry {
	padding: 24px 240px 24px 44px;
	background: #E3F0FC;
	border-radius: $borderRadius;
	position: relative;
	margin-top: 80px;
	&-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center; }
	&-img {
		min-width: 88px;
		padding-right: 20px; }
	&-subtitle {
		display: block;
		padding-bottom: 4px;
		font-size: 16px;
		line-height: 24px;
		font-weight: normal; }
	&-info {
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray; } }
	&-btn {
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translate(0,-50%);
		.second-btn {
			color: $colorMain; } } }

@media only screen and (max-width : 768px) {
	.balance {
		margin-bottom: 64px;
		&-wrap {
			padding: 32px 32px 104px; }
		&-col {
			max-width: 100%;
			width: 50%; }
		&-btn {
			top: auto;
			right: auto;
			bottom: 32px;
			left: 0;
			transform: translate(0,0);
			width: 100%;
			padding: 0 32px;
			.main-btn {
				display: block; } } }
	.inquiry {
		padding: 24px 200px 24px 30px;
		margin-top: 64px;
		&-img {
			min-width: 66px; }
		&-subtitle {
			font-size: 14px;
			line-height: 20px;
			padding-bottom: 8px; }
		&-btn {
			right: 24px;
			.second-btn {
				min-width: auto;
				font-size: 14px;
				padding: 9px 23px; } } } }

@media only screen and (max-width : 600px) {
	.balance {
		margin-bottom: 48px;
		&-wrap {
			padding: 24px 24px 64px; }
		&-row {
			display: block; }
		&-col {
			width: 100%;
			padding-bottom: 24px; }
		&-subtitle {
			font-size: 9px;
			line-height: 16px;
			padding-bottom: 8px; }
		&-price {
			font-size: 36px;
			line-height: 40px; }
		&-btn {
			padding: 0 24px; } }
	.inquiry {
		padding: 24px 24px 88px;
		margin-top: 24px;
		&-img {
			min-width: 64px; }
		&-btn {
			position: absolute;
			top: auto;
			right: auto;
			transform: translate(0,0);
			bottom: 24px;
			left: 0;
			width: 100%;
			padding: 0 24px;
			.second-btn {
				display: block; } } } }
