@import "vars";

.forum {
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 80px;
		.page-title {
			padding: 0; } }
	&-row {
		padding-left: 312px;
		position: relative; }
	&-title {
		.page-title {
			padding-bottom: 40px; } }
	.users-table__row {
		padding: 24px 40px 0; }
	.profile-top__main {
		max-width: 100%; }
	#sidebar {
		left: auto; }
	&-sidebar {
		position: absolute;
		top: 0;
		left: 0;
		width: 288px;
		&__title {
			display: block;
			font-size: 14px;
			color: $colorMain;
			line-height: 20px;
			padding-bottom: 18px;
			&.active {
				color: $colorAccent; } }
		&__wrap {
			border: 1px solid #E3E6EF;
			border-radius: $borderRadius;
			padding: 40px 40px 18px;
			.contents {
				padding: 0 20px;
				ol, ul {
					margin: 0!important;
					padding: 0!important; }
				li {
					margin-bottom: 10px!important;
					&:last-child {
						margin-bottom: 0!important; }
					&::marker {
						font-size: 12px!important;
						color: #63b7fe; } }
				a {
					text-decoration: none!important; }
				p {
					&:first-child {
						margin-top: 0!important; } }
				p,span,a,li {
					font-size: 14px!important;
					line-height: 18px!important; } }
			ul {
				li {
					padding-bottom: 12px;
					&.active {
						a {
							border-left-color: #5eb5fe; } }
					a {
						display: block;
						padding: 6px 0;
						font-size: 14px;
						line-height: 20px;
						color: $colorMain;
						padding-right: 20px;
						position: relative;
						border-left: 3px solid transparent;
						span {
							display: inline-block;
							color: $colorGray;
							padding-left: 12px; }
						b {
							position: absolute;
							top: 12px;
							right: 0;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background: #000;
							&.color-green {
								background: #66BB6A; }
							&.color-purple {
								background: #7E57C2; }
							&.color-red {
								background: #EF5350; }
							&.color-blue {
								background: #26C6DA; }
							&.color-yellow {
								background: #CDDC39; }
							&.color-brown {
								background: #8D6E63; }
							&.color-gray {
								background: #78909C; }
							&.color-orange {
								background: #FF7043; }
							&.color-graylight {
								background: #BDBDBD; } } } } } } }
	&-search {
		position: relative;
		margin-bottom: 24px;
		input {
			padding-right: 70px; } }
	&-btn {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0,-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 65px;
		cursor: pointer; }
	&-col {
		background: #fff;
		border-radius: $borderRadius;
		padding: 39px 160px 39px 144px;
		margin-bottom: 24px;
		position: relative;
		transition: $transition;
		&__img {
			position: absolute;
			top: 40px;
			left: 40px;
			img {
				display: block;
				max-width: 64px;
				border-radius: 50%; } }
		&__category {
			display: inline-block;
			font-size: 10px;
			line-height: 2;
			font-weight: 500;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $colorGray;
			margin-bottom: 16px; }
		&__title {
			font-size: 20px;
			line-height: 1.5;
			font-weight: normal;
			margin-bottom: 12px;
			a {
				color: $colorMain;
				&:hover {
					color: $colorAccent; } } }
		&__date {
			display: block;
			font-size: 12px;
			line-height: 20px;
			margin-bottom: 24px;
			color: $colorMain;
			span,a {
				color: $colorGray; } }
		&__comment {
			position: absolute;
			top: 76px;
			right: 40px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			font-size: 20px;
			line-height: 1.5;
			color: $colorMain;
			span {
				padding-left: 12px; } }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 14px;
			line-height: 24px;
			color: $colorGray; }
		&:hover {
			box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15); } }
	.pagination {
		margin-top: -24px; }
	&-title {
		a,span {
			font-size: 10px;
			line-height: 2;
			font-weight: 500;
			color: #CDDC39;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			margin-left: 18px;
			vertical-align: top; } }
	&-bottom {
		background: #EBEFF7;
		padding: 80px 0;
		margin: 24px 0 0; }
	&-contact {
		max-width: 600px;
		margin: 0 auto;
		.input-block {
			margin-bottom: 40px; }
		.main-btn {
			display: block;
			width: 100%; } }
	&-subtitle {
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		padding-bottom: 40px; }
	&-second {
		padding: 80px 0;
		border-top: 1px solid #E3E6EF; } }

@media only screen and (min-width : 1020px) {
	.forum {
		&-sidebar {
			&__title {
				display: none; } } } }

@media only screen and (max-width : 1020px) {
	.forum {
		&-row {
			padding-left: 254px; }
		&-sidebar {
			width: 230px;
			&__wrap {
				padding: 25px 15px 18px; } }
		&-col {
			padding: 32px 32px 32px 120px;
			&__comment {
				top: 32px; } } } }

@media only screen and (max-width : 768px) {
	.forum {
		&-top {
			padding-bottom: 40px; }
		&-row {
			padding: 0; }
		&-sidebar {
			position: relative;
			width: 100%;
			padding-bottom: 16px;
			z-index: 10;
			&__wrap {
				padding: 0;
				border: none;
				position: relative;
				ul {
					position: absolute;
					top: 100%;
					left: 0;
					width: 100%;
					background: #fff;
					box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
					border-radius: 0 0 $borderRadius $borderRadius;
					transition: $transition;
					opacity: 0;
					visibility: hidden;
					li {
						padding: 6px 24px; } }
				&.forum-active {
					.forum-sidebar {
						&__title {
							&::after {
								transform: translate(0,-50%) rotate(180deg); } } }
					ul {
						opacity: 1;
						visibility: visible; } } }
			&__title {
				padding: 14px 50px 14px 24px;
				font-size: 14px;
				line-height: 20px;
				color: $colorMain;
				cursor: pointer;
				background: #fff;
				border-radius: $borderRadius;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translate(0,-50%);
					border-top: 4px solid $colorMain;
					border-left: 2px solid transparent;
					border-right: 2px solid transparent;
					transition: $transition; } } }
		&-search {
			margin-bottom: 40px; }
		&-btn {
			width: 50px; }
		&-col {
			padding: 32px 100px 32px 120px;
			&__category {
				font-size: 9px;
				line-height: 16px; }
			&__title {
				font-size: 16px;
				line-height: 24px; }
			&__date {
				margin: 0; }
			p {
				display: none; }
			&__comment {
				top: 64px;
				right: 32px;
				font-size: 16px;
				line-height: 24px;
				span {
					padding-left: 8px; } } }
		&-bottom {
			padding: 64px 0;
			margin-top: 32px; }
		&-contact {
			max-width: 100%; }
		&-subtitle {
			padding-bottom: 32px; }
		&-second {
			padding: 40px 0 64px; } } }

@media only screen and (max-width : 450px) {
	.forum {
		&-top {
			display: block;
			padding-bottom: 16px;
			.page-title {
				padding-bottom: 24px; }
			.main-btn {
				display: block; } }
		&-sidebar {
			&__title {
				padding: 10px 20px;
				font-size: 12px;
				display: block; }
			&__wrap {
				ul {
					li {
						padding: 6px 20px; } } } }
		&-col {
			padding: 76px 24px 24px;
			margin-bottom: 8px;
			&__img {
				top: 24px;
				left: 24px;
				img {
					max-width: 32px; } }
			&__category {
				position: absolute;
				top: 32px;
				left: 76px; }
			&__comment {
				top: 32px;
				right: 24px; }
			&__title {
				margin-bottom: 16px; } }
		&-title {
			a,span {
				display: block;
				margin: 8px 0 0; } }
		&-bottom {
			margin-top: 14px;
			padding: 32px 0 40px; }
		&-subtitle {
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 20px; }
		&-contact {
			.input-block {
				margin-bottom: 24px; } }
		&-second {
			padding: 40px 0; } } }
#scroller {
	background: #63b7fe;
	border-radius: 5px;
	position: fixed;
	right: 10px;
	bottom: 10px;
	width: 32px;
	height: 32px;
	cursor: pointer;
	display: none;
	opacity: .6;
	transition: $transition;
	&:hover {
		opacity: 1; }
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		margin-top: -5px;
		margin-left: -9px;
		height: 10px;
		width: 18px;
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg); } }
@media only screen and (max-width : 768px) {
	#sidebar {
		.contents {
			padding: 0; } } }
@media only screen and (min-width : 768px) {
	.sticky-wrapper {
		#sidebar {
			float: left; }
		.sidebarfixed {
			position: fixed!important;
			top: 0; }
		.forum-row {
			padding: 0; }
		.forum-content {
			margin: 10px 10px 10px 312px; } } }
