@import "vars";

.pagination {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 80px 0;
	ul {
		display: flex;
		flex-flow: row nowrap;
		li {
			border: 2px solid transparent;
			text-align: center;
			width: 48px;
			border-radius: 50%;
			font-size: 14px;
			line-height: 44px;
			color: $colorMain;
			margin: 0 12px;
			a {
				display: inline-block;
				width: 44px;
				font-size: 14px;
				line-height: 44px;
				color: $colorMain;
				&:hover {
					color: $colorAccent; } }
			&.active {
				border-color: $colorAccent;
				color: $colorAccent; } } } }

.dots {
	display: none;
	flex-flow: row nowrap;
	justify-content: center;
	margin: 24px 0 0;
	li {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 1px solid transparent;
		margin: 0 11px;
		transition: $transition;
		position: relative;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: $colorGray;
			transition: $transition; }
		&.active {
			border-color: #CCD3DC;
			&::after {
				background: $colorMain; }
			&:hover {
				background: transparent; } } } }

@media only screen and (max-width : 768px) {
	.pagination {
		padding: 64px 0;
		ul {
			li {
				margin: 0 2px;
				width: 40px;
				line-height: 36px;
				a {
					width: 36px;
					line-height: 36px; } } } }
	.dots {
		&.dots-768 {
			display: flex; } } }

@media only screen and (max-width : 700px) {
	.dots {
		&.dots-700 {
			display: flex; } } }
