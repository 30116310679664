@import "vars";

.works {
	padding-bottom: 80px;
	.wrapper {
		max-width: 1084px; }
	.page-title {
		text-align: center;
		padding-bottom: 24px; }
	&-wrap {
		padding-bottom: 40px; }
	&-list {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		padding-bottom: 40px;
		li {
			padding: 0 20px;
			a {
				display: inline-block;
				padding: 24px 0;
				font-size: 16px;
				line-height: 30px;
				color: $colorMain;
				border-bottom: 2px solid transparent;
				&.active {
					border-bottom-color: $colorAccent; } } } }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding: 78px 0;
		border-bottom: 1px solid #E3E6EF;
		&:nth-child(even) {
			.works {
				&-info {
					order: -1; }
				&-img {
					padding: 0 0 0 30px; } } }
		&:last-child {
			border-bottom: none; } }
	&.dark {
		padding-bottom: 0;
		.works-wrap {
			@media only screen and (max-width : 450px) {
				padding-bottom: 0; } }
		.wrapper {
			max-width: 900px; }
		.works-img {
			min-height: 100px;
			max-height: 160px;
			img {
				max-width: 300px;
				display: block;
				margin: 0 auto;
				@media only screen and (max-width : 450px) {
					max-width: 200px; } } }

		.works-info {
			max-width: 100%;
			color: #ffffff; }
		.works-top {
			padding-right: 0; }
		.works-row {
			border-color: #4a5c77;
			@media only screen and (max-width : 450px) {
				margin-top: 20px; }
			&:nth-child(even) {
				margin-top: 20px;
				.works-info {} } } }

	&-img {
		display: flex;
		align-items: center;
		max-width: 520px;
		width: 100%;
		min-height: 360px;
		padding-right: 30px;
		div {
			width: 100%;
			border: 5px solid #e3e6ef; }
		img {
			max-width: 500px;
			max-height: 500px;
			width: 100%;
			height: auto; } }
	&-info {
		max-width: 392px;
		width: 100%;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 24px; } }
	&-top {
		padding-right: 50px;
		position: relative;
		span {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 48px;
			line-height: 1;
			font-weight: 300;
			color: $colorAccent; } }
	&-title {
		font-size: 30px;
		line-height: 1.3;
		font-weight: normal;
		padding-bottom: 32px; } }

@media only screen and (max-width : 768px) {
	.works {
		.works-video {
			h3 {
				padding-bottom: 0;
				font-size: 16px;
				text-align: center; }
			.works-img {
				padding-bottom: 10px!important; } }
		&-list {
			margin: 0 -20px;
			li {
				a {
					padding: 15px 0; } } }
		&-row {
			display: block;
			padding: 40px 0;
			&:nth-child(even) {
				.works {
					&-img {
						padding: 0 0 30px; } } } }
		&-img {
			min-height: auto;
			text-align: center;
			max-width: 100%;
			padding: 0 0 30px;
			img {
				max-width: 200px;
				height: auto;
				margin: 0 auto; } }
		&-info {
			margin: 0 auto; }
		&-title {
			font-size: 24px; }
		&-top {
			span {
				font-size: 40px; } } } }

@media only screen and (max-width : 450px) {
	.works {
		&-list {
			margin: 0 -10px;
			li {
				padding: 0 10px;
				a {
					font-size: 14px; } } }
		&-info {
			p {
				font-size: 14px;
				line-height: 1.3; } }
		&-title {
			font-size: 20px;
			padding-bottom: 25px; } } }

