@import "vars";

.header {
	&.header-chat {
		padding: 0;
		margin-bottom: 0;
		.header-top {
			border: none; } } }
.chat-section {
	background: #3b485c; }
.chat {
	min-height: calc(100vh - 129px);
	background: $colorMain;
	position: relative;
	.chat-correspondence.empty {
		height: 100%; }
	a {
		&:hover {
			text-decoration: none; } }
	#loader {
		display: none;
		p {
			font-size: 14px;
			color: #96a2b7;
			text-align: center; }
		&.active {
			display: flex;
			align-items: center;
			justify-content: center;
			.lds-ring {
				div {
					animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
					&:nth-child(1) {
						animation-delay: -0.45s; }
					&:nth-child(2) {
						animation-delay: -0.3s; }
					&:nth-child(3) {
						animation-delay: -0.15s; } } } }
		.lds-ring {
			display: inline-block;
			position: relative;
			width: 30px;
			height: 42px;
			margin-right: 10px;
			div {
				box-sizing: border-box;
				display: block;
				position: absolute;
				width: 25px;
				top: 0;
				left: 0;
				height: 25px;
				margin: 8px;
				border: 2px solid #96a2b7;
				border-radius: 50%;
				border-color: #96a2b7 transparent transparent transparent; } } }
	.none-messages {
		p {
			font-size: 14px;
			color: #96a2b7;
			text-align: center; } }
	a {
		color: $colorAccent; }
	.second-btn {
		min-width: auto;
		padding: 5px 11px 3px;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 0.05em;
		color: #96a2b7;
		text-transform: uppercase;
		border-radius: 6px;
		border: 1px solid #96a2b7;
		margin-left: 0;
		margin-top: 5px;
		display: inline-flex;
		align-items: flex-end;
		&:hover {
			color: $colorMain;
			text-decoration: none; } }
	&::after {
		content: '';
		position: absolute;
		top: -400px;
		left: 0;
		width: 100%;
		height: 400px;
		background: $colorMain; }
	.wrapper {
		position: relative;
		z-index: 2; }
	&-row {
		display: flex;
		flex-flow: row nowrap; }
	&-sidebar {
		width: 32.03%; }
	&-form {
		position: relative;
		margin-bottom: 32px;
		input {
			display: block;
			width: 100%;
			height: 48px;
			background: #48566E;
			border: none;
			border-radius: $borderRadius;
			padding: 0 45px 0 24px;
			font-size: 14px;
			color: #fff; } }
	&-search {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translate(0,-50%);
		padding: 3px;
		cursor: pointer;
		svg {
			display: block; } }
	&-tabs {
		display: flex;
		flex-flow: row nowrap;
		padding-bottom: 16px;
		li {
			padding-right: 40px;
			a {
				display: block;
				padding: 8px 0;
				border-bottom: 2px solid transparent;
				font-size: 14px;
				line-height: 26px;
				color: #fff;
				&:hover {
					color: $colorGray; }
				&.active {
					border-bottom-color: $colorAccent; } }
			&:last-child {
				padding-right: 0; } } }
	&-users {
		height: 485px;
		a {
			&:hover {
				text-decoration: none; } }
		.nano-pane {
			right: 0; }
		.nano-pane>.nano-slider {
			background: #5A6981; }
		&__wrap {
			padding-right: 28px; } }
	&-user {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: 24px 40px 22px 0;
		border-bottom: 1px solid #48566E;
		cursor: pointer;
		position: relative;
		transition: $transition;
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(90deg, #3F4D64 0%, #48566E 50.55%, #3F4D64 100%);
			opacity: 0;
			transition: $transition;
			z-index: -1; }
		&-active {
			svg {
				fill: $colorAccent;
				path {
					fill: $colorAccent; } }
			&::after {
				opacity: 1;
				background: linear-gradient(90deg, #3F4D64 0%, rgba(72, 86, 110, 0.54) 50.55%, #3F4D64 100%); } }
		&__num {
			position: absolute;
			top: 27px;
			right: 0;
			min-width: 30px;
			background: $colorAccent;
			text-align: center;
			border-radius: 10px;
			font-size: 12px;
			line-height: 20px;
			color: #fff; }
		&__img {
			min-width: 48px;
			width: 48px;
			img {
				display: block;
				border-radius: 50%; } }
		&__info {
			padding-left: 24px; }
		&__name {
			display: block;
			font-size: 14px;
			line-height: 25px;
			color: #fff;
			max-width: 250px; }
		&__status {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			padding-top: 3px;
			.status-ico {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				margin-right: 8px;
				background: #F2C94C;
				&.ico-green {
					background: #65C58C; }
				&.ico-red {
					background: #FF655B; } }
			span {
				font-size: 12px;
				line-height: 20px;
				color: $colorGray; } }
		&.active,&:hover {
			&::after {
				opacity: 1; } } }
	&-content {
		width: 67.97%;
		padding-left: 24px; }
	&-top {
		padding: 24px 160px 20px 40px;
		background: #48566E;
		border-radius: $borderRadius $borderRadius 0 0;
		position: relative;
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center; }
		&__img {
			min-width: 48px;
			width: 48px;
			position: relative; }
		&__status {
			position: absolute;
			bottom: -4px;
			right: -4px;
			width: 20px;
			height: 20px;
			border: 4px solid #48566e;
			border-radius: 50%;
			background: #F2C94C;
			&.ico-green {
				background: #65C58C; }
			&.ico-red {
				background: #FF655B; } }
		&__info {
			padding-left: 24px; }
		&__name {
			display: block;
			font-size: 20px;
			line-height: 1.25;
			font-weight: normal;
			color: #fff;
			margin-bottom: 7px;
			&__user {
				margin-bottom: 0; } }
		&__party {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			span {
				display: inline-block;
				font-family: $HelveticaNeueCyr;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray;
				padding-left: 8px; } }
		&__btns {
			position: absolute;
			top: 50%;
			right: 24px;
			transform: translate(0,-50%);
			display: flex;
			flex-flow: row nowrap; }
		&__num {
			position: absolute;
			top: 50%;
			right: 40px;
			transform: translate(0,-50%);
			font-size: 20px;
			line-height: 1.25;
			color: $colorGray; } }
	.table-btns {
		right: 0; }
	&-btn, .table-btn {
		width: 48px;
		height: 48px;
		border-radius: $borderRadius;
		margin-right: 16px;
		right: 0;
		position: relative;
		margin-left: 0;
		&__dots {
			right: 0;
			margin-right: 0; }
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%); }
		&__main {
			background: $colorAccent;
			border-color: transparent;
			&:hover {
				opacity: 0.7; } }
		&__second {
			border: 1px solid #596780;
			&:hover {
				border-color: $colorAccent;
				background: $colorAccent; } } }
	.table-btn {
		&:hover {
			border-color: $colorAccent;
			background: $colorAccent;
			opacity: .9; } }
	&-dialog {
		height: 524px;
		padding: 42px 20px 100px 40px;
		background: #3B485D;
		border-radius: 0 0 $borderRadius $borderRadius;
		position: relative;
		&.dialog_has_buttons {
			padding-top: 70px; }
		&.empty {
			text-align: center;
			padding-bottom: 42px;
			min-height: 100%;
			@media only screen and (max-width : 600px) {
				padding-bottom: 24px; }
			p {
				margin-top: 14px;
				font-size: 14px;
				line-height: 20px;
				color: #96a2b7; }
			img {
				max-width: 300px;
				opacity: .5; }
			.chat-dialog__content {
				height: 100%;
				display: flex;
				align-items: center;
				.chat-dialog__wrap {
					flex-basis: 100%; } } }
		.nano-pane {
			right: 4px; }
		.nano-pane>.nano-slider {
			background: #5A6981; }
		&__content {
			height: 100%; }
		&__wrap {
			padding-right: 20px; }
		&__btns {
			display: flex;
			flex-flow: row nowrap;
			padding: 20px;
			position: absolute;
			top: 0;
			width: 100%;
			left: 0;
			right: 0;
			background: #3b485c;
			z-index: 2; }
		&__btn {
			font-size: 10px;
			line-height: 16px;
			font-weight: 500;
			color: #fff;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			padding: 3px 11px;
			border: 1px solid #596780;
			border-radius: 16px;
			margin-right: 8px;
			&:hover {
				background: #48566E;
				border-color: #48566E;
				color: #fff;
				span {
					color: $colorGray; } }
			span {
				color: $colorGray;
				margin-left: 3px; }
			&.active {
				color: $colorAccent;
				border-color: $colorAccent;
				span {
					color: $colorAccent; }
				&:hover {
					background: $colorAccent;
					border-color: $colorAccent;
					color: #fff;
					span {
						color: #fff; } } } }
		&__bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0 40px 40px;
			background: #3b485d;
			&-wrap {
				position: relative; }
			input,.emojionearea-editor {
				display: block;
				width: 100%;
				height: 48px;
				background: #48566E;
				border: none;
				border-radius: $borderRadius;
				padding: 0 0 0 24px;
				font-size: 14px;
				color: #fff; }
			.emojionearea-editor {
				left: 0;
				padding-top: 15px;
				white-space: nowrap; }
			.emojionearea {
				background: #48566E;
				border-radius: $borderRadius!important;
				padding: 0 45px 0 0!important; } } }
	&-message {
		transition: $transition;
		padding-bottom: 32px;
		&__wrap {
			width: 77%; }
		&__top {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			img, .chat-userpic {
				display: block;
				min-width: 24px;
				width: 24px;
				max-height: 24px;
				border-radius: 50%;
				margin-right: 12px; } }
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center; }
		&__name {
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			color: #fff; }
		&__date {
			font-size: 12px;
			line-height: 20px;
			color: $colorGray; }
		&__info {
			background: $colorMain;
			padding: 16px 24px;
			border-radius: 0 10px 10px 10px;
			font-family: $HelveticaNeueCyr;
			font-size: 14px;
			line-height: 24px;
			color: #fff;
			b {
				font-size: 1.2rem;
				display: inline-block;
				vertical-align: middle; } }
		&.message-yellow {
			.chat-message__info {
				background: #F2C94C;
				color: $colorMain; } }
		&.message-yellow-alt {
			.chat-message__info {
				border: 2px solid #F2C94C;
				background: #48566d; } }
		&.chat-message__your {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			.chat-message__info {
				background: #65C58C; } } }
	&-send, &-emoji, .emojionearea-button, .emojionearea .emojionearea-button > div {
		position: absolute;
		top: 50%;
		right: 7px;
		transform: translate(0,-50%);
		padding: 10px;
		cursor: pointer;
		transition: $transition;
		svg {
			display: block;
			path {
				transition: $transition; } }
		&:hover {
			svg {
				path {
					fill: $colorAccent; } } } }
	&-private {
		display: none; }
	&-typing {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: 4px;
		&__bar {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			width: 30px;
			height: 20px;
			.sk-child {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: $colorGray;
				animation: sk-three-bounce 1.5s ease-in-out infinite both; }
			.sk-bounce-1 {
				animation-delay: 0.3s; }
			.sk-bounce-2 {
				animation-delay: 0.5s; }
			.sk-bounce-3 {
				animation-delay: 0.7s; } }
		p,span {
			display: inline-block;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray;
			padding-right: 12px; } }
	&-row {
		&.block-correspondence {
			.chat-content {
				display: block; }
			.chat-correspondence {
				display: block; }
			.chat-private {
				display: none; } }
		&.block-private {
			.chat-content {
				display: block; }
			.chat-private {
				display: block; }
			.chat-correspondence {
				display: none; } } }
	&-back {
		position: absolute;
		top: -24px;
		left: 40px;
		display: none;
		flex-flow: row nowrap;
		align-items: center;
		font-size: 9px;
		line-height: 20px;
		font-weight: 500;
		color: $colorGray;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		cursor: pointer;
		svg {
			display: block;
			margin-right: 8px; } }
	&-empty {
		color: #ffffff;
		.info {
			color: #96a2b7;
			margin: 10px 0; } }
	.rates-wrapper {
		margin-bottom: 20px; }
	.participation-col {
		width: 50%;
		@media only screen and (max-width : 700px) {
			width: 100%; }
		&__wrap {
			background: rgba(255, 255, 255, 0.02); }
		&__name {
			color: #ffffff;
			font-size: 14px; }
		&__price {
			color: #ffffff;
			font-size: 20px;
			padding-bottom: 10px; } } }
.chat-message__info {
	p {
		margin-top: 20px;
		&:first-child {
			margin-top: 0; } } }

@keyframes sk-three-bounce {
	0% {
		opacity: 0;
		transform: scale(0); }
	40% {
		opacity: 1;
		transform: scale(1); }
	80% {
		opacity: 0;
		transform: scale(0); }
	100% {
		opacity: 0;
		transform: scale(0); } }

.chat-correspondence {
	.chat-dialog__content {
		height: 100%; } }

.chat-shadow {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background: linear-gradient(transparent, #3f4d64);
	transition: $transition; }
.chat-disabled {
	cursor: auto;
	opacity: 0.5; }
.chat-private {
	.chat-message__wrap {
		width: 63%; } }
.chat-info {
	.chat-message__wrap {
		width: 77%; } }


.chat-users:hover {
	.chat-shadow {
		opacity: 0;
		visibility: hidden; } }

.chat-dialog {
	.chat-shadow {
		bottom: 100px;
		background: linear-gradient(transparent, #3b485d); }
	&:hover .chat-shadow {
		opacity: 0;
		visibility: hidden; } }

@media only screen and (max-width : 1020px) {
	.chat {
		min-height: calc(100vh - 136px);
		&-sidebar {
			width: 35%; }
		&-content {
			width: 65%; }
		&-dialog {
			&__wrap {
				width: 100%; } } } }

@media only screen and (max-width : 850px) {
	.chat {
		padding: 0;
		&-back {
			display: flex; }
		&-top {
			background: $colorMain; }
		&-sidebar {
			width: 100%; }
		&-users {
			&__wrap {
				padding-right: 10px; } }
		&-user {
			&__name {
				max-width: 100%; } }
		&-content {
			display: none;
			width: 100%;
			padding: 0; }
		&-correspondence,&-private {
			margin: 0 -36px; }
		&-dialog {
			border-radius: 0;
			.nano>.nano-pane {
				display: block!important; } }
		&-row {
			&.block-correspondence {
				.chat-content {
					display: block; }
				.chat-sidebar {
					display: none; }
				.chat-correspondence {
					display: block; }
				.chat-private {
					display: none; } }
			&.block-private {
				.chat-content {
					display: block; }
				.chat-sidebar {
					display: none; }
				.chat-private {
					display: block; }
				.chat-correspondence {
					display: none; } } } }
	.table-btn {
		&:hover {
			background: transparent!important;
			border-color: transparent!important; } }
	.table-btns {
		right: 0;
		&__drop {
			width: 230px;
			a {
				width: 100%!important;
				border-radius: 0!important; }
			.chat-btn__main {
				span {
					color: #ffffff; } }
			.chat-btn__second {
				svg {
					path {
						fill: $colorMain; } } } }
		&.active {
			.table-btn {
				&__dots {
					background: transparent!important;
					svg {
						path {
							fill: $colorAccent!important; } } } } } }
	.chat-shadow {
		opacity: 0;
		visibility: hidden; } }
.chat-userpic {
	display: block;
	width: 48px;
	height: 48px;
	background-size: cover;
	background-position: center;
	border-radius: 50%;
	overflow: hidden; }

@media only screen and (max-width : 700px) {
	.chat {
		min-height: calc(100vh - 97px);
		&-correspondence,&-private {
			margin: 0 -20px; }
		&-form {
			margin-bottom: 16px;
			input {
				height: 40px;
				padding: 0 43px 0 20px;
				font-size: 12px; } }
		&-search {
			right: 17px; }
		&-tabs {
			padding-bottom: 8px;
			li {
				a {
					padding: 16px 0;
					font-size: 14px;
					line-height: 20px; } } }
		&-user {
			padding: 24px 34px 22px 0;
			&__name {
				font-size: 12px;
				line-height: 20px; } } } }
@media only screen and (max-width : 1024px) {
	.chat-section {
		padding-top: 96px; } }

@media only screen and (max-width : 600px) {
	.chat-section {
		padding-top: 70px!important; }
	.chat {
		&-back {
			left: 20px; }
		&-top {
			padding: 20px 20px 40px;
			&__name {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 8px; }
			&__num {
				position: static;
				transform: translate(0,0);
				font-size: 16px;
				line-height: 24px; }
			&__btns {
				position: absolute;
				right: 0;
				transform: translate(0,0);
				padding: 0;
				margin: 0;
				z-index: 9; } }
		&-btn {
			width: 50%;
			height: 40px;
			margin: 0 4px; }
		&-sidebar {
			margin-top: 26px; }
		&-dialog {
			height: 490px;
			padding: 24px 20px 96px;
			&__wrap {
				padding-right: 10px; }
			&__bottom {
				padding: 0 20px 40px;
				input {
					height: 40px;
					padding: 0 45px 0 20px; } } }
		&-send {
			right: 17px;
			svg {
				path {
					transition: $transition; } }
			&:hover {
				svg {
					path {
						fill: #EBEEF5; } } } }
		&-message {
			padding-bottom: 25px;
			&__wrap {
				width: 90%;
				position: relative; }
			&__date {
				position: absolute;
				left: 12px;
				bottom: 8px;
				font-size: 11px;
				line-height: 16px; }
			&__info {
				padding: 8px 12px 28px;
				font-size: 12px;
				line-height: 20px; }
			&.message-yellow {
				.chat-message__date {
					color: #A38937; } }
			&.chat-message__your {
				.chat-message__date {
					color: #fff; } } }
		&-private {
			.chat-message__wrap {
				width: 90%;
				&__notify {
					width: 100%; } } } } }
.footer-chat {
	display: none!important; }

@media only screen and (max-width : 768px) {
	.chat-section {
		padding-top: 90px; }
	.chat-users {
		height: auto; }
	.chat {
		overflow: hidden;
		min-height: calc(100vh - 96px);
		&-back {
			left: 20px;
			&-mobile {
				position: relative;
				left: 0;
				top: 0;
				width: 40px; } }
		&-top {
			background: #364155;
			padding: 15px 20px 15px;
			display: flex;
			&__info {
				width: 100%;
				padding-left: 0;
				padding-right: 20px;
				text-align: center; }
			&__name {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 0;
				span {
					display: none; } }
			&__num {
				position: static;
				transform: translate(0,0);
				font-size: 12px;
				line-height: 18px; }
			&__row {
				width: 100%;
				&.with-btns {
					width: calc(100% - 70px); }
				flex-direction: row-reverse; }
			&__img {
				width: 40px;
				height: 40px;
				min-width: 40px;
				.chat-userpic {
					width: 40px;
					height: 40px; } }
			&__status {
				bottom: 0;
				right: 0;
				width: 10px;
				height: 10px;
				border: 2px solid; }
			&__party {
				justify-content: center; }
			&__btns {
				position: absolute;
				right: 0;
				padding: 0;
				margin: 0;
				z-index: 9; } }
		&-dialog {
			padding: 24px 20px 60px;
			&__wrap {
				padding-right: 10px; }
			&__content {
				height: calc(100% - 20px)!important; }
			&__bottom {
				padding: 0 20px 40px; }
			&__disabled {
				input {
					font-size: 11px!important; }
				.chat-send {
					right: 0; } } }
		&-private {
			.chat-message__wrap {}
 }			//width: 90%
		&-dialog {
			&__bottom {
				padding: 0 20px 20px; } } }
	.user-breadcrumb {
		display: none!important; } }

@media only screen and (max-width : 850px) {
	.chat-section {
		padding-top: 96px; }
	.chat {
		overflow: hidden;
		&-back {
			left: 20px;
			&-mobile {
				position: relative;
				left: 0;
				top: 0;
				width: 40px; } }
		&-top {
			background: #364155;
			padding: 15px 20px 15px;
			display: flex;
			&__info {
				width: 100%;
				padding-left: 0;
				padding-right: 20px;
				text-align: center; }
			&__name {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 0;
				span {
					display: none; } }
			&__num {
				position: static;
				transform: translate(0,0);
				font-size: 12px;
				line-height: 18px; }
			&__row {
				width: 100%;
				&.with-btns {
					width: calc(100% - 70px); }
				flex-direction: row-reverse; }
			&__img {
				width: 40px;
				height: 40px;
				min-width: 40px;
				.chat-userpic {
					width: 40px;
					height: 40px; } }
			&__status {
				bottom: 0;
				right: 0;
				width: 10px;
				height: 10px;
				border: 2px solid; }
			&__party {
				justify-content: center; }
			&__btns {
				position: absolute;
				right: 0;
				padding: 0;
				margin: 0;
				z-index: 9; } } } }
@media only screen and (min-width : 600px) and (max-width : 700px) {
	.chat-section {
		padding-top: 72px; } }
@media only screen and (max-width : 1024px) {
	.chat {
		.tab-list {
			span {
				display: none; } }
		&-top {
			&__name {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 0; }
			&__num {
				font-size: 12px;
				line-height: 18px; }
			&__img {
				width: 40px;
				height: 40px;
				min-width: 40px;
				.chat-userpic {
					width: 40px;
					height: 40px; } } } } }

@keyframes lds-ring {
	0% {
		transform: rotate(0deg); }

	100% {
		transform: rotate(360deg); } }
.emojionearea .emojionearea-picker .emojionearea-filters {
	background: #48556d!important; }
.emojionearea-filter {
	&.active {
		background: transparent!important;
		border-bottom: 2px solid #5db5fe!important; } }
.dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] {
	background-color: #3f4c63!important;
	.textcomplete-item {
		cursor: pointer;
		&.active {
			background-color: #5eb5fe!important;
			a {
				color: #ffffff!important; } }
		a {
			color: #96a2b7!important; } } }
.emojionearea-filter {
	filter: none!important;
	-webkit-filter: none!important;
	opacity: 0.5;
	&.active {
		opacity: 1; } }
