@import "vars";

.registration {
	padding: 0 0 80px;
	&-top {
		height: 96px;
		background: #fff;
		border-radius: $borderRadius;
		margin-bottom: 24px; }
	&-row {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: 56px;
		margin: 0 -12px;
		&.marathons-list {
			padding-bottom: 0; } } }

@media only screen and (max-width : 768px) {
	.registration {
		padding-bottom: 64px;
		&-row {
			padding-bottom: 16px; } } }

@media only screen and (max-width : 600px) {
	.registration {
		padding-bottom: 40px;
		&-row {
			margin: 0;
			padding-bottom: 24px; } } }
