@import "vars";

.slick-dotted.slick-slider {
	margin: 0; }

.slick-arrow {
	top: 50%;
	width: 16px;
	height: 30px;
	margin-top: -6px;
	svg path {
		transition: $transition; }
	&:hover {
		svg path {
			fill: #475771; } } }

.slick-prev {
	left: -36px; }

.slick-next {
	right: -36px; }

.slick-dots {
	bottom: 0;
	li {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 1px solid transparent;
		margin: 0 11px;
		transition: $transition;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: $colorGray;
			transition: $transition; }
		&:hover {
			background: #E6E9F1;
			&::after {
				background: #3F4D64; } }
		&.slick-active {
			border-color: #CCD3DC;
			&::after {
				background: $colorMain; }
			&:hover {
				background: transparent; } } } }

@media only screen and (max-width : 1360px) {
	.slick-arrow {
		margin: 0; }
	.slick-prev {
		left: auto;
		right: 48px; }
	.slick-next {
		right: 12px; }
	.slider-all.slick-slider {
		.slick-arrow {
			top: -60px; } } }

@media only screen and (max-width : 768px) {
	.slider-all.slick-slider {
		.slick-arrow {
			top: -56px; } } }

@media only screen and (max-width : 600px) {
	.slick-arrow {
		svg {
			width: 12px;
			height: 22px; } }
	.slick-dots li {
		margin: 0 7px; }
	.slider-all.slick-slider {
		.slick-arrow {
			top: -36px; } } }
