@import "vars";

.cabinet {
	&-select {
		display: none;
		.select {
			&-text {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				padding: 16px 10px 16px 0;
				font-size: 14px;
				line-height: 20px;
				background: transparent;
				border-bottom: 2px solid $colorAccent;
				border-radius: 0;
				b {
					font-weight: normal;
					color: $colorGray;
					margin-left: 10px; }
				&::after {
					right: 2px; }
				svg {
					margin-right: 12px;
					path {
						fill: $colorAccent; } } }
			&-list {
				li {
					a {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						font-size: 14px;
						line-height: 20px;
						color: $colorMain;
						b {
							font-weight: normal;
							color: $colorGray;
							margin-left: 10px; }
						span {
							display: inline-block;
							width: 24px;
							height: 20px;
							margin-right: 12px; } } } } } }
	&-top {
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 40px;
			.page-title {
				padding: 0 20px 0 0; } }
		.timeline {
			padding-top: 0; } }
	&-tabs {
		display: flex;
		flex-flow: row nowrap;
		li {
			padding-right: 40px;
			&:last-child {
				padding-right: 0; }
			a {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				padding: 24px 0;
				font-size: 16px;
				line-height: 30px;
				color: $colorMain;
				border-bottom: 2px solid transparent;
				svg {
					margin-right: 12px;
					path {
						transition: $transition; } }
				span {
					color: $colorGray;
					margin-left: 10px; }
				.link {
					border-bottom: 2px solid #3f4d64;
					&:hover {
						border-color: $colorAccent; } }
				&.active {
					//border-bottom-color: $colorAccent
					.link {
						color: $colorAccent;
						border-color: $colorAccent; }
					svg {
						path {
							fill: $colorAccent; } } } } } }

	.testimonials,
	.free_answer {
		background: transparent;
		padding: 20px 0 80px;
		border-top: 1px solid #E3E6EF;
		.reviews {
			border: none;
			padding: 60px 180px;
			&-img {
				top: 60px;
				max-width: 140px;
				img {
					display: block;
					margin-bottom: 32px; }
				p {
					font-size: 14px;
					line-height: 20px;
					padding-bottom: 8px; }
				span {
					font-family: $HelveticaNeueCyr;
					font-size: 12px;
					line-height: 20px;
					color: $colorGray; } }
			&-title {
				font-size: 20px;
				line-height: 1.5;
				font-weight: normal;
				padding-bottom: 16px; }
			&-top {
				padding-bottom: 40px; }
			&-name {
				font-size: 20px;
				line-height: 1.5;
				font-weight: 500;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				padding: 0; }
			&-info {
				p {
					padding: 0; } } } }
	.free_answer {
		padding: 76px 0 80px;
		background: #ebeff7; }
	.involvement {
		&-form {
			&__row {
				padding-bottom: 40px; }
			.input-block {
				margin: 0; } }
		&-text {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 30px;
			padding-bottom: 40px; } }
	&-mailing {
		&__wrap {
			padding: 80px 0;
			border-top: 1px solid #E3E6EF; } }
	&-description {
		max-width: 600px;
		margin: 0 auto;
		&__name {
			display: inline-block;
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray;
			margin-bottom: 16px; }
		.section-title {
			padding-bottom: 40px; }
		&__row {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			padding-bottom: 40px;
			div {
				padding-right: 20px; }
			span {
				display: inline-block;
				margin-bottom: 8px;
				font-family: $HelveticaNeueCyr;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray; }
			p {
				font-size: 14px;
				line-height: 20px; } }
		&__info {
			p {
				font-family: $HelveticaNeueCyr;
				font-size: 16px;
				line-height: 30px; } }
		&__subtitle {
			font-size: 20px;
			line-height: 1.5;
			font-weight: normal;
			padding-bottom: 16px; } }
	&-edit {
		display: none; }
	.forum-second {
		border: none;
		padding: 0; }
	.forum-contact .input-block-m {
		margin: 0; }
	.edit-btn {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			right: 22px;
			transform: translate(0,-50%);
			border-top: 6px solid $colorMain;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			transition: $transition; } }
	&-row {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: flex-start;

		&.top-row {
			margin-bottom: 30px; } }
	&-btns {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		.main-btn,.second-btn {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			svg {
				margin-right: 12px; } }
		.main-btn {
			background: #65C58C;
			margin-left: 8px;
			padding: 12px 24px; }
		.second-btn {
			padding: 11px 23px; } }
	&-statistics {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: 80px;
		&__col {
			width: 33.333%;
			padding: 0 10px;
			text-align: center;
			.table {
				&-btn {
					width: 24px;
					height: 24px;
					position: static;
					transform: translate(0,0);
					margin-left: 12px; } }
			span {
				display: inline-block;
				padding-bottom: 12px;
				font-size: 36px;
				line-height: 1;
				font-weight: 300; }
			p {
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $HelveticaNeueCyr;
				font-size: 14px;
				line-height: 20px;
				color: $colorGray; } } }
	.step-wrap {
		padding: 0 0 80px;
		.step-frame .input-block.input-block-m {
			margin: 0; }
		.btn-block {
			margin: 0;
			.second-btn {
				margin: 0; } }
		.step-block__raffle {
			padding-bottom: 24px; } }
	.raffle {
		margin: 0 0 80px; } }

.active-block {
	.cabinet {
		&-description {
			display: none; }
		&-edit {
			display: block; } } }

.cabinet .step-wrap .btn-block .second-btn {
	margin-top: 24px; }

.table-btn {
	&__text {
		display: none; }
	b {
		display: block;
		height: auto;
		width: auto; } }

.participants-row .table-btn {
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%); } }

.participants-wrap {
	&.participants-wrap__2 .participants-col__2 .participants-row {
		display: flex;
		justify-content: flex-end; } }

.table {
	&-btns {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: absolute;
		top: 50%;
		right: 24px;
		transform: translate(0,-50%);
		z-index: 0;
		&__drop {
			display: flex;
			flex-flow: row nowrap;
			align-items: center; }
		.table-btn {
			position: static;
			transform: translate(0,0);
			margin-left: 8px;
			&__dots {
				display: none;
				border: none; }
			&__close {
				border: none; } } }
	&-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		right: 24px;
		transform: translate(0,-50%);
		width: 32px;
		height: 32px;
		border-radius: 50%;
		border: 1px solid #CCD3DC;
		transition: $transition; } }

.statistics {
	&-block {
		padding: 80px 0 32px;
		border-bottom: 1px solid #E3E6EF;
		&:first-child {
			padding-top: 0; }
		&:last-child {
			border-bottom: none; } }
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 80px;
		.section-title {
			padding: 0; } }
	&-row {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -10px; }
	&-col {
		width: 16.666%;
		padding: 0 10px 48px;
		span {
			display: inline-block;
			padding-bottom: 12px;
			font-size: 36px;
			line-height: 1;
			font-weight: 300; }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 14px;
			line-height: 20px;
			color: $colorGray; } } }

.participants {
	border-top: 1px solid #E3E6EF;
	&-accordeon {
		position: relative;
		&__arrow {
			position: absolute;
			top: 30px;
			right: 20px;
			transition: $transition;
			cursor: pointer;
			svg {
				display: block; }
			&.active {
				transform: rotate(180deg); } } }
	&-btn {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		padding: 80px 0;
		.second-btn {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			color: $colorMain;
			svg {
				margin-right: 16px; } } }


	&-wrap {
		display: flex;
		flex-flow: row nowrap;
		padding: 0 80px 0 144px;
		position: relative; }
	&-col {
		position: absolute;
		top: 0;
		&__1 {
			left: 0;
			min-width: 144px;
			width: 144px; }
		&__2 {
			right: 0;
			min-width: 80px;
			width: 80px; } }
	&-slider {
		width: 100%;
		.participants {
			&-row {
				padding: 40px 15px 40px 0;
				&__top {
					padding: 24px 10px 24px 0; } } } }
	&-slide {
		&__1 {
			width: 41%!important; }
		&__2 {
			width: 31%!important; }
		&__3 {
			width: 28%!important; } }
	&-row {
		height: 130px;
		padding: 40px 24px;
		a {
			color: $colorMain;
			&:hover {
				color: $colorAccent; } }
		p {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			font-size: 14px;
			line-height: 24px;
			.part-ico {
				margin-left: 10px;
				svg {
					display: block; } }
			span {
				display: block;
				width: 100%;
				font-family: $HelveticaNeueCyr;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray; }
			.second-btn {
				min-width: auto;
				padding: 3px 11px;
				font-size: 10px;
				line-height: 16px;
				letter-spacing: 0.05em;
				color: $colorMain;
				text-transform: uppercase;
				border-radius: 16px;
				border: 1px solid #CCD3DC;
				margin-left: 10px; } }
		.table-btn {
			position: static;
			transform: translate(0,0); }
		&__top {
			min-height: 70px;
			height: 70px;
			border-top: 1px solid #E3E6EF;
			padding: 24px;
			span {
				display: inline-block;
				font-size: 10px;
				line-height: 1.2;
				font-weight: 500;
				color: $colorGray;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				padding: 4px 0; } }
		&:nth-child(even) {
			background: #F8FAFF; } }
	&-wrap {
		&.participants-wrap__2 {
			padding-left: 104px;
			border-bottom: 1px solid #E3E6EF;
			.participants-row {
				height: 113px;
				display: flex;
				align-items: center;
				&__top {
					height: 70px; }
				&-draft {
					color: #96a2b7;
					img {
						opacity: .6; }
					a {
						color: #96a2b7;
						&:hover {
							color: $colorAccent; } } } }
			.participants-col__1 {
				min-width: 104px;
				width: 104px; }
			.participants-slide {
				&__2 {
					width: 19%!important; }
				&__3 {
					width: 40%!important; } } } }
	&-block {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		width: 100%;
		&__col {
			&-1 {
				min-width: 104px; } }
		&__2 {
			.participants-block__col {
				width: 50%; } }
		img {
			display: block;
			max-width: 40px;
			border-radius: 50%; } }
	&-wrap {
		&.participants-wrap__3 {
			.participants {
				&-row {
					&__top {
						display: block;
						height: 140px;
						padding: 24px 24px 24px 0;
						.participants-block__col {
							span {
								white-space: nowrap; } }
						.input-block,.select {
							margin-top: 12px; }
						.participants-block {
							align-items: end;
							.marathon-page-filter-submit {
								position: relative;
								top: 23px;
								left: 5px;
								.search-reset {
									display: none; }
								&.active {
									.search-icon {
										display: none; }
									.search-reset {
										display: block; } } } } } }
				&-block {
					&__col {
						.participants-block__col-2 {
							width: 100%; } } }
				&-slide {
					&__3 {
						.participants-row__top {
							padding-right: 0; }
						.participants-block__col-1 {
							padding-right: 24px; } } } } }
		&.participants-wrap__4 {
			padding-right: 144px;
			.slick-track {
				@media only screen and (min-width : 768px) {
					width: 100%!important; } }
			.participants {
				&-slide {
					&__1 {
						width: 32%!important;
						&__2 {
							width: 60%!important;
							@media only screen and (max-width : 900px) {
								width: 66.666%!important; } } }
					&__2 {
						width: 28%!important; } }
				&-col__2 {
					width: 144px;
					.participants {
						&-row {
							padding-left: 0; } } } } } }
	&-arrow {
		position: absolute;
		top: 26px;
		right: 20px;
		transition: $transition;
		cursor: pointer;
		z-index: 10;
		&.active {
			transform: rotate(180deg); } }
	&-accordeon {
		.participants-row__top {
			transition: $transition; }
		&.participants-active {
			.participants-row__top {
				height: 70px; } } }
	&-row {
		.table-btns {
			position: static;
			transform: translate(0,0); } } }

@media only screen and (max-width : 1200px) {
	.cabinet {
		&-row {
			display: block; }
		&-btns {
			justify-content: center;
			padding-top: 40px;
			margin: 0 -12px;
			.main-btn,.second-btn {
				width: 50%;
				justify-content: center;
				margin: 0 12px; } } } }

@media only screen and (max-width : 1020px) {
	.cabinet {
		&-tabs {
			li {
				padding-right: 32px; } }
		.testimonials,
		.free_answer {
			.reviews {
				padding: 60px 0 60px 180px; } } }
	.statistics {
		&-col {
			width: 20%; } }
	.participants {
		&-wrap {
			padding-left: 80px;
			&.participants-wrap__3 {
				.participants-row__top {
					height: 70px;
					.input-block, .select {
						display: none; }
					.participants-block__col span {
						white-space: normal; } } }
			&.participants-wrap__4 {
				padding-right: 80px;
				.participants-col__2 {
					width: 80px;
					.participants-row {
						justify-content: flex-end; } } } }
		&-col {
			&__1 {
				min-width: 80px;
				width: 80px; } }
		&-arrow {
			display: none; } }
	.table {
		&-btns {
			display: block;
			&__drop {
				position: absolute;
				top: 120%;
				right: 0;
				display: block;
				width: 160px;
				background: #fff;
				box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
				border-radius: $borderRadius;
				transition: $transition;
				padding: 10px;
				z-index: 10;
				opacity: 0;
				visibility: hidden;
				padding: 0;
				overflow: hidden;
				.table-btn {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: flex-start;
					width: 100%;
					height: auto;
					border-radius: 0;
					border: none;
					font-size: 12px;
					line-height: 20px;
					color: #3F4D64;
					padding: 10px 20px;
					margin: 0;
					&:hover {
						background: #F7FAFF; }
					&__text {
						display: block; }
					b {
						display: block;
						width: 16px;
						height: 16px;
						position: relative;
						margin-right: 14px;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%); } }
					&__close {
						color: $colorGray; } } }
			.table {
				&-btn {
					&__dots {
						display: block;
						cursor: pointer; } } }
			&.active {
				z-index: 21;
				.table-btns__drop {
					opacity: 1;
					visibility: visible; } } } } }

@media only screen and (max-width : 900px) {
	.participants {
		&-wrap {
			&.participants-wrap__2 {
			 	.participants-slide {
			 		&__1 {
			 			width: 45%!important; }
			 		&__2 {
			 			width: 21.666%!important; }
			 		&__3 {
			 			width: 33.333%!important; } } }
			&.participants-wrap__2 {
				padding-bottom: 48px;
				border: none;
				.slick-dots {
					bottom: -48px; } } } } }

@media only screen and (max-width : 768px) {
	.cabinet {
		&-top {
			&__row {
				padding-bottom: 40px; } }
		&-row {
			padding: 11px 0 64px; }
		&-statistics {
			padding-bottom: 64px; }
		&-tabs {
			li {
				padding-right: 28px;
				a {
					padding: 16px 0; } } }
		.testimonials,
		.free_answer {
			padding: 16px 0 64px;
			.reviews {
				padding: 48px 0;
				&-img {
					position: static;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					max-width: 100%;
					padding-bottom: 40px;
					img {
						max-width: 64px;
						min-width: 64px;
						height: 64px;
						margin: 0; }
					&__info {
						padding-left: 32px;
						p {
							display: none; } } }
				&-top {
					padding-bottom: 24px; }
				&-info {
					max-width: 100%; }
				&-title {
					padding-bottom: 8px; } }
			&-btn {
				max-width: 100%;
				padding: 0; } }
		&-mailing {
			&__wrap {
				padding: 64px 0; } }
		&-description,&-edit {
			max-width: 100%; }
		.step-wrap {
			padding-bottom: 64px; }
		.raffle {
			display: block;
			margin-bottom: 64px;
			&-info {
				padding: 0 0 24px; }
			&-btns {
				width: 100%;
				margin: 0 -4px;
				.main-btn,.second-btn {
					width: 50%;
					margin: 0 4px; } } } }
	.statistics {
		&-block {
			padding: 40px 0 8px; }
		&-top {
			padding-bottom: 38px; }
		&-col {
			width: 25%;
			padding: 0 10px 40px; } }
	.participants {
		&-wrap {
			padding-left: 60px; }
		&-col {
			&__1 {
				min-width: 60px;
				width: 60px;
				.participants-row {
					padding: 24px 10px 24px 24px;
					&__top {
						padding: 16px 10px 16px 24px; } } }
			&__2 {
				.participants-row {
					display: flex;
					justify-content: flex-end;
					padding: 24px 24px 24px 10px;
					&__top {
						padding: 16px 10px 16px 24px; } } } }
		&-row {
			height: 97px;
			padding: 24px;
			&__top {
				min-height: 50px;
				height: 50px;
				padding: 16px 10px 16px 24px; } }
		&-slider {
			.participants-row {
				padding: 24px 10px 24px 14px;
				&__top {
					padding: 16px 10px 16px 14px; } } }
		&-wrap {
			&.participants-wrap__2 {
				padding-left: 96px;
				.participants {
					&-row {
						height: 88px;
						&__top {
							height: 50px; } } } } }
		&-block {
			&__col {
				&-1 {
					min-width: 84px; } } }
		&-wrap {
			&.participants-wrap__3 {
				.participants-row__top {
					padding: 16px 10px 16px 0; } } } } }

@media only screen and (max-width : 700px) {
	.cabinet {
		&-select {
			display: block;
			padding: 0; }
		&-top {
			&__row {
				display: block;
				padding-bottom: 33px;
				.page-title {
					padding: 0 0 24px; } } }
		.second-btn, .main-btn {
			max-width: 100%;
			width: 100%; }
		.participation-block {
			margin-bottom: 32px; }
		&-tabs {
			display: none; }
		.testimonials,
		.free_answer {
			padding: 24px 0 48px;
			.reviews {
				padding: 0 0 32px;
				&-img {
					padding-bottom: 32px; }
				&-top {
					padding-bottom: 16px; }
				&-name {
					font-size: 16px;
					line-height: 24px; } } }
		.free_answer {
			padding: 48px 0; } }
	.participants {
		&-wrap {
			margin: 0 -20px;
			padding-bottom: 48px;
			.slick-dots {
				bottom: -48px; }
			&.participants-wrap__2 {
				.participants-slide {
					width: 33.333%!important; } }
			&.participants-wrap__4 {
				.participants-slide {
					width: 25% !important; } } }
		&-slide {
			width: 33.333%!important; }
		&-slider__4 {
			.participants {
				&-wrap {
					&.participants-wrap__2 {
						.participants-slide {
							width: 25% !important; } } }
				&-slide {
					width: 25%!important; } } } } }

@media only screen and (max-width : 600px) {
	.cabinet {
		&-row {
			padding: 0 0 32px; }
		&-btns {
			display: block;
			padding: 24px 0 0;
			margin: 0;
			.main-btn,.second-btn {
				width: 100%;
				margin: 0 0 8px; } }
		&-statistics {
			padding-bottom: 32px;
			margin: 0 -10px;
			&__col {
				position: relative;
				span {
					font-size: 24px;
					line-height: 40px;
					padding: 0; }
				p {
					font-size: 11px;
					line-height: 16px; }
				.table-btn {
					position: absolute;
					top: 10px;
					left: 55%; } } }
		.step-wrap {
			padding-bottom: 40px; }
		.raffle {
			margin-bottom: 48px;
			&-btns {
				display: block;
				margin: 0;
				.main-btn,.second-btn {
					width: 100%;
					margin: 0; }
				.main-btn {
					margin-bottom: 8px; } } } }
	.statistics {
		&-block {
			padding: 32px 0 8px; }
		&-col {
			padding: 0 10px 24px;
			span {
				font-size: 24px;
				line-height: 40px;
				padding: 0; }
			p {
				font-size: 11px;
				line-height: 16px; } } }
	.table-btn {
		right: 20px; }
	.participants {
		&-wrap {
			padding: 0 72px 0 60px;
			&.participants-wrap__2 {
				padding-left: 64px;
				.participants {
					&-row {
						display: block; }
					&-col {
						&__1 {
							min-width: 64px;
							width: 64px; } } } }
			&.participants-wrap__3 {
				.participants-block__col-1 {
					min-width: 80px;
					width: 80px; }
				.participants-block__2 {
					.participants-block__col-1 {
						min-width: 100px;
						width: 100px; } } } }
		&-block {
			align-items: end;
			&__col {
				&-1 {
					min-width: 56px;
					img {
						max-width: 32px;
						margin-top: -4px; } } } }
		&-row {
			min-height: 78px;
			height: 78px;
			&__top {
				min-height: 50px;
				height: 50px; } }
		&-col {
			&__1 {
				min-width: 60px;
				width: 60px;
				.participants-row {
					padding: 22px 0 0 20px;
					&__top {
						padding: 16px 0 0 20px; } } }
			&__2 {
				min-width: 72px;
				width: 72px;
				.participants-row {
					padding: 16px 20px 0 10px;
					&__top {
						padding: 16px 20px 0 10px; } } } }
		&-slide {
			.participants-row {
				padding: 22px 10px 16px 10px;
				&__top {
					min-height: 50px;
					height: 50px;
					padding: 16px 20px 0 10px; } } }
		&-wrap {
			.participants-col__2 {
				.participants-row {
					align-items: start; } } } } }

@media only screen and (max-width : 450px) {
	.cabinet {
		.testimonials,
		.free_answer {
			.reviews {
				&-img {
					img {
						min-width: 48px;
						max-width: 48px;
						height: 48px; }
					&__info {
						padding-left: 24px; } }
				&-title {
					font-size: 16px;
					line-height: 28px;
					padding: 0; }
				&-info {
					p {
						font-size: 14px;
						line-height: 24px; } } } }
		&-mailing {
			&__wrap {
				padding: 40px 0; } }
		&-description {
			&__name {
				margin-bottom: 8px; }
			.section-title {
				padding-bottom: 8px; }
			&__row {
				display: block;
				padding: 0 0 16px;
				div {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: space-between;
					padding: 16px 0;
					border-bottom: 1px solid #E3E6EF;
					span,p {
						font-size: 12px;
						line-height: 20px; }
					span {
						margin: 0; }
					&:last-child {
						border-bottom: none; } } }
			&__info {
				p {
					font-size: 14px;
					line-height: 24px; } }
			&__subtitle {
				font-size: 16px;
				line-height: 28px;
				padding-bottom: 12px; } }
		.pagination {
			padding: 40px 0; } }
	.statistics {
		padding-bottom: 8px;
		&-top {
			display: block;
			padding-bottom: 24px;
			.section-title {
				padding-bottom: 24px; }
			.main-btn {
				width: 100%; } }
		&-col {
			width: 50%; }
		&:first-child {
			.section-title {
				padding: 0; } } }
	.table {
		&-btn {
			right: 0; }
		&-btns {
			right: 0; } }
	.participants {
		&-slide {
			width: 33.333%!important; }
		&-block {
			&__col {
				&-2 {
					span {
						white-space: nowrap; } } } } } }
