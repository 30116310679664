@import "vars";

.participation {
	.page-title {
		text-align: center; }
	&-block {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background: #fff;
		border-radius: $borderRadius;
		padding: 40px 170px 40px 40px;
		margin-bottom: 40px;
		position: relative;
		&.slim {
			margin-bottom: 10px;
			padding: 20px 170px 20px 40px; }
		.status {
			position: absolute;
			top: 50%;
			right: 40px;
			transform: translate(0,-50%);
			white-space: nowrap;
			display: block;
			font-size: 14px;
			line-height: 25px;
			font-weight: normal;
			color: $colorGray;
			a {
				color: $colorGray; } } }
	&-block {
		margin-bottom: 20px; }
	&-info {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding-right: 20px;
		width: 100%; }
	&-img {
		max-width: 232px;
		width: 100%;
		display: block;
		height: 120px;
		margin-right: 40px;
		overflow: hidden;
		background-size: cover;
		background-position: center; }
	img {
		display: block; }
	&-name {
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		color: $colorMain;
		a {
			color: $colorMain; } }
	&-row {
		display: flex;
		flex-flow: row wrap;
		margin: 40px -12px 56px;
		.section-title {
			width: 100%;
			text-align: center; }
		&-center {
			justify-content: center;
			align-items: stretch; } }
	&-col {
		width: 33.333%;
		padding: 0 12px 24px;
		&__wrap {
			height: 100%;
			padding: 40px 25px 128px;
			text-align: center;
			background: #fff;
			border-radius: $borderRadius;
			transition: $transition;
			position: relative;
			border: 2px solid transparent;
			&.rate_item {
				padding-bottom: 60px;
				.description {
					@media only screen and (min-width : 700px) {
						min-height: 75px; } } }
			.place-description {
				display: block;
				text-align: right;
				margin-top: 10%;
				margin-bottom: 10px;
				padding: 5px 10px;
				border: 1px solid #f3f5fb;
				border-radius: 6px;
				strong {
					color: #65c58c; } }
			&.active {
				border-color: $colorAccent;
				span {
					&::after {
						opacity: 1;
						visibility: visible; } }
				.place-description {
					border-color: $colorAccent;
					strong {
						color: $colorAccent; } } }
			p {
				font-family: $HelveticaNeueCyr;
				font-size: 14px;
				line-height: 24px;
				color: $colorGray;
				max-width: 312px;
				margin: 0 auto; }
			.main-btn {
				position: absolute;
				bottom: 40px;
				left: 50%;
				transform: translate(-50%,0);
				min-width: auto; }
			&:hover {
				box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15); } }
		&__name {
			display: inline-block;
			font-size: 20px;
			line-height: 1.6;
			font-weight: normal;
			color: $colorMain;
			margin-bottom: 16px;
			position: relative;
			b {
				position: absolute;
				top: 50%;
				right: -12px;
				transform: translate(100%,-50%);
				display: inline-block;
				font-size: 9px;
				line-height: 16px;
				font-weight: bold;
				color: #fff;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				padding: 0 8px;
				background: #65C58C;
				border-radius: 10px;
				margin-left: 12px; } }
		&__price {
			display: block;
			line-height: 1;
			font-weight: 300;
			color: $colorMain;
			padding-bottom: 30px; }
		&-4 {
			width: 25%;
			.participation-col__name {
				line-height: 1.2em;
				min-height: 2.4em; }
			.participation-col__price {
				font-size: 42px; } } } }

@media only screen and (max-width : 768px) {
	.participation {
		&-block {
			padding: 32px;
			margin-bottom: 16px;
			.status {
				position: static;
				transform: translate(0,0); } }
		&-info {
			padding: 0; }
		&-img {
			margin-right: 36px; }
		&-name {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 12px; }
		&-row {
			margin: 32px -12px 40px; }
		&-col {
			&__wrap {
				padding: 32px 25px 96px;
				p {
					font-size: 12px;
					line-height: 20px; }
				.main-btn {
					bottom: 32px; } }
			&__name {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 8px; }
			&__price {
				font-size: 40px;
				padding-bottom: 24px; } } } }

@media only screen and (max-width : 700px) {
	.participation {
		&-block {
			margin-bottom: 20px; }
		&-info {
			margin: 0; }
		&-row {
			display: block;
			padding: 0 0 16px;
			margin: 0; }
		&-col {
			width: 100%;
			padding: 0 0 24px;
			&__wrap {
				padding: 24px 24px 88px;
				.main-btn {
					bottom: 24px; } } } } }

@media only screen and (max-width : 600px) {
	.participation {
		&-block {
			padding: 0;
			.status {
				position: absolute;
				top: auto;
				right: auto;
				bottom: 24px;
				left: 24px; } }
		&-info {
			flex-flow: row wrap;
			padding-bottom: 68px;
			&__all {
				width: 100%;
				order: -1; } }
		&-img {
			max-width: 100%;
			margin: 0;
			img {
				display: block;
				width: 100%;
				max-width: 100%; } }
		&-name {
			padding: 24px;
			margin: 0; } } }
