@import "vars";

.ui-datepicker {
	&.ui-widget.ui-widget-content {
		border: none;
		box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
		border-radius: $borderRadius;
		padding: 0;
		width: 392px!important;
		z-index: 99!important; }
	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
		text-align: center;
		font-family: $Rubik;
		font-size: 14px;
		line-height: 44px;
		color: $colorMain;
		border-radius: 50%;
		border: 2px solid transparent;
		background: transparent;
		width: 48px;
		height: 48px;
		padding: 0;
		&.ui-state-active {
			border-color: $colorAccent; } }
	.ui-state-default, .ui-widget-content .ui-state-default {
		margin: 0 auto; } }

.ui-widget-header {
	background: transparent;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #EBEEF5; }
.ui-datepicker .ui-datepicker-header {
	padding: 24px 0 20px; }
.ui-datepicker .ui-datepicker-title {
	font-family: $Rubik;
	font-size: 16px;
	line-height: 20px;
	font-weight: normal;
	color: $colorMain; }
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
	top: 50%;
	transform: translate(0,-75%);
	width: 12px;
	height: 24px;
	border: none;
	&:hover {
		background-color: transparent;
		border-color: transparent;
		opacity: 1; } }
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
	margin: 0;
	cursor: pointer;
	transition: $transition;
	&:hover {
		opacity: .5; } }

.ui-datepicker .ui-datepicker-prev {
	left: 24px; }
.ui-datepicker .ui-datepicker-next {
	right: 24px;
	transform: translate(0,-10%) rotate(180deg); }
.ui-widget-header .ui-icon {
	background: url(../img/arrow.svg) no-repeat center;
	background-size: contain; }

.ui-datepicker th {
	padding: 32px 5px 10px;
	font-family: $HelveticaNeueCyr;
	font-size: 9px;
	line-height: 11px;
	font-weight: bold;
	color: $colorGray;
	letter-spacing: 0.1em;
	text-transform: uppercase; }
.ui-datepicker td {
	padding: 0; }

@media only screen and (max-width : 450px) {
	.ui-datepicker {
		&.ui-widget.ui-widget-content {
			width: 280px!important; }
		.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
			width: 28px;
			height: 28px;
			font-size: 12px;
			line-height: 24px; } } }
