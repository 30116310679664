@import "vars";

.login {
	text-align: center;
	padding-bottom: 80px;
	.page-title {
		padding-bottom: 24px; }
	&-text {
		font-family: $HelveticaNeueCyr;
		font-size: 16px;
		line-height: 30px;
		padding-bottom: 80px;
		a {
			color: $colorAccent;
			&:hover {
				text-decoration: underline; } } }
	&-content {
		max-width: 600px;
		padding: 80px 60px;
		margin: 0 auto;
		border: 1px solid #E3E6EF;
		border-radius: $borderRadius;
		input {
			margin-bottom: 40px; }
		.text-error {
			padding-top: 0;
			margin-top: -30px;
			margin-bottom: 20px;
			@media only screen and (max-width : 768px) {
				margin-top: -8px; } }
		.social {
			padding: 8px 0 48px; }
		.checkbox {
			margin-bottom: 48px; }
		.main-btn,.second-btn {
			display: block;
			width: 100%; }
		.main-btn {
			margin-bottom: 24px; }
		.forgot {
			.checkbox {
				margin: 0;
				p {
					font-size: 14px;
					line-height: 24px; } } } }
	&-wrap {
		max-width: 392px;
		margin: 0 auto; }
	&-input {
		margin-bottom: 48px;
		input {
			margin: 0; } } }

@media only screen and (max-width : 768px) {
	.login {
		padding-bottom: 64px;
		&-text {
			padding-bottom: 40px; }
		&-content {
			max-width: 456px;
			padding: 64px 25px;
			input {
				margin-bottom: 24px; }
			.social {
				padding: 16px 0 40px; }
			.main-btn {
				margin-bottom: 16px; } }
		&-wrap {
			max-width: 336px; } } }

@media only screen and (max-width : 450px) {
	.login {
		.page-title {
			padding-bottom: 16px; }
		&-text {
			font-size: 12px;
			line-height: 20px;
			padding-bottom: 24px; }
		&-content {
			max-width: 100%;
			border: none;
			padding: 40px 0 0;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: -30px;
				width: 200%;
				height: 1px;
				background: #e3e6ef; }
			input {
				margin-bottom: 16px; }
			.main-btn {
				margin-bottom: 8px;
				max-width: 100%; } }
		&-wrap {
			max-width: 100%; }
		&-input {
			margin-bottom: 40px; } } }
