@import "vars";

.about {
	position: relative;
	&-bg {
		position: absolute;
		top: -390px;
		right: -200px; }
	.wrapper {
		position: relative;
		z-index: 2; }
	&-info {
		text-align: center;
		padding-bottom: 80px;
		border-bottom: 1px solid #E3E6EF;
		margin-bottom: 80px; }
	.page-title {
		line-height: 1.555;
		max-width: 783px;
		margin: 0 auto;
		text-align: center; }
	&-slider {
		padding-bottom: 80px; }
	&-slide {
		padding: 0 10px;
		span {
			display: block;
			padding-bottom: 40px;
			font-size: 96px;
			line-height: 1;
			font-weight: 300;
			color: $colorAccent; }
		p {
			font-size: 16px;
			line-height: 24px;
			color: $colorGray; } }
	&-btns {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		.main-btn,.second-btn {
			margin: 0 8px; } }
	.section-title {
		padding-bottom: 40px; }
	&-picture {
		text-align: center;
		margin-bottom: 80px; } }

@media only screen and (max-width : 1020px) {
	.about {
		&-bg {
			display: none; }
		&-slide {
			span {
				font-size: 60px; } } } }

@media only screen and (max-width : 768px) {
	.about {
		.page-title {
			line-height: 1.333;
			padding-bottom: 64px; }
		&-info {
			padding-bottom: 64px;
			margin-bottom: 64px; }
		&-slider {
			padding-bottom: 64px; }
		&-slide {
			span {
				font-size: 48px;
				line-height: 1.3;
				padding-bottom: 8px; } }
		.section-title {
			padding-bottom: 32px; }
		&-picture {
			margin: 0 -36px 48px; } } }

@media only screen and (max-width : 700px) {
	.about-picture {
		margin: 0 -20px 32px; } }

@media only screen and (max-width : 600px) {
	.about {
		padding-bottom: 8px;
		.page-title {
			padding-bottom: 32px; }
		&-info {
			padding-bottom: 48px;
			margin-bottom: 48px; }
		&-slider {
			padding-bottom: 48px; }
		&-slide {
			span {
				font-size: 36px;
				line-height: 45px; }
			p {
				font-size: 12px;
				line-height: 20px; } }
		&-btns {
			margin-top: 32px; }
		.section-title {
			padding-bottom: 24px; } } }

@media only screen and (max-width : 450px) {
	.about-btns {
		display: block;
		.main-btn,.second-btn {
			display: block;
			width: 100%; }
		.main-btn {
			margin-bottom: 8px; } } }

