.w-100,.marathon-content.cke_editable {
	width: 100%;
	max-width: 100%;
	.step-row {
		margin: 0; } }
.cke_editable {
	p {
		max-width: 100%!important; } }
.hidden {
	display: none !important; }
.note-editor.note-frame .note-toolbar {
	text-align: left; }
.disabled {
	opacity: .5;
	pointer-events: none !important;
	&-gray {
		opacity: .5;
		pointer-events: none !important;
		background-color: silver;
		color: #FFFFFF; } }
.disabled-light {
	opacity: .5;
	&-gray {
		background-color: silver;
		color: #FFFFFF;
		&:hover {
			background-color: silver; } } }
li.has-inp {
	pointer-events: none;
	color: silver;
	background: rgba(192, 192, 192, 0.1); }
a.delete-test-task {
	color: silver;
	font-size: 28px;
	padding: 0 8px;
	margin-left: -36px;
	margin-top: -4px;
	line-height: inherit;
	display: inline-block;
	vertical-align: middle; }

a.delete-test-variant {
	color: silver;
	font-size: 24px;
	padding: 4px 8px;
	margin-left: -34px;
	margin-right: 2px; }

a.delete-test-task,
a.delete-test-variant {
	&:hover {
		color: darkred; } }

.loader {
	border: 4px solid #ECECEC;
	animation: spin .8s linear infinite;
	border-top: 4px solid #5c9dd4;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin: 10px auto;
	&.absolute {
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 30%; }
	&.to-bottom {
		position: absolute;
		z-index: 1;
		left: 50%;
		bottom: 0;
		width: 42px;
		height: 42px;
		margin: 8px auto; } }

.header-bell {
	.loader {
		animation: none; }
	&.drop-active {
		.loader {
			animation: spin .8s linear infinite; } } }


@keyframes spin {
	0% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(360deg); } }

div.users-table {
	position: relative; }

.mr-10 {
	margin-right: 10px!important; }
.pb-20 {
	padding-bottom: 20px!important; }

div.mt-40-b {
	margin-top: 40px;
	border-top: 1px solid #E3E6EF;
	padding-top: 40px; }

li.permission {
	white-space: nowrap;
	line-height: 1.2em; }
.tariff {
	.checkbox.checkbox-big {
		input[type="checkbox"] {
			z-index: auto; }
		span {
			cursor: pointer; } } }

.step-filling__row:not(.step-active) {
	cursor: pointer;
	&:hover {
		border: 2px solid rgba(94, 181, 254, 0.2);
		transition: 0.3s; }
	&.step-order-row {
		border: 2px solid rgba(192, 192, 192, 0.2);
		transition: 0.3s;
		&:hover {
			border: 2px solid rgba(94, 181, 254, 0.8);
			transition: 0.3s; } }
	&.step-disabled {
		cursor: no-drop!important;
		opacity: 0.8;
		border: 2px solid rgba(192, 192, 192, 0.2);
		transition: 0.3s; } }
.pb-24 {
	padding-bottom: 24px !important; }

.modal {
	textarea.marathon-note {
		padding-top: 15px;
		background-color: #F2F5FB; } }

.pt-0 {
	padding-top: 0 !important; }

.pt-1 {
	padding-top: 0.25rem !important; }

.pt-2 {
	padding-top: 0.5rem !important; }

.pt-3 {
	padding-top: 1rem !important; }

.pt-4 {
	padding-top: 1.5rem !important; }

.pt-5 {
	padding-top: 3rem !important; }

.pe-0 {
	padding-right: 0 !important; }

.pe-1 {
	padding-right: 0.25rem !important; }

.pe-2 {
	padding-right: 0.5rem !important; }

.pe-3 {
	padding-right: 1rem !important; }

.pe-4 {
	padding-right: 1.5rem !important; }

.pe-5 {
	padding-right: 3rem !important; }

.pb-0 {
	padding-bottom: 0 !important; }

.pb-1 {
	padding-bottom: 0.25rem !important; }

.pb-2 {
	padding-bottom: 0.5rem !important; }

.pb-3 {
	padding-bottom: 1rem !important; }

.pb-4 {
	padding-bottom: 1.5rem !important; }

.pb-5 {
	padding-bottom: 3rem !important; }
.mt-0 {
	margin-top: 0 !important; }
.mt-1 {
	margin-top: 0.25rem !important; }
.mt-2 {
	margin-top: 0.5rem !important; }
.mt-3 {
	margin-top: 1rem !important; }
.mt-4 {
	margin-top: 1.5rem !important; }
.mt-5 {
	margin-top: 3rem !important; }
.mb-0 {
	margin-bottom: 0 !important; }
.mb-1 {
	margin-bottom: 0.25rem !important; }
.mb-2 {
	margin-bottom: 0.5rem !important; }
.mb-3 {
	margin-bottom: 1rem !important; }
.mb-4 {
	margin-bottom: 1.5rem !important; }
.mb-5 {
	margin-bottom: 3rem !important; }
.mmb-2 {
	@media only screen and (max-width : 768px) {
		margin-bottom: 0.5rem !important; } }
.mmb-4 {
	@media only screen and (max-width : 768px) {
		margin-bottom: 1.5rem !important; } }
.full-width {
	width: 100%; }
.d-block {
	display: block!important; }
.d-inline-flex {
	display: inline-flex!important; }

.with_dropdown {
	z-index: 999!important; }
.with_dropdown_list {
	@media only screen and (max-width : 768px) {
		width: 100%!important;
		text-align: center!important;
		li {
			padding-bottom: 10px; } } }
.textarea-descr .short-description,
.step-block .short-description {
	color: #3f4d64;
	font-size: 16px;
	line-height: 24px; }
.no-margin-top {
	margin-top: 0!important; }
.cke_inner .step-wrap {
	display: none; }
.cke_inner.cke_maximized .step-wrap {
	display: block; }
.cke_inner.cke_maximized .step-wrap .main-btn {
	display: block; }
.d-flex {
	display: flex;
	.step-subtitle+.step-subtitle {
		margin-left: 40px; } }
.align-items-center {
	align-items: center!important; }
.align-items-start {
	align-items: flex-start!important; }
.justify-content-center {
	justify-content: center!important; }
.fs-16 {
	font-size: 16px!important; }
.fs-18 {
	font-size: 18px!important; }
.fs-20 {
	font-size: 20px!important; }
.cabinet-btns {
	a {
		margin: 0!important; }
	a+a {
		margin: 0 10px!important; }
	@media only screen and (max-width : 768px) {
		a+a {
			margin: 10px 0!important; } } }
.about-btns {
	a {
		display: flex;
		align-items: center;
		svg {
			margin-right: 12px; } } }
.select {
	&.active {
		.search_block {
			display: block; } } }
.nano {
	.search_block {
		margin-bottom: 10px;
		display: none;
		input {
			display: block;
			opacity: 1;
			visibility: visible;
			background: #f3f5fb;
			border-radius: 0; } }
	.search_block+.nano-content {
		top: 52px; } }

.draggable+.slick-dots {
	li {
		border-color: #ccd3dc; }
	li.slick-active {
		border-color: #5eb5fe;
		button {
			&:before {
				opacity: .75;
				color: #000;
				width: 6px;
				height: 6px;
				background: #5eb5fe;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				margin-top: -3px;
				margin-left: -3px; } } } }
.text-center {
	text-align: center; }
.table-wrapper {
	display: block;

	@media only screen and (max-width : 768px) {
		overflow-x: auto;
		overflow-y: visible;
		white-space: nowrap; } }
.cabinet-table {
	width: 100%;
	border-collapse: collapse;
	.marathon-preview img {
		max-width: 80px;
		border-radius: 6px; }
	.preview_image {
		width: 80px;
		height: 80px;
		background-size: cover;
		background-position: center;
		border-radius: 6px;
		overflow: hidden; }
	.table-btns {
		width: 100%;
		display: flex;
		flex-direction: row; }
	th {
		text-align: left; }
	.table-row {
		td {
			padding: 24px;
			font-size: 14px; } }
	.bottom_info {
		display: flex;
		align-items: center;
		margin-top: 10px; }
	th, td {
		position: relative;
		&:not(:last-child) {
			padding-right: 0!important; }
		.select {
			font-weight: normal; } }
	.table-filter {
		margin-top: 10px; }
	.user_block {
		display: flex;
		align-items: center;
		.user_avatar {
			margin-right: 20px; } }
	.status_column {
		min-width: 150px; }
	.search-ico,
	.marathon-page-filter-submit {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		cursor: pointer;
		border-radius: $borderRadius;
		margin-left: 18px;
		border: 1px solid transparent;
		transition: $transition;
		z-index: 11;
		&:not(.relative) {
			position: absolute;
			top: 0;
			right: 36px; }
		svg {
			&.search-close {
				display: none; } }
		&:hover {
			background: #E6E9F1; } } }
.item_type {
	font-size: 10px;
	line-height: 1.2;
	font-weight: 500;
	color: #96a2b7;
	letter-spacing: .1em;
	text-transform: uppercase;
	svg {
		margin-right: 5px; }
	span {
		color: #3f4d64; }
	&+.item_type {
		margin-left: 10px; }
	&+p {
		margin-top: 5px; } }
.participation-img {
	&.article-img {
		border-radius: 6px; } }
.cabinet-top {
	@media only screen and (max-width : 768px) {
		.breadcrumb {
			display: none!important; } } }

.cabinet-row.top-row {
	@media only screen and (max-width : 768px) {
		margin: 0;
		padding: 0; } }
.participation-block {
	&.light {
		background: transparent;
		border-bottom: 1px solid #e3e6ef;
		padding: 0 170px 10px 0;
		@media only screen and (max-width : 768px) {
			padding: 0 0 20px 0;
			h3 {
				padding: 0; }
			.status {
				position: relative;
				left: 0;
				right: 0; } }
		.participation-info {
			padding: 0; }
		.participation-img {
			display: none; }
		.status {
			top: 10px;
			right: 0; } } }
.checkbox.checkbox-big {
	p {
		max-width: 100%;
		font-size: 14px; } }
.general-form {
	.text-error {
		font-size: 14px; }
	.users-table {
		padding-bottom: 0; }
	.users-table__row__empty {
		width: 100%;
		max-width: 100%;
		margin: 20px 0; }
	.download-info.text {
		max-width: 800px; }
	.step-col {
		padding-left: 5px;
		padding-right: 5px; } }
.show-d {
	display: block!important;
	@media only screen and (max-width : 768px) {
		display: none!important; } }
.show-m {
	display: none!important;
	@media only screen and (max-width : 768px) {
		display: block!important;
		.second-btn {
			max-width: 100%; } } }
.step-nav {
	@media only screen and (max-width : 768px) {
		justify-content: space-between; } }
.involvement-form, form, .about-info {
	@media only screen and (max-width : 768px) {
		.main-btn,.second-btn {
			max-width: 100%; } } }
.new-advantages__img {
	@media only screen and (max-width : 768px) {
		min-width: 100%!important;
		max-width: 100%!important; } }
.trial-block-top {
	@media only screen and (max-width : 768px) {
		padding: 20px!important;
		.participation-info {
			padding-bottom: 0;
			.status {
				position: relative;
				left: 0;
				top: auto;
				bottom: auto; } } } }
.step-filling__info {
	.step-delete {
		right: -40px;
		position: absolute; } }

