@import "vars";

.marathon {
	//margin-top: -40px
	&-text {
		font-family: $HelveticaNeueCyr;
		font-size: 16px;
		line-height: 30px;
		max-width: 350px; }
	&-row {
		position: relative;
		&.with_cover {
			.marathon-bg {
				display: none!important; }
			.marathon-img {
				display: none!important; }
			.marathon-block {
				width: auto;
				left: 20px;
				right: 20px;
				bottom: 20px;
				@media only screen and (max-width : 768px) {
					left: 0;
					right: 0;
					border-top-left-radius: 0;
					border-top-right-radius: 0; } }
			.marathon-main {
				color: #ffffff;
				max-width: 100%;
				border-radius: 15px;
				@media only screen and (max-width : 768px) {
					border-radius: 6px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0; }
				overflow: hidden;
				padding: 60px 20px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: top center;
				display: flex;
				align-items: center;
				justify-content: start;
				flex-direction: column;
				&:before {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					z-index: -1;
					width: 100%;
					height: 100%;
					content: "";
					-webkit-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					background-color: #18181a;
					opacity: 0.65; }
				.page-title {
					padding-bottom: 40px; }
				.marathon-list {
					max-width: none;
					li {
						padding: 0 15px 0 0;
						margin: 0 15px 0 0;
						border-right: 1px solid rgba(255, 255, 255, 0.5);
						&:last-child {
							border-right: 0;
							padding-right: 0;
							margin-right: 0; }
						span {
							color: #ffffff;
							@media only screen and (max-width : 768px) {
								font-size: 10px; } } } }
				.first_block {
					flex-direction: column; }
				.second_block {
					p {
						color: #ffffff; }
					&.bottom {
						text-align: center;
						align-items: center;
						display: flex;
						flex-direction: column;
						.first_block {
							margin-bottom: 20px; } } }
				.badge {
					background: #ffffff;
					border-radius: 6px;
					padding: 4px;
					margin-bottom: 10px; }
				@media only screen and (max-width : 768px) {
					height: auto;
					padding-bottom: 20px; } } } }

	&-main {
		max-width: 416px;
		height: 580px;
		padding-right: 20px;
		background: #F2F5FB;
		position: relative;
		z-index: 2;
		.page-title {
			max-width: 400px;
			padding-bottom: 60px;
			word-break: break-word; }
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			max-width: 340px;
			margin-bottom: 42px;
			.main-btn {
				min-width: auto; }
			span {
				display: block;
				font-size: 14px;
				line-height: 20px;
				color: #65C58C;
				padding-bottom: 4px; }
			p {
				font-family: $HelveticaNeueCyr;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray; } } }
	&-user {
		height: 620px; }
	&-category {
		display: inline-block;
		font-size: 10px;
		line-height: 2.5;
		font-weight: 500;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $colorGray;
		margin-bottom: 20px;
		a {
			color: $colorGray; } }
	&-list {
		min-height: 22px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		max-width: 328px;
		margin: 0 -5px 40px;
		li {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			padding: 0 5px;
			span {
				display: inline-block;
				padding-left: 12px;
				font-size: 12px;
				line-height: 20px;
				color: $colorMain; } } }
	&-bg {
		position: absolute;
		top: 0;
		right: -86px;
		width: 73%;
		height: 580px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover; }
	&-img {
		display: none; }
	&-block,
	&-header-panel {
		position: absolute;
		bottom: 80px;
		left: 0;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 96px;
		padding: 24px 24px 24px 40px;
		background: #fff;
		box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
		border-radius: $borderRadius;
		z-index: 3;
		.prompt {
			display: block;
			li {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				color: #3F4D64;
				div {
					display: flex;
					flex-flow: row nowrap;
					align-items: center; }
				&.text-gray {
					color: $colorGray; } } }
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center; }
		&__img {
			padding-right: 42px;
			img {
				display: block;
				max-width: 48px;
				border-radius: 50%; }
			.avatar {
				width: 40px;
				height: 40px;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				background-color: #e6e9f0;
				background-size: cover;
				background-position: center; } }

		&__info {
			span {
				display: block;
				padding-bottom: 4px;
				font-family: $HelveticaNeueCyr;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray; } }
		&__name {
			font-size: 16px;
			line-height: 24px;
			font-weight: normal;
			color: $colorMain;
			&__multiple {
				font-size: 14px;
				line-height: 20px; }
			a {
				color: $colorMain; } }
		&__ball {
			position: absolute;
			top: 50%;
			right: 300px;
			transform: translate(0,-50%);
			&.active {
				.marathon-block {
					&__b {
						b {
							border-color: #EBEEF5;
							background: #EBEEF5; } }
					&__drop {
						opacity: 1;
						visibility: visible; } } } }
		&__b {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			cursor: pointer;
			span {
				font-size: 36px;
				line-height: 48px;
				font-weight: 300;
				color: $colorMain;
				padding-right: 20px; }
			b {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid #CCD3DC;
				transition: $transition; } }
		&__drop {
			position: absolute;
			top: 110%;
			left: -105px;
			width: 392px;
			background: #fff;
			box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
			border-radius: $borderRadius;
			padding: 32px 40px 16px;
			opacity: 0;
			visibility: hidden;
			transition: $transition;
			ul {
				li {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 16px;
					font-size: 14px;
					line-height: 24px;
					font-weight: normal; } } } }
	&-header-panel {
		transition: all .3s;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.marathon_title {
			display: none;
			padding: 10px 24px;
			border-bottom: 1px solid #e3e6ef;
			width: 100%;
			text-align: center; }
		@media only screen and (max-width : 768px) {
			display: none; }
		&.floated {
			display: none;
			position: fixed;
			&.fixed {
				display: flex;
				top: 0;
				left: 0;
				right: 0;
				animation: smoothScroll .3s forwards;
				.marathon_title {
					display: block;
					@media only screen and (max-width : 768px) {
						display: none; } }
				@media only screen and (max-width : 768px) {
					top: auto;
					bottom: 0;
					display: flex; }
				@media only screen and (max-width : 1020px) {
					display: none; } } }
		@keyframes smoothScroll {
			0% {
				transform: translateY(-80px); }
			100% {
				transform: translateY(0px); } }
		padding: 0;
		position: relative;
		bottom: auto;
		top: auto;
		left: auto;
		right: auto;
		height: auto;
		.cabinet-tabs {
			width: 100%;
			justify-content: center;
			li {
				width: 25%;
				padding: 0; }
			@media only screen and (max-width : 768px) {
				display: flex; }
			a {
				justify-content: center;
				span {
					margin: 0!important; }
				padding: 10px 24px;
				&:hover {
					background: #f9fafc;
					span {
						color: $colorAccent; } }
				&.active {
					background: $colorAccent;
					span {
						color: #ffffff;
						margin: 0; }
					svg, path {
						fill: #ffffff;
						stroke: transparent; } }
				@media only screen and (max-width : 768px) {
					height: 100%;
					svg {
						margin: 0; }
					span {
						display: none; } } } } }

	&-subttile {
		position: absolute;
		bottom: 0;
		left: 0;
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		color: $colorMain;
		z-index: 2; }
	.marathon-info {
		border-bottom: 1px solid #E3E6EF; }
	&-info {
		display: flex;
		flex-flow: row nowrap;
		padding: 40px 0;
		margin: 0 -5px;
		&__col {
			width: 18%;
			padding: 0 5px;
			span {
				display: block;
				padding-bottom: 8px;
				font-family: $HelveticaNeueCyr;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray;
				a {
					color: $colorGray; } }
			p {
				font-size: 14px;
				line-height: 20px;
				color: $colorMain;
				a {
					color: $colorMain; } } } }
	&-description {
		img {
			opacity: 1!important; }
		&__wrap {
			border-top: 1px solid #E3E6EF;
			//padding: 80px 0
			padding: 30px 0 0; }
		&__bottom {
			padding-bottom: 0;
			border-top: 0; }
		iframe {
			opacity: 1!important;
			width: 100%;
			//min-height: 550px
			@media only screen and (max-width : 768px) {
 } } }				//max-height: 350px
	&-content {
		&.cke_editable {
			padding: 20px;
			max-width: 1000px;
			margin: 0 auto;
			border-left: 1px solid #ebeff8;
			border-right: 1px solid #ebeff8; }
		&.timeline {
			padding-bottom: 0!important; }
		position: relative;
		ol, ul {
			li {
				list-style: inherit;
				margin-left: 20px; } }
		.marathon-tags {
			li {
				list-style: none;
				margin-left: 0; } }
		p, ul:not(.slick-dots), ol {
			//max-width: 1000px
			margin: 0 auto 20px;
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 30px;
			//padding-bottom: 30px
			word-break: break-word;
			a {
				color: $colorAccent; }
			@media only screen and (max-width : 768px) {
				max-width: 100%;
				margin-bottom: 16px;
				font-size: 16px;
				line-height: 28px; }
			@media only screen and (max-width : 450px) {
				margin-bottom: 8px;
				font-size: 14px;
				line-height: 24px;
				padding-bottom: 24px; } }
		h3 {
			//max-width: 600px
			margin: 0 auto;
			font-size: 30px;
			line-height: 1.3;
			font-weight: normal;
			padding-bottom: 20px;
			@media only screen and (max-width : 768px) {
				max-width: 100%;
				font-size: 30px;
				line-height: 1.3;
				padding-bottom: 20px; }
			@media only screen and (max-width : 600px) {
				font-size: 20px;
				line-height: 1.3;
				padding-bottom: 20px; } } }

	&-picture {
		padding-bottom: 40px;
		border-bottom: 1px solid #E3E6EF; }
	.faq-info {
		.second-btn {
			display: block; } }
	&-bottom {
		max-width: 600px;
		margin: 0 auto; }
	&-ul {
		display: flex;
		flex-flow: row nowrap;
		padding-top: 35px;
		p {
			font-size: 20px;
			line-height: 1.5;
			font-weight: normal;
			color: $colorMain;
			padding-right: 32px; }
		ul {
			display: flex;
			flex-flow: row wrap;
			align-items: center; } }
	&-tags {
		p, ul {
			padding-bottom: 0!important;
			margin-bottom: 0!important; } }
	&-tags {
		ul {
			li {
				padding: 0 8px 4px 0;
				a {
					display: inline-block;
					padding: 0 12px;
					font-size: 12px;
					line-height: 24px;
					color: $colorGray;
					background: #E6E9F1;
					border-radius: 12px;
					text-align: center; } } } }
	&-social {
		ul {
			li {
				padding-right: 25px;
				a {
					display: flex;
					align-items: center;
					justify-content: center; } } } }
	&-toggle {
		display: none;
		&.active {
			display: block; } }
	&-slider {
		&.slick-dotted {
			padding-bottom: 40px;
			margin: 0 auto 80px;
			.slick-dots {
				display: flex;
				justify-content: center;
				margin: 0 -12px;
				width: calc(100% + 24px);
				li {
					width: 25%;
					height: 2px;
					border-radius: 0;
					border: none;
					padding: 0;
					background: #E3E6EF;
					margin: 0 12px;
					&::after {
						display: none; }
					&.slick-active {
						background: $colorGray; } } } } }
	&-step {
		display: flex;
		flex-flow: row nowrap;
		align-items: end;
		justify-content: space-between;
		padding: 40px 0 80px;
		&-bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			.marathon-step__btns {
				padding-top: 0; } }
		&__info {
			.page-title {
				max-width: 710px;
				padding: 0;
				&-full {
					max-width: 100%; } } }
		&__btns {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			padding-top: 46px;
			.second-btn {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				margin-left: 16px;
				color: $colorMain;
				&.btn-not-active {
					opacity: .5; }
				svg {
					margin-right: 16px; }
				&:last-child {
					svg {
						margin: 0 0 0 16px; } } } } }
	&-task {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding: 41px 40px;
		background: #fff;
		border-radius: $borderRadius;
		&__date {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			span {
				display: inline-block;
				font-size: 48px;
				line-height: 1;
				font-weight: 300;
				color: $colorGray;
				padding-right: 16px; }
			p {
				font-size: 10px;
				line-height: 1.5;
				font-weight: 500;
				color: $colorGray;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				b {
					display: block;
					font-weight: 500; } } }
		&__info {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			max-width: 640px;
			width: 100%;
			padding: 0 20px;
			&.user_step {
				max-width: 100%;
				justify-content: flex-end;
				@media only screen and (max-width : 768px) {
					flex-direction: column;
					align-items: flex-end; }
				@media only screen and (max-width : 450px) {
					align-items: start; }
				.reviews-user_task {
					margin-left: 20px;
					@media only screen and (max-width : 768px) {
						margin-left: 0; } } } }
		&__step,&__p {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			svg {
				margin-left: 15px; } }
		&__step {
			font-size: 20px;
			line-height: 1.5; }
		&__p {
			font-size: 14px;
			line-height: 20px;
			color: $colorAccent; }
		.main-btn {
			min-width: auto; } }
	&-attachments {
		max-width: 600px;
		margin: 0 auto;
		.reviews-second {
			margin: 0;
			max-width: 100%; }
		&__wrap {
			padding: 50px 0 80px; }
		.main-btn {
			display: block; } }
	&-subtitle {
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		padding-bottom: 40px; }
	&.marathon-second {
		.marathon-row {
			margin-bottom: 80px; } }
	&.marathon-all {
		margin: 0;
		.page-title {
			padding-bottom: 44px; } }
	&-select {
		display: flex;
		padding-bottom: 66px;
		.select {
			&-text {
				font-size: 30px;
				line-height: 1.3;
				background: transparent;
				padding-left: 0; }
			&-info {
				min-width: 230px; } } } }

.raffle {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	background: #FFF8E2;
	border-radius: $borderRadius;
	padding: 24px 24px 24px 40px;
	margin-top: 80px;
	&-btns {
		display: flex;
		flex-flow: row nowrap;
		.second-btn {
			margin-left: 8px; } }
	&-info {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding-right: 20px; }
	&-title {
		font-size: 16px;
		line-height: 1.3;
		color: $colorMain;
		padding-left: 44px; }
	&.raffle-blue {
		background: #E3F0FC;
		margin-top: 24px;
		.second-btn {
			color: $colorMain; } } }

@media only screen and (max-width : 1020px) {
	.marathon {
		margin: 0;
		&-block {
			&__ball {
				right: 200px; }
			&__drop {
				left: -100px; } }
		&-task {
			padding: 32px;
			&__info {
				justify-content: end;
				padding: 0 35px; }
			&__p {
				span {
					display: none; } } } } }

@media only screen and (max-width : 768px) {
	.marathon {
		&-main {
			max-width: 100%;
			height: auto;
			background: transparent;
			padding: 0;
			.page-title {
				max-width: 100%;
				padding-bottom: 16px; }
			&__row {
				display: block;
				max-width: 100%;
				margin-bottom: 32px;
				.main-btn {
					display: block;
					margin-bottom: 20px; }
				div {
					display: flex;
					flex-flow: row nowrap;
					span {
						padding: 0 23px 0 0; } } } }
		&-category {
			font-size: 9px;
			margin-bottom: 16px; }
		&-bg {
			display: none; }
		&-img {
			display: block;
			margin: 0 -36px 40px;
			img {
				display: block; } }
		&-info {
			justify-content: space-between;
			padding: 24px 0 32px;
			&__col {
				width: auto; } }
		&-block {
			position: relative;
			bottom: auto;
			padding: 24px;
			margin-bottom: 40px;
			&__img {
				padding-right: 24px; }
			&__name {
				font-size: 14px;
				line-height: 20px; } }
		&-subttile {
			position: static;
			padding-bottom: 24px; }
		&-description {
			&__wrap {
				padding: 40px 0; } }
		&-slider {
			&.slick-dotted {
				padding-bottom: 32px;
				margin-bottom: 48px; } }
		&-step {
			display: block;
			padding: 0 0 64px;
			&__btns {
				justify-content: space-between;
				margin: 0 -12px;
				.second-btn {
					width: 50%;
					margin: 0 12px;
					justify-content: center; } } }
		&-attachments {
			max-width: 100%;
			.involvement-form__row {
				padding-bottom: 48px; }
			&__wrap {
				padding: 32px 0; } }
		&-subtitle {
			padding-bottom: 24px; }
		&-select {
			padding-bottom: 26px;
			.select-text {
				font-size: 24px; } } }
	.raffle {
		margin-top: 64px; }
	.timeline {
		.reviews {
			&-top {
				justify-content: end;
				.text-anim {
					span {
						display: none; } } } }
		&-participant {
			.reviews-top {
				justify-content: start; } } } }

@media only screen and (max-width : 700px) {
	.marathon {
		&-img {
			margin: 0 -20px 40px; }
		&-info {
			display: block;
			padding: 4px 0 24px;
			margin: 0 0 8px;
			&__col {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				padding: 16px 0;
				border-bottom: 1px solid #E3E6EF;
				&:last-child {
					border-bottom: none; } } }
		&-task {
			padding: 24px 24px 90px;
			position: relative;
			&.marathon-task__second {
				padding: 24px; } }
		&-btns {
			position: absolute;
			bottom: 24px;
			left: 0;
			width: 100%;
			padding: 0 24px;
			.main-btn {
				display: block; } } } }

@media only screen and (max-width : 600px) {
	.marathon {
		&-block {
			display: block;
			height: auto;
			&__row {
				display: block; }
			&__img {
				padding: 0; }
			&__info {
				padding: 16px 0 24px; }
			&__ball {
				top: 24px;
				right: 24px;
				transform: translate(0,0); }
			&__drop {
				left: auto;
				right: -24px;
				width: 280px;
				padding: 20px 20px 10px;
				ul {
					li {
						padding-bottom: 10px;
						font-size: 12px;
						line-height: 20px; } } }
			.estimate {
				justify-content: center; } }
		&-description {
			&__wrap {
				//padding: 32px 0 40px
				padding: 32px 0 0; } }
		&-slider {
			&.slick-dotted {
				padding-bottom: 24px;
				margin-bottom: 32px;
				width: calc(100% + 16px);
				.slick-dots {
					li {
						width: 100%;
						max-width: 40px;
						margin: 0 8px; } } } }
		&-task {
			display: block;
			&__info {
				padding: 4px 0 0; } } }
	.raffle {
		display: block;
		padding: 24px;
		margin: 48px 0 0;
		&-info {
			padding: 0 0 24px; }
		&-title {
			padding-left: 22px; }
		.main-btn,.second-btn {
			display: block; } } }

@media only screen and (max-width : 450px) {
	.marathon {
		.timeline {
			.reviews {
				&-top {
					display: flex;
					padding-bottom: 20px;
					.table-btn {
						top: 43px; } }
				&-name {
					padding: 0; } } }
		&-main {
			.page-title {
				padding-bottom: 24px; }
			&__row {
				margin-bottom: 24px;
				.main-btn {
					margin-bottom: 16px; }
				.first_block {
					justify-content: space-between;
					width: 100%; }
				.second_block {
					.main-btn {
						max-width: 100%;
						width: 100%;
						margin: 10px auto 0; } } } }
		&-category {
			margin-bottom: 12px; }
		&-list {
			min-height: 22px;
			max-width: 100%;
			margin: 0 0 24px;
			justify-content: end;
			li {
				padding: 0 16px 0 0;
				span {
					padding-left: 8px; } } }
		&-img {
			margin-bottom: 24px; }
		&-ul {
			display: block;
			padding-top: 32px;
			p {
				font-size: 16px;
				padding: 0 0 16px; } }
		&-task {
			&__date {
				span {
					font-size: 10px;
					line-height: 1.6;
					font-weight: 500;
					color: $colorGray;
					padding: 0 5px 0 0; }
				p {
					b {
						display: inline; } } }
			&__step {
				font-size: 16px;
				line-height: 30px; } }
		&-step {
			padding: 0 0 24px;
			&__btns {
				padding: 24px 0 0;
				margin: 0 -4px;
				.second-btn {
					min-width: auto;
					margin: 0 4px;
					padding: 9px 10px;
					font-size: 14px;
					svg {
						height: 16px;
						width: 10px;
						margin-right: 10px; }
					&:last-child svg {
						margin: 0 0 0 10px; } } } }
		&-select {
			padding-bottom: 14px;
			.select {
				&-text {
					font-size: 20px;
					line-height: 1.6;
					padding-right: 40px; } } }
		&.marathon-all {
			.page-title {
				padding-bottom: 24px; } } } }
.step-nav {
	justify-content: end; }
.block-bottom {
	display: flex;
	align-items: center;
	padding-bottom: 60px;
	@media only screen and (max-width : 768px) {
		padding-bottom: 0;
		//flex-direction: column
		.marathon-step__btns {
			a {
				min-width: 50px;
				min-height: 50px;
				span {
					display: none; } } }
		.checkbox {
			max-width: 150px; } }
	.checkbox {
		padding: 13px 24px;
		max-width: 180px;
		margin-right: 10px; }
	.second-btn {
		display: flex!important;
		line-height: inherit; }
	.marathon-step__btns {
		padding: 0; } }
.badge-mini {
	display: inline-block;
	padding: 0px 12px;
	font-size: 12px;
	line-height: 24px;
	color: #96a2b7;
	background: #e6e9f1;
	-webkit-border-radius: 12px;
	border-radius: 12px;
	text-align: center;
	&-white {
		background: #ffffff; } }


.events_block {
	display: flex;
	align-items: start;
	justify-content: space-between;
	&_item {
		width: 50%;
		@media only screen and (max-width : 768px) {
			width: 100%; }
		&:last-child {
			margin-left: 40px;
			@media only screen and (max-width : 768px) {
				margin-top: 20px;
				margin-left: 0; } } }
	.event-name-empty {
		display: flex;
		align-items: center;
		flex-direction: row;
		height: 100%;
		justify-content: start;
		img {
			display: none;
			width: 100%;
			margin-right: 10px;
			max-width: 80px; } }
	.soon_events {
		font-weight: 500;
		margin: 15px 0 5px;
		display: block; }
	@media only screen and (max-width : 768px) {
		flex-direction: column; } }
fieldset {
	border: 0; }

/*  Events display */
.events-container {
	overflow-y: scroll;
	height: 100%; }

.events-container:after {
	clear: both; }

.event-card {
	position: relative;
	padding-left: 10px;
	margin-bottom: 15px;
	border-bottom: 1px solid #e3e6ef;
	padding-bottom: 10px;
	&:last-child {
		border-bottom: 0; } }

.event-count, .event-name, .event-cancelled {
	display: inline; }
.event-time {
	color: #96a2b7; }
.event-url {
	&.stream-event {
		&:before {
			content: '';
			background-image: url('/img/icos/stream.svg');
			width: 18px;
			height: 12px;
			display: inline-block;
			margin-right: 5px; } } }


.calendar-container {
	width: 100%;
	position: relative;
	margin: 0px auto;
	background: #fff;
	display: inline-block;
	border: 1px solid #e3e6ef;
	border-radius: 6px;
	&:after {
		clear: both; } }

.event-color {
	content: "";
	width: 5px;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0; }

.calendar {
	width: 100%;
	display: table;
	padding: 10px 10px 0;
	.select-text {
		border-bottom: 1px solid #e3e6ef;
		border-radius: 0;
		padding: 14px; }
	.select-info {
		margin-top: 0;
		&.nano {
			min-height: 180px; } } }


.year-header {
	background: #f2f7ff;
	text-align: center;
	position: relative;
	padding: 10px 0;
	span {
		display: inline-block; } }

.left-button, .right-button {
	cursor: pointer;
	width: 28px;
	text-align: center;
	position: absolute; }

.left-button {
	left: 0;
	-webkit-border-top-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	border-top-left-radius: 5px; }

.right-button {
	right: 0;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	border-top-right-radius: 5px; }

.days-table, .dates-table, .months-table {
	border-collapse: separate;
	text-align: center;
	width: 100%; }

.days-table,.dates-table {
	width: 100%;
	border-collapse: collapse; }
.dates-table {
	td {
		border: 1px solid #e3e6ef; } }

.day {
	height: 26px;
	width: 26px;
	font-size: 11px;
	line-height: 26px;
	border: 2px solid transparent;
	text-transform: uppercase; }


.month {
	cursor: default;
	height: 26px;
	width: 26px;
	padding: 0 2px;
	padding-top: 10px;
	line-height: 26px;
	text-transform: uppercase;
	font-size: 11px;
	color: #9e9e9e;
	transition: all 250ms;
	&:hover {
		color: #435ebe; } }

.active-month {
	font-weight: bold;
	color: #435ebe; }



.table-date, .day {
	&:hover {
		cursor: pointer; }
	span {
		height: 36px;
		width: 36px;
		//font-size: 12px
		line-height: 26px;
		text-align: center;
		border-radius: 50%;
		border: 2px solid transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto; } }

.table-date {
	cursor: default;
	color: #2b2b2b;
	transition: all 250ms; }

.table-date {
	&:not(.event-date) {
		&:hover {
			background: #f2f7ff; } }
	&.nil {
		&:hover {
			background: transparent;
			cursor: default!important; } } }
.active-date {
	border-color: #fff8e2;
	background: #fff8e2; }
.event-date {
	background: #52A0FD;
	color: #ffffff;
	&.active-date {
		background: #65c58c; } }
.old-date {
	background: #f8f6f6;
	color: #cacdd6;
	&.event-date {
		background: rgba(82, 160, 253, 0.15); } }

.cke_maximized {
	body {
		background: #cccccc; } }


.faq-info, .marathon-content, .timeline-info, .timeline-col {
	ul {
		li {
			position: relative;
			display: block;
			width: 100%;
			margin: 0;
			padding: 10px 0 10px 25px;
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			line-height: 1.25;
			&:before {
				position: absolute;
				content: '';
				width: 8px;
				height: 8px;
				border-radius: 50%;
				top: 15px;
				left: 0;
				display: block;
				background: $colorAccent; } } }
	ol {
		counter-reset: list;
		li {
			position: relative;
			display: block;
			width: 100%;
			margin: 0;
			padding: 10px 0 10px 25px;
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			line-height: 1.25;
			border-bottom: 1px solid #e0e2e7;
			&:last-child {
				border-bottom: 0; }

			&:before {
				position: absolute;
				top: 10px;
				left: 0;
				display: block;
				content: counter(list) ".";
				counter-increment: list;
				color: $colorAccent;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				line-height: 1.25; } } } }
.timeline-row-preview {
	padding: 20px 10px 20px!important;
	.timeline-top {
		padding-bottom: 0!important; } }
.timeline-info, .timeline-col {
	ul, ol {
		li {
			font-size: 14px;
			line-height: 25px;
			padding: 15px 0 15px 15px; } }
	ol {
		li {
			&:before {
				top: 18px;
				font-size: 14px; } } }
	ul {
		li {
			&:before {
				top: 24px; } } } }
