@import "vars";

.history {
	.section-title {}
	&-table {
		border-top: 1px solid #E3E6EF;
		border-bottom: 1px solid #E3E6EF; }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		padding: 45px 24px 44px 0;
		border-radius: $borderRadius;
		&:nth-child(even) {
			background: #F8FAFF; }
		&.history-row__top {
			padding: 24px 24px 24px 0;
			p {
				font-size: 10px;
				line-height: 2;
				font-weight: 500;
				color: $colorGray;
				letter-spacing: 0.1em;
				text-transform: uppercase; } } }
	&-col {
		padding-left: 24px;
		p {
			font-size: 14px;
			line-height: 24px;
			a {
				color: $colorAccent; } }
		&__1 {
			width: 18.6%;
			min-width: 180px; }
		&__2 {
			width: 17.4%;
			min-width: 120px; }
		&__3 {
			width: 64%; } } }

@media only screen and (max-width : 768px) {
	.history {
		&-row {
			padding: 24px 24px 24px 0;
			&.history-row__top {
				padding: 16px 24px 16px 0; } } } }

@media only screen and (max-width : 600px) {
	.history {
		&-table {
			margin: 0 -20px; }
		&-row {
			padding: 20px 20px 20px 0;
			border-radius: 0; }
		&-col {
			padding: 0 0 0 20px;
			p {
				font-size: 12px;
				line-height: 20px; }
			&__1 {
				min-width: 130px; }
			&__2 {
				min-width: 120px; } } } }

@media only screen and (max-width : 450px) {
	.history {
		&-table {
			overflow-y: hidden;
			overflow-x: auto; }
		&-row {
			min-width: 200%;
			max-width: 200%;
			padding: 20px 0;
			&.history-row__top {
				padding: 16px 0; } }
		&-col {
			padding: 0 20px;
			&__1,&__2 {
				min-width: auto;
				width: 50vw; }
			&__2 {
				text-align: right; }
			&__3 {
				width: 100vw; } }
		.dots {
			display: flex; }
		.pagination {
			padding: 40px 0; } } }
