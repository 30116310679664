@import "vars";

.recording {
	padding-top: 40px;
	.timetable {
		padding: 0; } }

@media only screen and (max-width: 768px) {
	.recording {
		padding-top: 60px;
		.faq-accordeon__wrap {
			padding: 40px 0; } } }

@media only screen and (max-width: 450px) {
	.recording {
		padding-top: 25px; } }
