@import "vars";

.terms {
	padding-bottom: 40px;
	.page-title {
		text-align: center; }
	&-wrap {
		max-width: 600px;
		margin: 0 auto; }
	&-title {
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		padding: 42px 0 40px; }
	p {
		font-family: $HelveticaNeueCyr;
		font-size: 16px;
		line-height: 30px;
		padding-bottom: 38px; } }

section.static-page {
	a[href^="/"], a[href^="#"] {
		font-size: 16px;
		line-height: 20px;
		color: #3f4d64!important;
		&:hover {
			color: #5eb5fe!important;
			text-decoration: none!important; } }
	ul, ol {
		font-size: 16px;
		line-height: 30px;
		padding-bottom: 38px; }
	li {
		list-style: inherit!important;
		margin-left: 15px!important; } }
@media only screen and (max-width : 768px) {
	.terms {
		&-wrap {
			max-width: 100%; }
		p {
			padding-bottom: 25px; }
		&-title {
			padding: 15px 0 24px; } }
	section.static-page {
		ul, ol {
			padding-bottom: 25px; } } }

@media only screen and (max-width : 600px) {
	section.static-page {
		ul {
			font-size: 14px;
			line-height: 24px;
			padding-bottom: 24px; } }
	.terms {
		p {
			font-size: 14px;
			line-height: 24px;
			padding-bottom: 24px; }
		&-title {
			font-size: 16px;
			line-height: 24px;
			padding: 10px 0 16px; } } }
