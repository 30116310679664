@import "vars";

.personalArea {
	padding-bottom: 80px;
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 30px;
		.main-btn {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			svg {
				margin-right: 16px; } } }
	&-list {
		display: flex;
		flex-flow: row nowrap;
		li {
			padding-right: 48px;
			a {
				display: inline-block;
				font-size: 26px;
				line-height: 1.333;
				font-weight: normal;
				color: $colorGray;
				span {
					display: inline-block;
					padding-left: 15px;
					font-size: 16px;
					line-height: 20px;
					color: $colorAccent;
					vertical-align: top; }
				&:hover {
					color: $colorAccent; }
				&.active {
					color: $colorMain; } }
			&:last-child {
				padding-right: 0; } } } }

.notFound {
	text-align: center;
	&-img {
		max-width: 440px;
		margin: 0 auto 35px; }
	.section-title {
		padding-bottom: 24px; }
	p {
		max-width: 350px;
		margin: 0 auto 80px;
		font-family: $HelveticaNeueCyr;
		font-size: 16px;
		line-height: 24px; }
	.main-list {
		justify-content: center; } }

@media only screen and (max-width : 768px) {
	.personalArea {
		padding-bottom: 64px;
		&-top {
			padding-bottom: 64px;
			.main-btn {
				padding: 11px 24px;
				font-size: 14px; } }
		&-list {
			li {
				padding-right: 40px;
				a {
					font-size: 20px;
					line-height: 1.5;
					span {
						font-size: 14px;
						padding-left: 12px; } } } }
		.articles-row {
			padding-bottom: 40px; } } }

@media only screen and (max-width : 700px) {
	.personalArea {
		&-list {
			li {
				padding-right: 24px;
				a {
					font-size: 20px;
					line-height: 1.6;
					span {
						padding-left: 8px;
						font-size: 12px;
						line-height: 16px; } } } } }
	.notFound {
		&-img {
			margin-bottom: 25px; }
		p {
			margin-bottom: 32px; } } }

@media only screen and (max-width : 600px) {
	.personalArea {
		padding-bottom: 40px;
		&-top {
			display: block;
			padding-bottom: 24px;
			.main-btn {
				max-width: 400px;
				margin: 0 auto;
				justify-content: center; } }
		&-list {
			padding-bottom: 24px;
			flex-direction: column; }
		.articles-row {
			padding-bottom: 24px; }
		.center-btn {
			.main-btn {
				max-width: 400px;
				width: 100%; } } }
	.notFound {
		.section-title {
			padding-bottom: 12px; }
		p {
			font-size: 12px;
			line-height: 20px; }
		.main-btn {
			display: block;
			max-width: 450px;
			margin: 0 auto; } } }

