.new-partners {
    position: relative;
    margin-top: 106px;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 80px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 60px;
    }

    &__decor {
        position: absolute;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        &--md {
            right: -202px;
            bottom: -260px;
            width: 494px;
            height: 378px;
            background-image: url(../img/icon-decor-md.svg);
        }

        @media screen and (min-width: 1024px) and (max-width: 1360px) {
            &--md {
                right: -260px;
            }
        }
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    &__wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        counter-reset: new-partners-counter;

        @media screen and (min-width: 1024px) {
            margin: 0 -12px;
        }
        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }
    &__item {
        padding: 32px 36px;
        background: #fff;
        border-radius: 6px;
        &::before {
            counter-increment: new-partners-counter;
            content: counter(new-partners-counter) '.';
            display: block;
            margin-bottom: 36px;
            font-size: 48px;
            font-weight: 300;
            color: #5EB5FE;
        }

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            padding: 30px;
        }
        @media screen and (min-width: 1024px) {
            margin: 0 12px;
            width: calc(33.33% - 24px);
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            &::before {
                margin-bottom: 26px;
            }
        }
        @media screen and (max-width: 767px) {
            padding: 20px;
            &::before {
                margin-bottom: 16px;
                font-size: 32px;
            }
        }
    }
    &__item + &__item {
        @media screen and (max-width: 1023px) {
            margin-top: 20px;
        }
    }
    &__description {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.4;
        color: #3F4D64;
    }
}