@import "vars";

.breadcrumb {
	display: flex;
	flex-flow: row wrap;
	padding: 5px 0 25px;
	li {
		font-size: 10px;
		line-height: 1.5;
		font-weight: 500;
		color: $colorGray;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-right: 28px;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 45%;
			right: 12px;
			transform: translate(0,-50%);
			border-left: 6px solid $colorGray;
			border-top: 3px solid transparent;
			border-bottom: 3px solid transparent; }
		a {
			display: inline-block;
			line-height: 1.5;
			color: $colorGray; }
		&:last-child {
			&:after {
				display: none; } } } }

@media only screen and (max-width : 768px) {
	.breadcrumb {
		li {
			display: none;
			&.active {
				display: block; } } } }
