@import "vars";

.social {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	li {
		padding: 0 8px;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			background: #E6E9F1;
			border-radius: 50%; } } }

@media only screen and (max-width : 768px) {
	.social {
		li {
			a {
				width: 40px;
				height: 40px; } } } }

@media only screen and (max-width : 450px) {
	.social {
		li {
			padding: 0 4px; } } }

