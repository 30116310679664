@import "vars";

.post {
	width: 33.333%;
	padding: 0 12px 24px;
	&-wrap {
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-end;
		height: 100%;
		background: #fff;
		border-radius: $borderRadius;
		position: relative;
		transition: $transition;
		&:hover {
			box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
			.post {
				&-img {
					&__wrap {
						transform: scale(1); } }
				@media only screen and (min-width : 768px) {
					&-status {
						visibility: hidden;
						opacity: 0; }
					&-info {
						&__price {
							visibility: visible;
							opacity: 1; } } }
				&-btn {
					padding-left: 9px;
					span {
						display: inline-block;
						opacity: 1;
						visibility: visible;
						width: 121px;
						padding: 0 23px 0 1px; } }
				&-tags {
					display: flex;
					height: 26px;
					opacity: 1;
					visibility: visible; } } }
		@media only screen and (max-width : 768px) {
			.post {
				&-status {
					visibility: visible;
					opacity: 1; }
				&-info {
					&__price {
						visibility: visible;
						opacity: 1; } } } } }
	&-top {
		width: 100%;
		padding: 20px 20px; }
	&-category {
		display: inline-block;
		font-size: 10px;
		line-height: 20px;
		font-weight: 500;
		color: $colorGray;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		a {
			color: $colorGray; } }
	&-freebie {
		color: $colorAccent; }
	&-title {
		min-height: 60px;
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		color: $colorMain;
		a {
			color: $colorMain; } }
	&-img {
		width: 100%;
		min-height: 200px;
		height: 200px;
		position: relative;
		overflow: hidden;
		&__wrap {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			transition: $transition;
			transform: scale(1.05); } }
	&-al {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: absolute;
		top: 24px;
		right: 0;
		background: #7E57C2;
		border-radius: 16px 0px 0px 16px;
		font-size: 10px;
		line-height: 32px;
		color: #fff;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding: 0 16px 0 12px;
		z-index: 1;
		&-user_type {
			border-radius: 0px 16px 16px 0;
			right: auto;
			left: 0;
			span {
				padding-left: 0!important; } }
		span {
			padding-left: 8px; }
		&-ul {
			background: $colorGreen;
			color: #ffffff; }
		&-fl {
			background: $colorMain;
			color: #ffffff; }
		&-participant {
			background: rgba(101, 197, 140, 0.9);
			top: auto;
			bottom: 24px;
			&.marathon-label {
				top: auto!important; } }


		&-raffle {
			background: #6cbcff;
			color: #ffffff; }
		&-external {
			background: $colorMain;
			svg {
				position: relative;
				top: -1px; } } }
	&-status {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0,-50%);
		font-size: 14px;
		line-height: 20px;
		color: $colorGray;
		transition: $transition; }
	&-btn {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background: $colorAccent;
		border-radius: $borderRadius;
		white-space: nowrap;
		font-size: 16px;
		line-height: 20px;
		color: #fff;
		transition: $transition;
		z-index: 2;
		b {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			min-width: 48px;
			height: 48px; }
		span {
			display: inline-block;
			opacity: 0;
			visibility: hidden;
			width: 0;
			padding: 0;
			transition: $transition; } }
	&-info {
		width: 100%;
		&__wrap {
			padding: 20px 20px;
			border-bottom: 1px solid #EBEEF5; }
		&__text {
			padding-bottom: 12px;
			p {
				font-size: 12px;
				line-height: 20px;
				color: $colorGray;
				height: 60px;
				overflow: hidden;
				word-break: break-word; } }
		&__price {
			font-size: 14px;
			line-height: 20px;
			color: $colorGray;
			opacity: 0;
			visibility: hidden; }
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
			position: relative; }
		&__bottom {
			padding: 20px 20px;
			ul {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				padding-right: 15px;
				li {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					font-size: 12px;
					line-height: 20px;
					color: $colorMain;
					a {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						color: $colorMain; }
					span {
						display: inline-block;
						padding-left: 12px; } } } } }
	&-tags {
		display: flex;
		flex-flow: row wrap;
		transition: $transition;
		position: relative;
		height: 26px;
		opacity: 1;
		visibility: visible;
		li {
			padding: 5px 16px 5px 0;
			a {
				font-family: $HelveticaNeueCyr;
				display: inline-block;
				font-size: 12px;
				line-height: 20px;
				color: $colorGray; }
			&:last-child {
				padding-right: 0; } } } }

.text-red {
	color: #FF655B; }

.text-green {
	color: #65C58C; }

@media only screen and (max-width : 1140px) {
	.post {
		&-top {
			padding: 40px 20px 30.5px; }
		&-info {
			&__wrap {
				padding: 32px 20px 30px; }
			&__bottom {
				padding: 17px 20px 18px; } } } }

@media only screen and (max-width : 1020px) {
	.post {
		width: 50%;
		&-top {
			padding: 40px 40px 30.5px; }
		&-info {
			&__wrap {
				padding: 32px 40px 30px; }
			&__bottom {
				padding: 17px 40px 18px; } } } }

@media only screen and (max-width : 768px) {
	.post {
		&-top {
			padding: 32px; }
		&-category {
			margin-bottom: 16px; }
		&-title {
			min-height: 48px;
			font-size: 16px;
			line-height: 24px; }
		&-img {
			min-height: 172px;
			height: 172px; }
		&-info {
			&__wrap {
				padding: 32px; }
			&__row {
				display: block;
				margin: 0; }
			&__bottom {
				padding: 17px 32px 18px;
				ul {
					li {
						font-size: 11px;
						span {
							padding-left: 8px; } } } } }
		&-btn {
			justify-content: center;
			b {
				min-width: 40px;
				height: 40px; }
			span {
				display: block;
				width: auto;
				opacity: 1;
				visibility: visible;
				padding: 0 0 0 5px; } }
		&-tags {
			height: 100%;
			opacity: 1;
			visibility: visible; }
		&-status {
			position: static;
			transform: translate(0,0);
			padding-top: 16px; }
		&-wrap {
			&:hover {
				.post-btn {
					padding: 0;
					span {
						width: auto;
						padding: 0 0 0 5px; } } } } } }

@media only screen and (max-width : 700px) {
	.post {
		&-top {
			padding: 24px; }
		&-info {
			&__wrap {
				padding: 24px; }
			&__bottom {
				padding: 16px 24px; } } } }

@media only screen and (max-width : 600px) {
	.post {
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		padding: 0 0 8px;
		&-wrap {
			width: 100%; } } }

@media only screen and (max-width : 450px) {
	.post {
		&-img {
			min-height: 144px;
			height: 144px; }
		&-tags {
 }			//display: none
		&-info {
			&__text {
				display: none; }
			&__bottom {
				ul {
					li {
						span {
							font-size: 10px; } } } } } } }
