@import "vars";

.faq {
	&-list {
		&__row {
			padding: 24px 0 24px 104px;
			border-bottom: 1px solid #E3E6EF;
			position: relative;
			a,p {
				font-size: 16px;
				line-height: 24px;
				color: $colorMain; }
			a {
				&:hover {
					color: $colorAccent; } }
			span {
				position: absolute;
				top: 24px;
				left: 0;
				font-size: 16px;
				line-height: 24px; } }
		&__top {
			border-top: 1px solid #E3E6EF;
			a,p,span {
				font-size: 10px;
				line-height: 2;
				font-weight: 500;
				color: $colorGray;
				letter-spacing: 0.1em;
				text-transform: uppercase; } } } }

@media only screen and (max-width : 768px) {
	.faq-list__row {
		padding-left: 60px; } }
