@import "vars";

.block-wrap {
	padding: 20px 0; }

.calendar {
	margin-bottom: 10px;
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 50px; }
	&-title {
		font-size: 30px;
		line-height: 1.3;
		font-weight: normal; }
	&-pagination {
		display: flex;
		flex-flow: row nowrap;
		align-items: center; }
	&-prev,&-next {
		padding: 0 12px;
		cursor: pointer;
		svg {
			display: block; } }
	&-next {
		margin-right: -12px; }
	&-row {
		min-height: 185px;
		display: flex;
		flex-flow: row wrap;
		padding: 24px 0;
		border-bottom: 1px solid #E3E6EF;
		&__top {
			min-height: auto;
			padding: 0;
			border-top: 1px solid #E3E6EF; } }
	&-day {
		display: block;
		font-size: 10px;
		line-height: 2;
		font-weight: 500;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $colorGray; }
	&-col {
		width: 14.2857%;
		padding: 24px;
		border-radius: $borderRadius;
		&.active {
			border: 2px solid $colorAccent;
			.calendar-num {
				color: $colorAccent; } }
		&.calendar-nextMonth {
			.calendar-num {
				color: $colorGray; } } }
	&-num {
		display: flex;
		flex-flow: row nowrap;
		align-items: top;
		font-size: 24px;
		line-height: 1.333;
		font-weight: 300;
		padding-bottom: 16px;
		.calendar-day {
			display: none;
			padding-left: 18px;
			margin-top: -3px; } }
	&-info {
		font-size: 12px;
		line-height: 20px;
		padding-bottom: 16px;
		a {
			color: #3F4D64; }
		.svg {
			display: inline-block;
			height: 10px;
			width: 12px;
			position: relative;
			margin-left: 4px;
			svg {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0,-50%); } } }
	&-btn,.fc-more {
		display: inline-block;
		font-size: 10px;
		line-height: 1.6;
		font-weight: 500;
		color: $colorGray;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		padding: 3px 13px;
		border: 1px solid #CCD3DC;
		border-radius: 16px;
		margin-top: 12px;
		&:hover {
			background: #CCD3DC;
			color: #fff; } }
	.fc-more-cell {
		padding: 0 24px;
		.fc-more {
			margin-bottom: 5px; } }
	&-wrap {
		display: none; }
	&-mob {
		display: none;
		font-size: 12px;
		line-height: 16px;
		color: #3F4D64;
		a {
			color: #3F4D64; } }
	&-quantity {
		display: inline-block;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
		padding: 0 8px;
		text-align: center;
		background: $colorAccent;
		border-radius: 10px;
		margin-bottom: 8px;
		a {
			color: #fff; } } }

@media only screen and (max-width : 1020px) {
	.calendar {
		&-info,&-btn {
			display: none; }
		&-mob {
			display: block; } } }

@media only screen and (max-width : 768px) {
	.calendar {
		&-top {
			padding-bottom: 24px; }
		&-title {
			font-size: 20px;
			line-height: 1.6; }
		&-prev,&-next {
			svg {
				max-height: 22px; } }
		&-day {
			font-size: 9px;
			line-height: 16px; }
		&-row {
			min-height: 156px;
			padding: 12px 0;
			&__top {
				min-height: auto;
				.calendar-col {
					padding: 16px 24px; } } }
		&-col {
			padding: 12px 24px; } } }

@media only screen and (max-width : 600px) {
	.calendar {
		&-row {
			min-height: auto;
			border: none;
			padding: 0;
			&__top {
				display: none; } }
		&-col {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 24px 0;
			border-top: 1px solid #E3E6EF;
			&.active {
				border: none; } }
		&-num {
			line-height: 1;
			padding: 0;
			.calendar-day {
				display: inline-block; } }
		&-mob {
			display: flex;
			flex-flow: row nowrap;
			align-items: center; }
		&-quantity {
			order: 20;
			margin: 0 0 0 16px; } } }
