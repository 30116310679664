.new-consultation {
    margin: 120px 0;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin: 80px 0;
    }
    @media screen and (max-width: 767px) {
        margin: 60px 0;
    }

    &__form {
        width: 100%;
    }
    &__form-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
    &__form-item {
        flex: 1 1 auto;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &__form-item + &__form-item {
        margin-left: 24px;

        @media screen and (max-width: 767px) {
            margin-top: 16px;
            margin-left: 0;
        }
    }
    &__btn {
        margin-top: 48px;
        .main-btn {
            min-width: 237px;
        }
        
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
}