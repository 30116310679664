@import "vars";

.interesting {
	background: #fff;
	box-shadow: 0px 25px 40px rgba(150, 162, 183, 0.15);
	border-radius: $borderRadius;
	padding: 24px 24px 24px 40px;
	position: relative;
	input {
		display: block;
		width: 100%;
		height: 48px;
		border: none;
		font-size: 16px;
		padding-right: 120px; }
	&-btn {
		position: absolute;
		top: 50%;
		right: 24px;
		transform: translate(0,-50%);
		display: inline-block;
		padding: 0 25px;
		font-size: 16px;
		line-height: 48px;
		color: $colorMain;
		background: #F2F5FB;
		border-radius: $borderRadius;
		text-align: center;
		&:hover {
			background: #EBEEF5; } }
	&.interesting-second {
		margin-bottom: 80px;
		input {
			padding-right: 160px; }
		.interesting-btn {
			background: $colorMain;
			color: #fff;
			&:hover {
				background: #475771; } } } }

@media only screen and (max-width : 768px) {
	.interesting {
		padding: 20px;
		input {
			height: 40px; }
		&-btn {
			font-size: 14px;
			line-height: 40px; }
		&.interesting-second {
			margin-bottom: 64px; } } }

@media only screen and (max-width : 600px) {
	.interesting {
		padding: 8px 8px 8px 24px;
		input {
			font-size: 14px;
			padding-right: 90px; }
		&-btn {
			right: 8px;
			padding: 0 16px; }
		&.interesting-second {
			margin-bottom: 32px;
			input {
				padding-right: 120px; } } } }
