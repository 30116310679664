.new-main {
    position: relative;
    z-index: 2;
    padding-top: 22px;
    &::before {
        content: '';
        position: absolute;
        top: -220px;
        left: calc(50% - 298px);
        z-index: -1;
        transform: translate(-50%, 0);
        display: block;
        width: 974px;
        height: 783px;
        background-image: url(../img/new-main-bg.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        &::before {
            top: -190px;
            left: calc(50% - 70px);
        }
    }
    @media screen and (max-width: 767px) {
        &::before {
            top: -100px;
            left: calc(50% - 80px);
            width: 628px;
            height: 505px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }
    &__body {
        max-width: 710px;
        width: 100%;
        position: relative;
        z-index: 9999;
    }
    &__heading {
        font-size: 36px;
        font-weight: 600;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 1.4;
        }
    }
    &__description {
        margin-top: 32px;
        font-size: 20px;
        font-weight: 500;

        @media screen and (max-width: 767px) {
            margin-top: 20px;
            font-size: 16px;
        }
    }
    &__btn {
        margin-top: 50px;
        
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
    &__mobile {
        display: none;
        position: relative;
        @media screen and (max-width: 767px) {
            display: block;
            min-width: 350px;
            max-width: 350px;
            height: 698px;
            margin-top: 40px;
            margin-bottom: -140px;
            &::before {
                content: '';
                z-index: 2;
                position: absolute;
                display: block;
                min-width: 350px;
                max-width: 350px;
                height: 698px;
                border: none;
                background-image: url("/img/main/phone.png");
                background-position: center;
                background-size: cover;
            }
            &::after {
                position: absolute;
                content: '';
                z-index: 999;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                pointer-events: none;
            }
            .mobile_video {
                width: 306px;
                height: 100%;
                max-height: 560px;
                position: relative;
                display: block;
                margin: 0 auto;
                overflow: hidden;
            }
            video {
                width: 100%;
                height: auto;
                overflow: hidden;
                margin-top: 13px;
            }
            img {
                margin-top: 13px;
                display: block;
                width: 100%;
                height: 100%!important;
                object-fit: cover;
            }
        }
    }
    &__img {
        display: block;
        overflow: hidden;
        margin-right: -205px;
        min-width: 726px;
        width: 726px;
        height: 418px;
        border: 10px solid #5EB5FE;
        border-radius: 33px;
        position: relative;
        @media screen and (max-width: 767px) {
            display: none;
        }
        img {
            display: block;
            width: 100%;
            height: 100%!important;
            object-fit: cover;
        }
        video {
            width: 100%;
            height: auto;
            border-radius: 20px;
            overflow: hidden;
            margin-top: -1px;
        }
        &::before {
            position: absolute;
            content: '';
            z-index: 999;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
        }

        @media screen and (max-width: 1699px) {
            margin-right: calc((50% - 50vw) - 95px);
        }
        @media screen and (max-width: 1023px) {
            margin: 30px auto 0;
        }
        @media screen and (max-width: 767px) {
            min-width: 486px;
            width: 486px;
            height: 317px;
        }
    }
}