@import "vars";

.video {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	margin-bottom: 116px;
	&-overlay,iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }
	&-overlay {
		display: flex;
		align-items: center;
		justify-content: center;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		cursor: pointer;
		z-index: 100; }
	iframe {
		border-width: 0;
		outline-width: 0; }
	span {
		display: none; }
	&.active .video-overlay {
		opacity: 0;
		visibility: hidden; } }

@media only screen and (max-width : 768px) {
	.video {
		margin: 0 0 48px; } }

@media only screen and (max-width : 700px) {
	.video {
		margin: 0 0 48px; } }

@media only screen and (max-width : 450px) {
	.video {
		margin-bottom: 32px; } }
