@import "vars";

.center-btn {
	text-align: center; }

.main-btn, .second-btn {
	display: inline-block;
	text-align: center;
	min-width: 172px;
	border-radius: $borderRadius;
	font-size: 16px;
	line-height: 20px;
	white-space: nowrap;
	position: relative;
	z-index: 2;
	transition: $transition;
	cursor: pointer;
	&.small {
		min-width: auto;
		svg {
			margin-right: 0!important; } }
	&.icon-btn {
		min-width: auto;
		line-height: 1px;
		&.stop-marathon {
			order: -1; }
		@media only screen and (min-width : 700px) {
			span {
				display: none; }
			svg {
				margin-right: 0!important; }
			margin: 0 5px; }
		&.abuse {
			&:hover {
				background: $colorRed; }
			&:after {
				display: none; }
			&:hover {
				svg {
					fill: #ffffff; } } } } }
.link {
	color: #3f4d64;
	&:hover {
		color: $colorAccent; }
	&-blue {
		color: $colorAccent!important;
		&:hover {
			color: #96a2b7!important; } } }
.main-btn {
	padding: 14px 24px;
	background: $colorAccent;
	color: #fff;
	&:disabled {
		opacity: .5; }
	&:hover {
		background: #6CBCFF; }
	&-gray {
		background: $colorMain;
		color: #fff;
		&:hover {
			background: #475771; } }
	&-red {
		background: $colorRedLight;
		color: #fff;
		&:hover {
			background: $colorRed; } }
	&-big {
		display: block;
		width: 100%; }
	&-full-width {
		@media only screen and (max-width : 768px) {
			width: 100%;
			max-width: 100%!important; } } }

.second-btn {
	padding: 13px 24px;
	border: 1px solid #CCD3DC;
	color: $colorGray;
	position: relative;
	overflow: hidden;
	&.abuse {
		border-color: transparent;
		svg {
			fill: #a0a6b2; } }
	&-small {
		min-width: auto;
		margin-left: 14px;
		padding: 3px 11px;
		border-radius: 16px;
		font-size: 10px;
		line-height: 1.6;
		font-weight: 500;
		color: $colorMain;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		min-width: 90px; }
	&-main {
		padding: 12px 23px!important;
		border: 2px solid $colorAccent; }
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		background: none repeat scroll 0 0 #e6e9f1;
		width: 0;
		height: 0;
		border-radius: 50%;
		opacity: .5;
		transform: translateX(-50%) translateY(-50%);
		z-index: -1;
		transition: $transition; }
	&:hover {
		border-color: #e6e9f1;
		color: $colorMain;
		&::after {
			height: 420%;
			width: 110%;
			opacity: 1; } }
	&-f {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		color: $colorMain;
		svg {
			margin-right: 16px; } }
	&-big {
		display: block;
		width: 100%; } }

.estimate {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 14px 24px;
	background: $colorMain;
	border-radius: $borderRadius;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	svg {
		margin-right: 16px; } }
.back_icon {
	position: relative;
	top: 2px;
	margin-right: 5px; }
.main-btn-flex {
	display: flex;
	.main-btn {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		svg {
			margin-right: 16px; } } }

.btn-block {
	max-width: 600px;
	margin: 80px auto 0;
	.main-btn,.second-btn {
		display: block;
		width: 100%;
		margin-top: 24px; } }

@media only screen and (max-width : 768px) {
	.btn-block {
		max-width: 100%;
		margin-top: 40px;
		.main-btn,.second-btn {
			margin-top: 20px; } } }

@media only screen and (max-width : 700px) {
	.main-btn {
		padding: 10px 24px; }
	.second-btn {
		padding: 9px 24px; }
	.estimate {
		padding: 10px 24px;
		font-size: 14px;
		line-height: 20px; } }

@media only screen and (max-width : 600px) {
	.center-btn {
		.second-btn {
			max-width: 320px;
			width: 100%; } }
	.btn-block {
		.main-btn,.second-btn {
			margin-top: 8px; } } }

@media only screen and (max-width : 450px) {
	.main-btn-flex {
		display: block; } }


