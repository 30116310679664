@import "vars";

.profile {
	border: 1px solid #E3E6EF;
	border-radius: $borderRadius;
	padding: 40px 40px 40px 400px;
	position: relative;
	margin-bottom: 76px;
	&-user {
		position: absolute;
		top: 40px;
		left: 104px; }
	&-img {
		position: relative;
		max-width: 96px;
		margin-bottom: 15px;
		background-size: cover;
		background-position: center;
		width: 90px;
		height: 90px;
		border-radius: 100%;
		img {
			display: block;
			border-radius: 50%; }
		span {
			position: absolute;
			top: 8px;
			right: 0;
			display: inline-block;
			padding: 5px;
			background: #65C58C;
			border-radius: 50%;
			font-size: 9px;
			line-height: 16px;
			font-weight: bold;
			color: #fff;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			img {
				width: 15px;
				height: auto; } } }
	&-name {
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		color: $colorMain;
		max-width: 170px; }
	&-col {
		padding: 0 10px 36px;
		&.subscription-col {
			@media only screen and (max-width : 450px) {
				flex-direction: column;
				justify-content: start;
				text-align: left;
				align-items: start;
				p {
					margin: 0 0 5px;
					padding: 0; }
				.second-btn {
					margin-top: 20px; } } }
		&__subtitle {
			display: block;
			padding-bottom: 8px;
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray; }
		p {
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 20px;
			.second-btn {
				min-width: auto;
				margin-left: 14px;
				padding: 3px 11px;
				border-radius: 16px;
				font-size: 10px;
				line-height: 1.6;
				font-weight: 500;
				color: $colorMain;
				letter-spacing: 0.05em;
				text-transform: uppercase; } } }
	&-btn {
		@media only screen and (max-width : 450px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.second-btn+.second-btn {
				margin-top: 10px; } } }
	&-info {
		margin: 0 -10px 43px; }
	&-top {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		max-width: 626px;
		&__main {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			max-width: 274px;
			width: 100%; } }
	.second-btn {
		color: $colorMain; } }

@media only screen and (max-width : 1020px) {
	.profile {
		padding: 64px 40px 64px 275px;
		&-user {
			top: 64px;
			left: 60px; } } }

@media only screen and (max-width : 768px) {
	.profile {
		margin-bottom: 64px;
		&-top {
			display: block; }
		&-info {
			margin-bottom: 16px; }
		&-col {
			padding: 0 10px 32px; } } }

@media only screen and (max-width : 600px) {
	.profile {
		border: none;
		border-top: 1px solid #E3E6EF;
		border-bottom: 1px solid #E3E6EF;
		padding: 24px 40px 32px;
		margin: 0 -20px 48px;
		&-user {
			position: static;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			padding-bottom: 32px; }
		&-img {
			margin: 0 24px 0 0; } } }

@media only screen and (max-width : 450px) {
	.profile {
		padding: 24px 20px 32px;
		&-user {
			padding-bottom: 16px; }
		&-img {
			img {
				max-width: 72px; } }
		&-name {
			font-size: 16px;
			line-height: 24px; }
		&-info {
			margin: 0 0 16px; }
		&-top {
			&__main {
				display: block;
				max-width: 100%; } }
		&-col {
			display: flex;
			flex-flow: row nowrap;
			align-items: end;
			justify-content: space-between;
			padding: 16px 0;
			border-bottom: 1px solid #E3E6EF;
			&__subtitle {
				padding: 0 8px 0 0; }
			p {
				display: block;
				font-size: 12px;
				text-align: right;
				.second-btn {
					display: block;
					margin: 8px 0 0; } }
			&:last-child {
				border-bottom: none; } }
		&-btn {
			.second-btn {
				display: block; } } } }

.profile_tabs {
	display: flex;
	h3 {
		padding: 10px;
		margin-bottom: 48px;
		border-bottom: 2px solid transparent;
		transition: $transition;
		&:hover {
			border-color: $colorGray; }
		a {
			color: $colorMain; }
		&.active {
			border-color: $colorAccent; } }
	h3:last-child {
		margin-left: 10px;
		padding-left: 10px; } }
.organized_profile {
	color: #ffffff;
	.profile {
		border-color: #48566e;
		@media only screen and (min-width : 768px) {
			min-height: 430px; }
		&-img {
			@media only screen and (max-width : 768px) {
				margin: 0; } }
		&-col {
			padding-bottom: 10px; }
		&-user {
			@media only screen and (max-width : 768px) {
				flex-direction: column;
				position: relative;
				top: 0;
				left: auto; } } }
	.organized_badge {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: center;
		padding: 1px 8px;
		width: 90px;
		height: 16px;
		background: #977CCF;
		border-radius: 8px;
		margin: 16px 0px;
		font-weight: bold;
		font-size: 9px;
		line-height: 14px;
		letter-spacing: 0.05em;
		text-transform: uppercase; }
	.profile-col__subtitle {
		color: $colorGray;
		min-width: 210px; }
	.profile-col__subtitle, p {
		font-size: 16px;
		line-height: 24px; }
	.profile-col {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		@media only screen and (max-width : 768px) {
			border-color: #48566e; } }
	.post-info__bottom {
		@media only screen and (max-width : 768px) {
			border-color: #55657f; } }
	.personalArea-list {
		@media only screen and (max-width : 768px) {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				box-sizing: border-box;
				&:last-child {
					width: 100%; } } } }
	.video {
		//padding-bottom: 0
		margin-bottom: 0; }
	.profile_description {
		margin-bottom: 0;
		padding-bottom: 0;
		word-break: break-word;
		display: flex;
		flex-direction: column;
		p {
			display: initial;
			padding: 0;
			margin-bottom: 15px;
			width: 100%;
			display: block;
			a {
				color: $colorAccent; } }
		ul {
			margin-bottom: 15px;
			li {
				list-style: disc;
				line-height: 24px;
				margin-left: 15px;
				a {
					color: $colorAccent; } }
			@media only screen and (max-width : 768px) {
				text-align: left; } }
		@media only screen and (max-width : 768px) {
			flex-direction: column;
			.profile-col__subtitle {
				margin-bottom: 20px; } } }
	.profile-name {
		color: #ffffff; }
	.personalArea-list li a.active {
		color: #ffffff; }
	.article-wrap {
		background-color: #48566E;
		&:hover {
			box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.15); }
		.article-img__wrap {
			background-color: #425067; } }
	.profile-info {
		&:last-child {
			margin-bottom: 0; } }
	.post-title a,
	.post-info__bottom ul li span {
		color: #ffffff; }
	.post-info__bottom ul li {
		svg, svg path {
			fill: #96A2B7; } }
	.slick-dots {
		li {
			&.slick-active {
				border-color: rgba(255, 255, 255, 0.2);
				&:after {
					background: $colorAccent; } }
			&:hover {
				border-color: rgba(255,255,255,.2);
				background: transparent;
				&:after {
					background: #ffffff; } } } }
	.main-btn-gray {
		background: #ffffff;
		color: $colorMain;
		svg, svg path {
			fill: $colorMain; }
		@media only screen and (max-width : 768px) {
			margin-top: 10px; }
		&:hover {
			background: $colorAccent;
			color: #ffffff;
			svg, svg path {
				fill: #ffffff; } } }
	@media only screen and (max-width : 1360px) {
		.slick-prev {
			left: -20px;
			right: auto; }
		.slick-next {
			right: -20px;
			left: auto; }
		.slider-all.slick-slider .slick-arrow {
			top: 90px;
			svg, svg path {
				fill: $colorGray; }
			&:hover {
				svg, svg path {
					fill: #ffffff; } } } } }
.announcements_block {
	&_item {
		position: relative;
		margin-bottom: 40px;
		&.exists {
			@media only screen and (min-width : 768px) {
				.banner_content {
					display: flex;
					align-items: center;
					.input-block.url {
						width: 100%;
						margin-left: 20px; } } } }
		.users-table__close {
			z-index: 4;
			top: 0;
			right: 0; } }
	.input-block {
		margin-bottom: 10px; } }
.download-img {
	&.banner-img {
		width: 192px; } }
.download-photo {
	position: relative;
	#upload-avatar {
		width: 100%;
		height: 100%;
		z-index: 2; } }

.user_avatar {
	border-radius: 50%;
	background-position: center;
	overflow: hidden;
	width: 40px;
	height: 40px;
	background-size: cover;
	&.no-radius {
		border-radius: 0!important; } }
