@import "vars";

.input-block input, .input-block textarea, .input-text, .select-text {
	display: block;
	width: 100%;
	height: 48px;
	background: #fff;
	border-radius: $borderRadius;
	border: none;
	padding: 0 24px;
	font-size: 14px;
	z-index: 1;
	&:disabled {
		opacity: 0.5; }
	&[type="file"] {
		height: 100%; } }

.input-block {
	label {
		display: inline-block;
		font-size: 16px;
		line-height: 30px;
		cursor: pointer;
		margin-bottom: 16px; }
	textarea {
		height: 192px!important;
		resize: none;
		padding: 15px 24px;
		&:disabled {
			color: $colorGray; } }
	&__wrap {
		position: relative;
		z-index: auto;
		input {
			width: 100%;
			padding: 0 50px 0 24px; } }
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		right: 24px;
		transform: translate(0,-50%);
		cursor: pointer; }
	.input-hint {
		font-family: $HelveticaNeueCyr;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		padding-top: 12px; }
	&.input-block--big {
		display: flex;
		flex-flow: row nowrap;
		align-items: end;
		input {
			width: 80px;
			min-width: 80px;
			height: 48px;
			padding: 0 20px; }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray;
			padding-left: 24px; } }
	&-big {
		display: flex;
		flex-flow: row nowrap;
		span {
			display: inline-block;
			min-width: 24px;
			text-align: center;
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 48px;
			color: $colorGray;
			&.input-block__text {
				margin-left: 24px; } }
		input {
			width: 80px;
			padding: 0 20px; }
		.input-block {
			&__wrap {
				input {
					width: 120px;
					padding-right: 40px; } }
			&__btn {
				font-size: 14px;
				line-height: 24px;
				font-weight: normal; } }
		&-n {
			display: block;
			.input-block__wrap {
				width: 120px; } } }
	&.input-block-small {
		textarea {
			height: 96px!important; } } }

.select {
	position: relative;
	z-index: 10;
	&-text {
		height: auto;
		padding: 14px 40px 14px 24px;
		font-size: 14px;
		line-height: 20px;
		color: $colorMain;
		position: relative;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 22px;
			transform: translate(0,-50%);
			border-top: 6px solid $colorMain;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			transition: $transition; } }
	&-info {
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: 4px;
		width: 100%;
		background: #fff;
		box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
		border-radius: $borderRadius;
		overflow: hidden;
		z-index: 11;
		opacity: 0;
		visibility: hidden;
		transition: $transition;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 300px;
		li {
			padding: 14px 24px;
			font-size: 14px;
			line-height: 20px;
			cursor: pointer;
			transition: $transition;
			&:hover {
				background: #F7FAFF;
				color: $colorAccent; }
			&.active {
				background: #F7FAFF;
				color: $colorAccent; } }
		&.nano {
			position: absolute;
			min-height: 95px;
			max-height: 240px; } }
	input {
		display: none;
		opacity: 0;
		visibility: hidden; }
	&.active {
		z-index: 12;
		.select {
			&-text {
				&::after {
					transform: translate(0,-50%) rotate(180deg);
					border-top-color: $colorGray; } }
			&-info {
				opacity: 1;
				visibility: visible; } } } }

.input-drop {
	position: relative;
	.select-info {
		height: 208px;
		padding: 8px 0;
		&.courators {
			height: 70px; }
		a {
			color: $colorMain; }
		.user_avatar {
			display: flex;
			flex-flow: row nowrap;
			align-items: center; }
		li {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			padding: 16px 24px;
			img {
				max-width: 32px;
				border-radius: 50%;
				margin-left: 8px; } } }
	.small_image {
		width: 80px;
		height: 60px;
		border-radius: 6px;
		background-size: cover;
		background-position: center;
		overflow: hidden;
		&.avatar {
			border-radius: 100%;
			width: 32px;
			height: 32px;
			margin-right: 10px; } }
	&.has-items {
		.select-info {
			height: 150px; } }
	&.active, &:focus-within.has-items, &:focus-within.has-item {
		.select-info {
			opacity: 1;
			visibility: visible; } } }
.multiple {
	margin-top: 20px;
	&:first-child {
		margin-top: 0; } }
.remove-row {
	svg {
		transform: rotateY(0deg) rotate(45deg); } }
.inputAdd {
	&-list {
		display: flex;
		flex-flow: row wrap;
		padding: 8px 0 0;
		li {
			display: inline-block;
			padding: 4px 28px 4px 12px;
			font-size: 12px;
			line-height: 16px;
			color: $colorGray;
			background: #E6E9F1;
			border-radius: 12px;
			position: relative;
			margin: 8px 8px 0 0;
			transition: $transition;
			span {
				position: absolute;
				top: 50%;
				right: 12px;
				transform: translate(0,-50%);
				cursor: pointer; } } }
	&-btn {
		position: absolute;
		top: 50%;
		right: 24px;
		transform: translate(0,-50%);
		height: 18px;
		cursor: pointer; }
	&-input {
		&.active {
			.select-info {
				opacity: 1;
				visibility: visible; } } } }

.users {
	&-table {
		padding-bottom: 56px;
		&.empty-table {
			display: none;
			padding-bottom: 0; }
		&__row {
			padding: 24px 104px;
			box-shadow: rgba(0, 0, 0, 0.05) 0 1px 1px;
			&.coupon_row {
				padding-left: 54px;
				padding-right: 84px;
				.coupon-block {
					margin: 0 5px;
					input {
						border: 1px solid transparent; }
					&.input-block--error {
						input {
							border: 1px solid #ff655b; } } } }
			border-radius: $borderRadius;
			position: relative;
			/* !!! for test only !!! */
			border: 1px solid rgba(204, 211, 220, 0.2);
			background: #EBFBF9;
			&__empty {
				background: #fff8e2 !important;
				text-align: center;
				padding: 20px !important;
				position: relative;
				max-width: 600px;
				margin: 20px auto;
				font-size: 14px;
				line-height: 24px;
				color: #9f9a8a; }
			&:nth-child(odd) {
				background: #FAFAFF; } }
		&__num {
			position: absolute;
			top: 50%;
			left: 24px;
			transform: translate(0,-50%);
			font-size: 14px;
			line-height: 25px; }
		&__info {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			img {
				display: block;
				max-width: 40px;
				border-radius: 50%;
				margin-right: 24px; } }
		&__coauthor {
			width: 100%; }
		&__coauthor_block {
			@media only screen and (min-width : 450px) {
				display: flex;
				flex-flow: row nowrap;
				align-items: center; } }
		&__name {
			font-size: 14px;
			line-height: 24px;
			font-weight: normal;
			a {
				color: $colorMain; } }
		&_user_payment_input {
			padding: 0 10px 0 24px!important;
			height: 30px!important;
			font-size: 12px!important; }
		&_user_payment {
			max-width: 160px;

			@media only screen and (max-width : 450px) {
				margin-left: 0;
				margin-bottom: 0!important;
				margin-top: 15px;
				padding: 0!important;
				max-width: 100%; } }
		&__close,&__btn {
			position: absolute;
			top: 50%;
			transform: translate(0,-50%);
			width: 32px;
			height: 32px;
			cursor: pointer;
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%); } }
		&__close {
			right: 24px; }
		&__btn {
			right: 64px;
			border-radius: 50%;
			border: 1px solid #CCD3DC; } } }

.accounts {
	&-list {
		display: flex;
		flex-flow: row wrap;
		li {
			margin: 0 16px 16px 0;
			a {
				display: inline-block;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				overflow: hidden;
				position: relative;
				svg {
					width: 100%;
					height: 100%; }
				&::after {
					content: '+';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					font-family: $Rubik;
					font-size: 28px;
					line-height: 48px;
					font-weight: normal;
					color: #fff;
					text-align: center;
					background: rgba($colorMain, 0.75);
					opacity: 0;
					visibility: hidden;
					transition: $transition; }
				&:hover {
					&::after {
						opacity: 1;
						visibility: visible; } } } } } }

.text-error {
	display: none;
	font-family: $HelveticaNeueCyr;
	font-size: 12px;
	line-height: 20px;
	color: #FF655B;
	padding-top: 16px; }

.invalid-feedback.text-error {
    display: block; }

.validate_url {
	.text-error {
		margin-left: 40px; } }
.input-block--error {
	input {
		color: #FF655B; }
	.text-error {
		display: block; }
	.block-with_icon {
		svg {
			path {
				fill: $colorRed!important; } } } }

.textarea {
	&-descr {
		position: relative;
		textarea:not(.short-description) {
			padding-top: 60px; }
		&__top {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 40px;
			border-bottom: 1px solid #EBEFF8;
			.select {
				width: 100%;
				&-text {
					height: 40px;
					padding: 10px 24px;
					border: none;
					border-radius: 0;
					background: transparent; } } }
		&__list {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			min-width: 128px;
			height: 40px;
			padding: 0;
			border-left: 1px solid #EBEFF8;
			li, button {
				width: 24px;
				height: 24px;
				border-radius: 2px;
				cursor: pointer;
				position: relative;
				transition: $transition;
				margin: 0 2px;
				svg {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					path {
						transition: $transition; } }
				&:hover {
					background: #EAF1FC;
					svg {
						path {
							fill: $colorAccent; } } }
				&.active {
					background: #EAF1FC; } } }
		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 72px;
			height: 40px;
			border-left: 1px solid #EBEFF8;
			svg {
				display: block; } } } }
.note-toolbar {
	background-color: white; }

@media only screen and (max-width : 450px) {
	.input-text, .input-block input {
		height: 40px;
		padding: 0 20px;
		font-size: 12px; }
	.input-block {
		textarea {
			height: 144px!important;
			padding: 20px; }
		label {
			font-size: 14px;
			line-height: 20px; } }
	.select {
		&-text,&-list li {
			padding: 10px 20px;
			font-size: 12px;
			line-height: 20px; }
		&-info.nano {
			height: 120px; } }
	.accounts-list {
		li {
			a {
				width: 40px;
				height: 40px;
				&::after {
					line-height: 40px; } } } }
	.users {
		&-table {
			margin: 0 -20px;
			&.empty-table {
				padding-bottom: 0; }
			&__row {
				padding: 20px 95px 20px 48px; }
			&__num {
				left: 20px;
				font-size: 12px;
				line-height: 20px; }
			&__info {
				img {
					max-width: 32px;
					margin-right: 16px; } }
			&__name {
				font-size: 12px;
				line-height: 20px; }
			&__close {
				right: 20px; }
			&__btn {
				right: 60px; } } }
	.input-block__wrap input {
		padding-right: 40px; }
	.inputAdd-btn {
		right: 20px; } }
.marathons-results {
	.nano-content {
		position: relative; } }
