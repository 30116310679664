@import "vars";

.redaction {
	padding-bottom: 80px;
	.page-title {
		text-align: center; }
	&-wrap {
		max-width: 600px;
		margin: 0 auto; }
	&-title {
		display: block;
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		padding-bottom: 48px; }
	&-subtitle {
		display: block;
		font-size: 16px;
		line-height: 30px;
		font-weight: normal;
		padding-bottom: 16px; }
	&-block {
		padding-bottom: 48px;
		&__big {
			padding-bottom: 80px; } }
	&-row {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -12px 32px; }
	&-col {
		width: 50%;
		padding: 0 12px 48px; }
	.checkbox {
		display: block;
		margin-bottom: 27px;
		p {
			font-family: $Rubik;
			font-size: 14px; } }
	&-accounts {
		.redaction-row {
			margin: 0 -12px 14px; } }
	.main-btn,.second-btn {
		display: block;
		width: 100%; } }

@media only screen and (max-width : 768px) {
	.redaction {
		padding-bottom: 64px;
		&-wrap {
			max-width: 100%; }
		&-title {
			padding-bottom: 40px; }
		&-block {
			padding-bottom: 40px; }
		&-row {
			margin-bottom: 8px; }
		&-col {
			padding-bottom: 40px; } } }

@media only screen and (max-width : 450px) {
	.redaction {
		padding-bottom: 40px;
		&-title {
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 24px; }
		&-subtitle {
			font-size: 14px;
			line-height: 20px;
			padding-bottom: 20px; }
		&-block {
			padding-bottom: 32px; }
		&-row {
			margin: 0 0 8px; }
		&-col {
			width: 100%;
			padding: 0 0 32px; }
		&-accounts {
			.redaction {
				&-row {
					margin: 0; }
				&-col {
					padding-bottom: 16px; } } }
		.checkbox {
			margin-bottom: 16px;
			p {
				font-size: 12px; } } } }
