@import "fonts";
@import "libs";
@import "vars";

*::-webkit-input-placeholder {
	color: $colorGray; }

*:-moz-placeholder {
	color: $colorGray; }

*::-moz-placeholder {
	color: $colorGray; }

*:-ms-input-placeholder {
	color: $colorGray; }

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a, a:hover, a:focus, a:active, a:visited {
	text-decoration: none;
	transition: $transition; }

li {
	list-style: none; }

html {
	overflow-x: hidden; }

body {
	font-family: $Rubik;
	font-size: 16px;
	line-height: 1.3;
	color: $colorMain;
	background: #F2F5FB;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
 }	// overflow: hidden

img {
	max-width: 100%;
	height: auto!important; }

button,select,input,textarea {
	font-family: $Rubik; }

button {
	cursor: pointer;
	transition: $transition;
	border: none;
	background: transparent;
	appearance: none; }

input,textarea {
	appearance: none;
	box-shadow: none; }

.wrapper {
	max-width: 1296px;
	margin: 0 auto;
	padding: 0 36px; }

.forgot {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 3px 0 40px;
	&-link {
		font-size: 14px;
		line-height: 25px;
		color: $colorGray;
		&:hover {
			color: $colorAccent; } } }

.warnings {
	background: #FFF8E2;
	border-radius: $borderRadius;
	padding: 24px 24px 24px 72px;
	position: relative;
	max-width: 600px;
	margin: 0 auto 40px;
	svg {
		position: absolute;
		top: 24px;
		left: 24px; }
	p {
		font-size: 14px;
		line-height: 24px;
		color: #9F9A8A;
		a {
			color: $colorAccent; } } }

.br {
	height: 1px;
	background: #DDE1E9; }

@import "components/general/buttons";
@import "components/general/sliders";
@import "components/general/title";
@import "components/general/check-radio";
@import "components/general/input-select";
@import "components/general/video";
@import "components/general/pagination";
@import "components/general/breadcrumb";
@import "components/general/social";
@import "components/general/tooltip";
@import "components/general/modal";
@import "components/general/datepicker";
@import "components/general/filter";
@import "components/general/rating";
@import "components/header";
@import "components/footer";
@import "components/interesting";
@import "components/main";
@import "components/preview";
@import "components/registration";
@import "components/soon";
@import "components/post";
@import "components/404";
@import "components/terms";
@import "components/works";
@import "components/login";
@import "components/support";
@import "components/contacts";
@import "components/faq";
@import "components/faq-all";
@import "components/faq-answer";
@import "components/about";
@import "components/contactUs";
@import "components/premium";
@import "components/forum";
@import "components/reviews";
@import "components/marathon";
@import "components/participation";
@import "components/timeline";
@import "components/testimonials";
@import "components/involvement";
@import "components/member";
@import "components/testing";
@import "components/article";
@import "components/profile";
@import "components/personalArea";
@import "components/balance";
@import "components/history";
@import "components/calendar";
@import "components/redaction";
@import "components/step";
@import "components/cabinet";
@import "components/recording";
@import "components/chat";
@import "components/calendar-static";
@import "components/general/sitemap";
@import "components/_cabinet-new.sass";
@import "components/_custom.sass";
@import "components/_new.sass";
@import "components/_mobile.sass";
@import "components/_static_pages.sass";
@import "components/_tooltipster.sass";
@import "components/toast.sass";

// new
@import "components/section-top";
@import "components/new-main";
@import "components/courses";
@import "components/new-clients";
@import "components/new-advantages";
@import "components/new-partners";
@import "components/new-consultation";
@import "components/free-bonus";
@import "css/sweetalert2.min.css";

.ui-loader {
	display: none; }

.section {
	min-height: calc(100vh - 606px); }

.text-anim {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-size: 16px;
	line-height: 20px;
	color: $colorGray;
	svg {
		margin-left: 16px; } }

.text-green {
	color: #65C58C!important; }
.text-purple {
	color: #7E57C2!important; }
.text-red {
	color: #EF5350!important; }
.text-blue {
	color: #26C6DA!important; }
.text-yellow {
	color: #CDDC39!important; }
.text-brown {
	color: #8D6E63!important; }
.text-gray {
	color: #78909C!important; }
.text-orange {
	color: #FF7043!important; }
.text-graylight {
	color: #BDBDBD!important; }
.text-orange-2 {
	color: #F2994A!important; }
.text-red-2 {
	color: #FF655B!important; }
.text-blue-2 {
	color: $colorAccent!important; }
.text-stopped {
	color: #c7ce8e !important; }

.question {
	font-size: 14px;
	line-height: 20px;
	color: $colorMain;
	position: relative;
	z-index: 10; }
.cabinet-control__row {
	.creation {
		ul {
			width: 100%!important; } } }
.main-list {
	.creation {
		ul {
			width: 100%;
			@media only screen and (max-width : 450px) {
				text-align: center; } } } }
.creation {
	position: relative;
	z-index: 90;
	ul {
		position: relative;
		z-index: 9999;
		width: 100%; }
	&-large {
		display: inline-block;
		ul {
			width: auto!important; } }
	&:hover {
		ul {
			opacity: 1;
			visibility: visible; } } }
.header-top {
	.creation {
		z-index: 10; } }
.question,
.creation {
	&-toltip {
		display: inline-block;
		width: 24px;
		height: 24px;
		background: #E6E9F1;
		border-radius: 50%;
		text-align: center;
		font-size: 12px;
		line-height: 24px;
		font-weight: normal;
		color: $colorMain;
		margin-left: 6px;
		cursor: pointer;
		position: relative;
		&:hover {
			ul {
				opacity: 1;
				visibility: visible; } } }
	ul {
		position: absolute;
		z-index: 5;
		top: 100%;
		left: 0;
		width: 168px;
		background: #fff;
		box-shadow: 0px 10px 25px rgba(150, 162, 183, 0.3);
		border-radius: $borderRadius;
		overflow: hidden;
		margin-top: 8px;
		text-align: left;
		transition: $transition;
		opacity: 0;
		visibility: hidden;
		li {
			a {
				display: block;
				padding: 14px 24px;
				font-size: 14px;
				line-height: 20px;
				color: $colorMain;
				&:hover,&.active {
					background: #F7FAFF;
					color: $colorAccent; } } } } }
.tab {
	display: none;
	&.tab-active {
		display: block; } }

.prompt {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	z-index: 20;
	li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-size: 20px;
		line-height: 1.5;
		color: $colorGray;
		cursor: pointer;
		a {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			color: $colorGray; }
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			border: 1px solid #CCD3DC;
			border-radius: 50%;
			margin-right: 12px;
			position: relative;
			transition: $transition;
			i {
				position: absolute;
				top: -16px;
				left: 50%;
				transform: translate(-50%,-100%);
				display: block;
				width: 170px;
				text-align: center;
				background: $colorGray;
				border-radius: $borderRadius;
				box-shadow: 0px 5px 20px rgba(150, 162, 183, 0.25);
				font-size: 11px;
				line-height: 15px;
				font-style: normal;
				color: #fff;
				padding: 5px 8px;
				opacity: 0;
				visibility: hidden;
				transition: $transition;
				&::before,&::after {
					content: '';
					position: absolute;
					bottom: -5px;
					left: 50%;
					border-top: 5px solid $colorGray;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					margin-left: -5px; } } }
		b {
			font-weight: normal; }
		&:hover {
			span {
				border-color: #DDE1E9;
				background: #DDE1E9;
				i {
					opacity: 1;
					visibility: visible; } } } } }

@media only screen and (max-width : 1020px) {
	body {
		padding-top: 136px; } }

@media only screen and (max-width : 768px) {
	.warnings {
		max-width: 100%;
		margin-bottom: 32px; }
	.mobile-align-center {
		text-align: center; } }

@media only screen and (max-width : 700px) {
	body {
		padding-top: 97px; }
	.wrapper {
		padding: 0 20px; } }

@media only screen and (max-width : 450px) {
	.forgot {
		padding-bottom: 30px; }
	.warnings {
		margin-bottom: 24px; } }

@import "_media";
@import "_custom";
