@import "vars";

.footer {
	background: $colorMain;

	position: relative;
	z-index: 1;
	&-top {
		padding: 80px 0 60px;
		border-bottom: 1px solid #48566E; }
	&-row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin: 0 -10px; }
	&-nav {
		display: flex;
		flex-flow: row nowrap;
		width: 100%; }
	&-col {
		width: 30%;
		padding: 0 10px;
		ul {
			li {
				padding-bottom: 19px;
				a {
					font-size: 14px;
					line-height: 20px;
					color: #fff;
					&:hover {
						color: $colorAccent; } } } }
		&__last {
			min-width: 205px;
			width: 205px;
			.main-btn {
				margin-bottom: 16px; } } }
	&-bottom {
		padding: 40px 0;
		.wrapper {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between; } }
	&-social {
		display: flex;
		flex-flow: row nowrap;
		li {
			padding: 0 8px;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				&:hover {
					background: #48566E; } } } }
	&-info {
		padding-top: 2px;
		text-align: right;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 12px;
			line-height: 20px;
			color: $colorGray;
			padding-bottom: 10px; } }
	.main-btn,.second-btn {
		min-width: 185px; }
	.second-btn {
		color: #fff;
		border-color: #53627B;
		&::after {
			background-color: #48566E; }
		&:hover {
			border-color: #48566E;
			color: #fff; } } }

@media only screen and (max-width : 900px) {
	.footer {
		&-top {
			padding: 40px 0 20px; }
		&-nav {
			flex-flow: row wrap;
			.footer-col {
				width: 50%;
				&:last-child {
					width: 100%;
					padding: 0;
					ul {
						display: flex;
						flex-flow: row wrap;
						li {
							width: 50%;
							padding: 0 10px 20px; } } } } } } }

@media only screen and (max-width : 600px) {
	.footer {
		&-top {
			padding: 40px 0; }
		&-row {
			display: block; }
		&-nav {
			padding-bottom: 20px; }
		&-col {
			&__last {
				min-width: auto;
				width: 100%;
				text-align: center;
				.main-btn, .second-btn {
					max-width: 320px;
					width: 100%; }
				.main-btn {
					margin-bottom: 8px; } } }
		&-bottom {
			padding: 32px 0 35px;
			.wrapper {
				display: block;
				text-align: center; } }
		&-social {
			justify-content: center;
			padding: 10px 0 10px; }
		&-info {
			text-align: center;
			text-align: center; } } }

@media only screen and (max-width : 450px) {
	.footer {
		text-align: center;
		&-nav {
			.footer-col {
				width: 100%;
				ul {
					li {
						padding-bottom: 16px; } }
				&:last-child {
					padding: 0 10px;
					ul {
						display: block;
						li {
							width: 100%;
							padding: 0 0 16px; } } } } } } }

