.new-advantages {
    position: relative;
    margin-top: 120px;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 80px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 60px;
    }
    
    &__decor {
        position: absolute;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        &--sm {
            top: 470px;
            left: -57px;
            width: 128px;
            height: 98px;
            background-image: url(../img/icon-decor-sm.svg);
        }
        &--md {
            top: 910px;
            right: -262px;
            width: 494px;
            height: 378px;
            background-image: url(../img/icon-decor-md.svg);
        }

        @media screen and (min-width: 1200px) and (max-width: 1360px) {
            &--md {
                right: -360px;
            }
        }
        @media screen and (max-width: 1199px) {
            display: none;
        }
    }
    &__wrapper {
        margin: 0 auto;
        max-width: 1042px;
        width: 100%;
        counter-reset: new-advantages-counter;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 1024px) {
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .new-advantages {
                    &__body {
                        margin-right: 0;
                        margin-left: 40px;
                    }
                }
            }
        }
        @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: start;
        }
    }
    &__item + &__item {
        margin-top: 80px;
        padding-top: 80px;
        border-top: 1px solid #E3E6EF;
        
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-top: 60px;
            padding-top: 60px;
        }
        @media screen and (max-width: 767px) {
            margin-top: 40px;
            padding-top: 40px;
        }
    }
    &__body {
        position: relative;
        margin-right: 40px;
        max-width: 392px;
        width: 100%;
        &::before {
            counter-increment: new-advantages-counter;
            content: counter(new-advantages-counter) '.';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            font-size: 48px;
            font-weight: 300;
            color: #5EB5FE;
        }

        @media screen and (max-width: 1023px) {
            margin-right: 0;
            max-width: none;
        }
        @media screen and (max-width: 767px) {
            &::before {
                font-size: 32px;
            }
        }
    }
    &__title {
        padding-right: 42px;
        font-size: 30px;
        font-weight: normal;
        line-height: 1.33;
        color: #3F4D64;

        @media screen and (max-width: 767px) {
            padding-right: 30px;
            font-size: 20px;
        }
    }
    &__description {
        margin-top: 32px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        color: #3F4D64;
        
        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }
    &__img {
        display: block;
        overflow: hidden;
        min-width: 420px;
        width: 420px;
        height: 274px;
        border: 7px solid #5EB5FE;
        border-radius: 33px;
        img {
            display: block;
            width: 100%;
            height: 100%!important;
            object-fit: cover;
        }
        
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin: 40px auto 0;
        }
        @media screen and (max-width: 1023px) {
            margin: 20px auto 0;
        }
    }
    &__picture {
        display: block;
        img {
            display: block;
            object-fit: contain;
        }
        
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin: 40px auto 0;
        }
        @media screen and (max-width: 1023px) {
            margin: 20px auto 0;
        }
    }
}