@import "vars";

.premium {
	padding-bottom: 70px;
	background: $colorMain;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: -400px;
		left: 0;
		width: 100%;
		height: 400px;
		background: $colorMain; }
	.page-title {
		color: #fff;
		text-align: center; }
	&-row {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -12px; }
	&-col {
		width: 33.333%;
		padding: 0 12px 8px;
		text-align: center;
		&__wrap {
			padding: 40px 20px;
			background: #48566E;
			border-radius: $borderRadius; }
		.main-btn {
			min-width: auto; } }
	&-time {
		display: block;
		font-size: 20px;
		line-height: 1.6;
		color: #fff;
		padding-bottom: 40px; }
	&-price {
		display: block;
		font-size: 48px;
		line-height: 1;
		font-weight: 300;
		color: #fff;
		padding-bottom: 24px; }
	&-name {
		display: block;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		padding-bottom: 32px; } }
.premium_more {
	background: #5eb5fe;
	color: #ffffff;
	font-size: 14px;
	border-radius: 6px;
	padding: 15px;
	text-align: center;
	.second-btn {
		padding: 10px;
		font-size: 14px;
		min-width: auto;
		display: block;
		background: #ffff;
		margin-top: 20px; }
	img {
		display: block;
		max-width: 60px;
		margin: 0 auto 10px; } }

@media only screen and (max-width : 1020px) {
	.premium {
		&-time {
			padding-bottom: 30px; }
		&-price {
			font-size: 40px; } } }

@media only screen and (max-width : 767px) {
	.premium {
		padding-bottom: 56px;
		&-col {
			&__wrap {
				padding: 32px 15px; } }
		&-time {
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 8px; }
		&-price {
			font-size: 40px;
			line-height: 1.2; }
		&-name {
			padding-bottom: 24px; } } }

@media only screen and (max-width : 700px) {
	.premium {
		&-col {
			width: 100%; } } }

@media only screen and (max-width : 450px) {
	.premium {
		padding: 0;
		&-col {
			text-align: left;
			&__wrap {
				padding: 24px;
				.main-btn {
					display: block; } } }
		&-time {
			font-size: 14px;
			padding-bottom: 8px; }
		&-price {
			font-size: 36px;
			padding-bottom: 8px; } } }

.ticket {
	position: relative;
	display: block;
	max-width: 450px;
	width: 100%;
	margin: 50px auto 0 auto;
	background: #ffffff;
	text-align: center;
	border-radius: 6px;
	@media only screen and (max-width : 700px) {
		margin: 0; }
	.headding {
		@media only screen and (max-width : 700px) {
			font-size: 16px; } }
	.ticket_inner {
		display: block;
		padding: 33px 0;
		color: #313131;
		text-decoration: none;
		.code_block {
			position: relative;
			display: block;
			font-size: 29px;
			font-style: normal;
			color: #5eb5fe;
			border-top: 2px dashed rgba(0,0,0,.1);
			padding: 20px 0;
			@media only screen and (max-width : 700px) {
				font-size: 20px; }
			&:before {
				content: "";
				position: absolute;
				top: -21px;
				left: 0;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				border-left: 20px solid #3f4e64; }
			&:after {
				content: "";
				position: absolute;
				top: -21px;
				right: 0;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				border-right: 20px solid #3f4e64; } }
		.ribbon {
			position: absolute;
			display: block;
			top: -6px;
			right: -5px;
			width: 110px;
			height: 110px;
			overflow: hidden;
			z-index: 2;
			.label {
				position: relative;
				display: block;
				left: -10px;
				top: 23px;
				width: 158px;
				padding: 10px 0;
				font-size: 15px;
				text-align: center;
				color: #fff;
				background-color: #5eb5fe;
				-webkit-box-shadow: 0px 0px 4px rgba(39, 90, 174, 0.3);
				-moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
				-ms-box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
				box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
				-webkit-transform: rotate(45deg) translate3d(0,0,0);
				-moz-transform: rotate(45deg) translate3d(0,0,0);
				-ms-transform: rotate(45deg) translate3d(0,0,0);
				transform: rotate(45deg) translate3d(0,0,0);
				&:before, &:after {
					content: "";
					position: absolute;
					bottom: -4px;
					border-top: 4px solid #4476a9;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent; }
				&:before {
					left: 0; }
				&:after {
					right: 0; } } } }
	span {
		display: block;
		font-size: 20px; }
	strong {
		display: block;
		font-size: 34px;
		color: #FF655B;
		margin: 20px; }
	.copy_btn {
		display: block;
		color: white;
		position: relative;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 57px;
		padding: 0;
		line-height: 58px;
		text-align: center;
		border-radius: 0;
		background-color: $colorAccent;
		cursor: pointer;
		margin-top: -33px;
		transition: $transition;
		&:hover {
			cursor: pointer;
			background-color: $colorGreen;
			transition: $transition;
			&:before {
				border-bottom-color: $colorGreen;
				transition: $transition; } }
		&:before {
			content: "";
			position: absolute;
			top: -10px;
			left: calc(50% - 20px);
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 10px solid $colorAccent;
			transition: $transition; }
		&.active {
			background-color: $colorGreen;
			&:before {
				border-bottom-color: $colorGreen; } } }
	.additional_info {
		padding: 10px;
		margin: 10px 40px 20px;
		background: #f4f4f4;
		border: 2px dashed #ffffff;
		.info_item {
			padding: 5px 0;
			font-size: 14px; } }
	.author_block {
		padding: 10px;
		margin: 10px 40px 20px;
		background: #f4f4f4;
		border: 2px dashed #ffffff;
		.marathon-block__row {
			align-items: center;
			justify-content: center;
			margin-bottom: 0px;
			flex-direction: column;
			@media only screen and (max-width : 700px) {
				margin-bottom: 0; }
			.marathon-block__img {
				padding-right: 0px;
				margin-bottom: 10px;
				img {
					@media only screen and (max-width : 700px) {
						margin: 0 auto; } } } }

		h5, p {
			text-transform: uppercase;
			color: #96a2b7;
			font-size: 10px;
			margin-bottom: 10px; } } }
.social_share {
	display: block;
	text-align: center;
	margin: 20px 0;
	p {
		color: #96a2b7;
		font-size: 10px;
		text-transform: uppercase;
		margin-bottom: 10px; } }
