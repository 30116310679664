@import "vars";

.involvement {
	margin-top: -40px;
	&-wrap {
		padding: 80px 0;
		border-top: 1px solid #E3E6EF; }
	&-info {
		max-width: 426px;
		margin: 0 auto 80px;
		text-align: center;
		img {
			display: block; }
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 24px;
			max-width: 410px; } }
	&-subtitle {
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		padding-bottom: 16px; }
	&-form {
		max-width: 600px;
		margin: 0 auto;
		.input-block {
			margin-bottom: 24px; } }
	&-list {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: 8px;
		margin-top: 24px;
		li {
			width: 48px;
			height: 48px;
			font-size: 10px;
			line-height: 48px;
			font-weight: 500;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $colorGray;
			border-radius: 50%;
			background: #E6E9F1;
			text-align: center;
			overflow: hidden;
			margin: 0 16px 16px 0; } }
	&-form {
		&__row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			padding-bottom: 80px;
			p {
				font-family: $HelveticaNeueCyr;
				font-size: 16px;
				line-height: 24px;
				color: $colorGray;
				padding-left: 24px; } }
		.main-btn {
			display: block;
			width: 100%; } } }

@media only screen and (max-width : 768px) {
	.involvement {
		&-info {
			margin-bottom: 64px; }
		&-form {
			max-width: 100%; } } }

@media only screen and (max-width : 450px) {
	.involvement {
		&-wrap {
			padding: 24px 0 40px; }
		&-info {
			margin-bottom: 40px; }
		&-form {
			&__row {
				display: block;
				padding-bottom: 32px;
				p {
					padding: 16px 0 0; } } } } }
