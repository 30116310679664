@import "vars";

.reviews {
	padding: 61px 100px 56px;
	border-top: 1px solid #E3E6EF;
	position: relative;
	&-img {
		position: absolute;
		top: 40px;
		left: 0;
		img {
			max-width: 72px;
			border-radius: 50%; } }
	&-info {
		max-width: 600px;
		margin: 0 auto;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 30px;
			color: $colorMain;
			padding-bottom: 16px;
			a {
				color: $colorAccent; } } }
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 32px; }
	&-user_task {
		padding-bottom: 15px;
		justify-content: start;
		.text-anim {
			span {
				margin-right: 10px;
				@media only screen and (max-width: 768px) {
					display: inline-block!important; }
				@media only screen and (max-width: 450px) {
					font-size: 12px!important; } }
			img {
				max-width: 25px; } } }

	&-voting {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		span {
			display: block;
			min-width: 44px;
			text-align: center;
			font-size: 20px;
			line-height: 1.2;
			color: #96A2B7;
			padding: 0 5px;
			&.text-red {
				color: #FF655B; } }
		.voting {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 24px;
			height: 24px;
			border: 1px solid #CCD3DC;
			border-radius: 50%;
			line-height: 22px;
			transition: $transition;
			cursor: pointer;
			&:hover {
				background: #DDE1E9;
				border-color: #DDE1E9; } } }
	&-name {
		display: flex;
		align-items: center;
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		color: $colorMain;
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 16px;
			margin-left: 10px; }
		a {
			color: $colorMain; } }
	&-date {
		font-family: $HelveticaNeueCyr;
		font-size: 12px;
		line-height: 20px;
		color: $colorGray;
		a {
			color: $colorGray; } }
	&-list {
		li {
			margin-left: 32px; } }
	&-second {
		background: #F2F5FB;
		max-width: 600px;
		padding: 24px;
		border-radius: 0 $borderRadius $borderRadius $borderRadius;
		position: relative;
		margin: 40px auto 0;
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: -15px;
			border-top: 15px solid #F2F5FB;
			border-right: 15px solid #F2F5FB;
			border-left: 15px solid transparent; }
		img {
			position: absolute;
			top: 24px;
			left: 24px;
			max-width: 40px;
			border-radius: 50%; }
		&__info {
			padding: 7px 0 0 64px;
			p {
				font-size: 14px;
				line-height: 25px;
				padding-bottom: 12px;
				max-width: 480px; } }
		&__name {
			display: block;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 26px; } } }


@media only screen and (max-width : 767px) {
	.reviews {
		padding: 32px 0 32px 180px;
		&-top {
			padding-bottom: 24px; }
		&-name {
			font-size: 18px; }
		&-list {
			li {
				margin-left: 24px; } }
		&-second {
			margin-top: 32px;
			&__info {
				padding: 56px 0 0; }
			&__name {
				position: absolute;
				top: 34px;
				left: 88px;
				margin: 0; } } } }

@media only screen and (max-width : 600px) {
	.reviews {
		padding: 32px 0 32px 100px; } }

@media only screen and (max-width : 450px) {
	.reviews {
		padding: 34px 0 68px;
		&-img {
			top: 24px;
			img {
				max-width: 48px; } }
		&-top {
			padding: 0; }
		&-name {
			padding: 0 0 30px 72px;
			font-size: 16px;
			line-height: 28px; }
		&-info {
			p {
				font-size: 14px;
				line-height: 24px; } }
		&-voting,&-list {
			position: absolute;
			bottom: 26px;
			left: 0;
			span {
				font-size: 16px; } }
		&-list {
			li {
				margin: 0 24px 0 0; } }
		&-second {
			border-radius: 0;
			margin-top: 24px;
			&::after {
				display: none; } } } }


