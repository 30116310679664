@import "vars";

.support {
	&-row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		max-width: 1150px;
		margin: 0 auto;
		padding-bottom: 53px; }
	&-col {
		max-width: 320px;
		width: 100%;
		text-align: center;
		padding: 0 10px 32px;
		p,a {
			font-size: 20px;
			line-height: 1.3;
			color: $colorMain; } }
	&-subtitle {
		font-family: $HelveticaNeueCyr;
		font-size: 14px;
		line-height: 2;
		font-weight: normal;
		color: $colorGray;
		padding-bottom: 12px; } }

@media only screen and (max-width : 1020px) {
	.support {
		&-row {
			flex-flow: row wrap; }
		&-col {
			width: 50%;
			max-width: 100%;
			margin: 0 auto; } } }

@media only screen and (max-width : 768px) {
	.support {
		&-row {
			padding-bottom: 32px; } } }

@media only screen and (max-width : 600px) {
	.support {
		&-row {
			padding-bottom: 24px; }
		&-col {
			width: 100%;
			padding: 0 0 24px;
			p,a {
				font-size: 16px;
				line-height: 24px; } }
		&-subtitle {
			font-size: 12px;
			line-height: 20px;
			padding-bottom: 8px; } } }

