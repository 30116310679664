@import "vars";

.accordeon-arrow {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0,-50%);
	width: 48px;
	height: 48px;
	border: 1px solid #CCD3DC;
	border-radius: $borderRadius;
	cursor: pointer;
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		path {
			transition: $transition; } } }

.faq {
	padding-bottom: 4px;
	.interesting.interesting-second {
		margin-bottom: 4px; }
	&-accordeon {
		position: relative;
		transition: $transition;
		&::before,&::after {
			content: '';
			position: absolute;
			top: 0;
			width: 2000px;
			height: 100%;
			background: #F8FAFF;
			opacity: 0;
			visibility: hidden;
			transition: $transition; }
		&::before {
			left: -2000px; }
		&::after {
			right: -2000px; }
		&__top {
			padding: 80px 60px 80px 0;
			border-bottom: 1px solid #E3E6EF;
			position: relative;
			&-calendar {
				padding: 40px 30px 40px 0; } }
		&__title {
			font-size: 30px;
			line-height: 1.333;
			font-weight: normal;
			cursor: pointer;
			transition: $transition; }
		&__wrap {
			padding: 80px 0;
			&-calendar {
				padding: 20px 0; } }
		&__row {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin: 0 -15px;
			padding-top: 80px;
			@media only screen and (max-width : 450px) {
				padding-top: 0; }
			@media only screen and (max-width : 768px) {
				padding-top: 0; }
			&:first-child {
				padding: 0; } }
		&__subtitle {
			font-size: 20px;
			line-height: 1.5;
			font-weight: 500;
			letter-spacing: 0.04em;
			text-transform: uppercase;
			padding-bottom: 48px;
			span {
				font-weight: normal;
				color: $colorGray;
				padding-left: 7px; } }
		&__col {
			width: 47%;
			padding: 0 15px 48px;
			position: relative;
			ul {
				padding-bottom: 24px;
				li {
					position: relative;
					font-size: 14px;
					line-height: 24px;
					padding-left: 28px;
					margin-bottom: 24px;
					max-width: 550px;
					a {
						font-size: 14px;
						line-height: 24px;
						color: $colorMain; }
					&::after {
						content: '';
						position: absolute;
						top: 10px;
						left: 0;
						width: 4px;
						height: 4px;
						border-radius: 50%;
						background: $colorGray; } } }
			.second-btn {
				position: absolute;
				bottom: 0;
				left: 15px; } }
		&.accordeon-active {
			background: #F8FAFF;
			&::before,&::after {
				opacity: 1;
				visibility: visible; }
			.faq {
				&-accordeon {
					&__top {
						.accordeon-arrow {
							transform: translate(0,-50%) rotate(180deg);
							svg {
								path {
									fill: $colorGray; } } } }
					&__title {
						color: $colorAccent; } } } } } }

@media only screen and (max-width : 768px) {
	.accordeon-arrow {
		width: 40px;
		height: 40px; }
	.faq {
		.interesting.interesting-second {
			margin-bottom: 24px; }
		&-accordeon {
			&__top {
				padding: 40px 60px 40px 0; }
			&__title {
				font-size: 24px;
				line-height: 1.5; }
			&__wrap {
				padding: 48px 0 0; }
			&__row {
				margin: 0; }
			&__subtitle {
				padding-bottom: 40px; }
			&__col {
				width: 100%;
				padding: 0 0 64px;
				.second-btn {
					position: static; } } } } }

@media only screen and (max-width : 450px) {
	.faq-navigation {
		.marathon-step__btns {
			display: block!important;
			.second-btn {
				width: 100%;
				margin-bottom: 5px;
				.navigation_title {

					width: 100%;
					white-space: break-spaces; } } } }
	.faq {
		.interesting.interesting-second {
			margin-bottom: 8px; }
		&-accordeon {
			&__top {
				padding: 38px 50px 38px 0; }
			&__title {
				font-size: 20px;
				line-height: 1.4; }
			&__wrap {
				padding: 40px 0 0; }
			&__subtitle {
				font-size: 16px;
				line-height: 24px;
				padding-bottom: 20px; }
			&__col {
				padding-bottom: 40px;
				ul {
					padding-bottom: 15px;
					li {
						padding-left: 20px;
						font-size: 12px;
						line-height: 20px;
						margin-bottom: 15px; } } } } } }

