@import "vars";

.timeline {
	padding: 80px 0;
	&-row {
		padding: 40px 160px;
		border-top: 1px solid #E3E6EF;
		position: relative;
		&:nth-child(odd) {
			background: #ecf0f6; }
		&-preview {
			&:nth-child(odd) {
				background: transparent; } }
		&-active {
			background: rgba(94, 181, 254, 0.1);
			&:nth-child(odd) {
				background: rgba(94, 181, 254, 0.1); } }
		.table-btn {
			top: 40px;
			right: 40px;
			transform: translate(0,0); } }
	&-date {
		position: absolute;
		top: 40px;
		left: 40px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		span {
			font-size: 48px;
			line-height: 1;
			font-weight: 300;
			color: $colorGray;
			padding-right: 16px; }
		p {
			font-size: 10px;
			line-height: 1.5;
			font-weight: 500;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $colorGray;
			b {
				display: block;
				font-weight: 500; } } }
	&-info {
		max-width: 600px;
		margin: 0 auto;
		p {
			font-family: $HelveticaNeueCyr;
			font-size: 16px;
			line-height: 25px;
			color: $colorGray;
			//word-break: break-all
			word-break: break-word; } }
	&-step {
		font-size: 20px;
		line-height: 1.5;
		font-weight: normal;
		padding-bottom: 24px;
		span {
			padding-left: 16px; } }
	.center-btn {
		margin-top: 40px;
		.main-btn {
			display: block;
			max-width: 600px;
			margin: 0 auto; } }
	&-row {
		&.timeline-notStarted {
			opacity: .5; } } }

@media only screen and (max-width : 768px) {
	.timeline {
		padding: 64px 0;
		&-row {
			padding: 32px 0 32px 180px;
			.table-btn {
				top: 32px; } }
		&-step {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			font-size: 16px;
			line-height: 28px;
			padding-bottom: 12px;
			span {
				display: flex;
				align-items: center;
				justify-content: center; } }
		.center-btn {
			margin-top: 8px; } } }

@media only screen and (max-width : 600px) {
	.timeline {
		padding: 48px 0;
		&-row {
			padding: 24px 14px;
			margin: 0 -14px;
			.table-btn {
				right: 0; } }
		&-date {
			position: static;
			padding-bottom: 4px;
			span {
				font-size: 10px;
				line-height: 15px;
				font-weight: 500;
				padding: 0 5px 0 0; }
			p {
				b {
					display: inline; } } }
		&-info {
			p {
				font-size: 12px;
				line-height: 20px; } } } }
