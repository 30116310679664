@import "vars";

.testimonials {
	padding: 76px 0;
	background: #EBEFF7;
	&-top {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 76px;
		.section-title {
			padding: 0; } }
	&-btn {
		max-width: 600px;
		padding: 20px 0 0;
		margin: 0 auto;
		.second-btn {
			display: block;
			width: 100%; } }
	.forum-bottom {
		padding-bottom: 0; } }

@media only screen and (max-width : 767px) {
	.testimonials {
		padding: 46px 0 64px;
		&-top {
			padding-bottom: 40px; }
		.prompt li span i {
			transform: translate(-90%,-100%);
			&::before {
				display: none; }
			&::after {
				left: auto;
				right: 12px; } } } }

@media only screen and (max-width : 450px) {
	.testimonials {
		padding: 32px 0 40px;
		&-top {
			display: block;
			padding-bottom: 24px;
			.section-title {
				padding-bottom: 24px; }
			.estimate {
				justify-content: center; } }
		.prompt li span i {
			transform: translate(-10%,-100%);
			&::after {
				left: 18px;
				right: auto; } } } }
