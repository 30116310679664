@import "vars";

.timetable {
	padding-bottom: 130px;
	&-wrap {
		border-bottom: 1px solid #E3E6EF; }
	.fc-toolbar h2 {
		font-size: 30px;
		line-height: 1.333;
		font-weight: normal; }
	.fc-today-button {
		display: none; }
	.fc-toolbar.fc-header-toolbar {
		margin-bottom: 30px; }
	.fc td, .fc th {
		border: none; }
	.fc-unthemed .fc-row {
		//padding: 24px 0
		border-top: 1px solid #E3E6EF; }
	.fc-widget-header.fc-day-header {
		padding: 24px 0 24px 24px;
		text-align: left;
		font-size: 10px;
		line-height: 2;
		font-weight: 500;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $colorGray; }
	.fc-day-top {
		padding: 24px; }
	.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-mirror-skeleton td {
		min-height: 30px; }
	.fc-day-number {
		font-size: 16px;
		line-height: 16px;
		font-weight: 300;
		color: $colorMain; }
	.fc-event-container {
		padding: 0 24px;
		.fc-content {
			white-space: normal; }
		.fc-day-grid-event {
			margin: 0; }
		.fc-event, .fc-event-dot {
			background: transparent;
			border: none; }
		.fc-title {
			display: block;
			font-size: 12px;
			line-height: 20px;
			color: $colorMain;
			padding-bottom: 16px; }
		.fc-time {
			display: none; } }
	.fc-scroller.fc-day-grid-container {
		overflow: hidden!important;
		height: auto!important; }
	.fc-unthemed td.fc-today {
		background: #E3F0FC; }
	.fc-icon {
		width: 36px;
		height: 36px; }
	.fc-button-primary {
		background: transparent!important;
		border: none!important;
		box-shadow: none!important; }
	.fc-icon-chevron-right:before,.fc-icon-chevron-left:before {
		color: #3F4D64;
		font-size: 36px; } }

.fc-view-container {
	font-family: $Rubik; }

@media only screen and (max-width : 1020px) {
	.timetable {
		&-wrap {
			.fc-view-container {
				margin: 0 -36px; } }
		.fc-day-top {
			padding: 20px; }
		.fc-event-container {
			.fc-title {
				font-size: 11px;
				line-height: 18px;
				padding-bottom: 10px; } }
		.fc-event-container {
			padding: 0 15px; } } }
.fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header {
	background: $colorAccent;
	color: white;
	.fc-icon-x:before {
		display: block;
		position: relative;
		top: 8px; } }
.fc-body.fc-widget-content {
	.fc-event-container {
		padding-top: 24px; } }
.fc-ltr.fc-list-item-marker {
	display: none; }
@media only screen and (max-width : 850px) {
	.fc-scroller {
		height: 100%!important; }
	.timetable .fc-toolbar.fc-header-toolbar {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		.fc-right {
			display: none; }
		.fc-center {
			order: -1; }
		.fc-button {
			padding: 0; } }
	.fc-unthemed .fc-list-heading td {
		background: $colorAccent;
		.fc-list-heading-main,.fc-list-heading-alt {
			font-family: $Rubik;
			font-size: 16px;
			font-weight: normal;
			color: #fff; } }
	.fc-event-dot {
		background: $colorAccent; }
	.fc-unthemed .fc-list-view {
		border-color: transparent; }
	.timetable {
		padding-bottom: 64px;
		.fc-toolbar h2 {
			font-size: 24px;
			line-height: 36px; }
		&-wrap {
			.fc-view-container {
				margin: 0; } }
		.fc-view-container {
			overflow-y: hidden;
			overflow-x: auto; } } }

@media only screen and (max-width : 450px) {
	.timetable {
		padding-bottom: 40px;
		.fc-toolbar h2 {
			font-size: 20px;
			line-height: 32px; } }
	.fc-list-table td {
		padding: 8px 10px; } }
