.new-clients {
    position: relative;
    z-index: 1;
    margin-top: 120px;

    @media screen and (min-width: 1024px) {
        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 24px;
            left: 0;
            z-index: -1;
            display: block;
            width: 100%;
            height: 184px;
            background: #5EB5FE;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 80px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 60px;
    }
    
    &__wrapper {
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 1024px) {
            margin: 0 -12px;
        }
        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
        background: #fff;
        border-radius: 6px;

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            padding: 30px;
        }
        @media screen and (min-width: 1024px) {
            margin: 0 12px;
            width: calc(33.33% - 12px);
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
        }
        @media screen and (max-width: 767px) {
            padding: 20px;
        }
    }
    &__item + &__item {
        @media screen and (max-width: 1023px) {
            margin-top: 20px;
        }
    }
    &__description {
        font-size: 20px;
        font-weight: normal;
        color: #3F4D64;

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            font-size: 18px;
        }
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    &__btn {
        margin-top: 50px;
        .main-btn,
        .second-btn {
            min-width: 237px;
        }
        
        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
}