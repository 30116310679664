@import "vars";

.section-title {
	font-size: 30px;
	line-height: 1.3;
	font-weight: normal;
	padding-bottom: 40px;
	span,a {
		display: inline-block;
		vertical-align: top;
		margin-left: 15px;
		font-size: 16px;
		line-height: 20px;
		font-weight: normal;
		color: $colorAccent; } }

.page-title {
	font-size: 36px;
	line-height: 1.388;
	font-weight: 500;
	padding-bottom: 80px;
	span,a {
		display: inline-block;
		vertical-align: top;
		margin-left: 15px;
		font-size: 16px;
		line-height: 20px;
		font-weight: normal;
		color: $colorAccent; } }

@media only screen and (max-width : 768px) {
	.page-title {
		font-size: 30px;
		line-height: 1.3;
		padding-bottom: 40px; }
	.section-title {
		font-size: 24px;
		line-height: 1.5;
		padding-bottom: 40px; } }

@media only screen and (max-width : 600px) {
	.page-title {
		font-size: 20px;
		line-height: 1.3;
		padding-bottom: 28px; }
	.section-title {
		font-size: 20px;
		line-height: 1.6;
		padding-bottom: 24px; } }
